import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
async createOutput(task_allocation_id,achieved_work){
    let data={
      "task_allocation_id":task_allocation_id,
      "achieved_work":achieved_work
    }
    let response = await apiCall("output/create", data)
    return response;
  
  },
  async updateOutput(output_id,achieved_work,comment){
    let data={
      "output_id":output_id,
      "achieved_work":achieved_work,
      "comment":comment
    }
    let response = await apiCall("output/update", data)
    return response;
  
  },
    async fetchOutput(task_id){
        let data={
            "task_id":task_id
        }
        let response = await apiCall("output/list", data)
  
        return response;
  
    },

    async fetchTeamOutput(team_id){
        let data={
            "team_id":team_id
        }
        let response = await apiCall("output/list/team", data)
  
        return response;
  
    },
    async fetchPayment(output_id){
      let data={
          "output_id":output_id
      }
      let response = await apiCall("payment/team/calculate", data)

      return response;

  },
  async addHSEReport(task_allocation, location, report_date, teams_understand, teams_trained, tools_condition,tools_photo,teams_doc,other_parties,
    right_ppe,ppe_photo,hazards,hazards_removed,staff_assigned, staff_list,assessed_by,designation){
    let data={
        "task_allocation":task_allocation,
        "location":location,
        "report_date":report_date,
        "teams_understand":teams_understand,
        "teams_trained":teams_trained,
        "tools_condition":tools_condition,
        "tools_photo":tools_photo,
        "teams_doc":teams_doc,
        "other_parties":other_parties,
        "right_ppe":right_ppe,
        "ppe_photo":ppe_photo,
        "hazards":hazards,
        "hazards_removed":hazards_removed,
        "staff_assigned":staff_assigned,
        "staff_list":staff_list,
        "assessed_by":assessed_by,
        "designation":designation,

    }
    let response = await apiCall("hse_report/add", data)

    return response;

},
async addTeamPhoto(output_id, output_lat,output_long,team_photo){
  let data={
      "output_id":output_id,
      "output_lat":output_lat,
      "output_long":output_long,
      "team_photo":team_photo
  }
  let response = await apiCall("output/photo", data)

  return response;

},


   
}
import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
async createRollCall(output,employee,status){
  

    let data={
      "output":output,
      "employee":employee,
      "status":status
    }
    let response = await apiCall("call/create", data)
    return response;
  
  },
  async updateOutput(outputId,achieved_work,team_id,output_site){
    let data={
      "output_id":outputId,
      "achieved_work":achieved_work,
      "team_id":team_id,
      "output_site":output_site
    }
    let response = await apiCall("output/update", data)
    return response;
  
  },
    async fetchOutput(task_id){
        let data={
            "task_id":task_id
        }
        let response = await apiCall("output/list", data)
  
        return response;
  
    },

    async fetchTeamOutput(team_id){
        let data={
            "team_id":team_id
        }
        let response = await apiCall("output/list/team", data)
  
        return response;
  
    },
    async fetchRollcall(output,employee,status){
      let data={
        "output":output,
        "employee":employee,
        "status":status
      }
      let response = await apiCall("call/list/all", data)
      return response;
    
    }
   
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Common/Loader";
import { CircularProgressbar } from "react-circular-progressbar";
import ajaxProject from "../../util/remote/ajaxProject";
import "react-circular-progressbar/dist/styles.css";

export default function ProjectInformation() {
  const { id } = useParams();

  const [projectProfile, setProjectProfile] = useState(false);
  const [projectProgress, setProjectProgress] = useState(false);

  const getProjectProgress = async (id) => {
    const server_response = await ajaxProject.fetchProjectProgress(id);
    if (server_response.status === "OK") {
      setProjectProgress(server_response.details);
    } else {
      setProjectProgress("404");
    }
  };

  const getProjectProfile = async (id) => {
    const server_response = await ajaxProject.fetchProjectProfile(id);

    if (server_response.status === "OK") {
      setProjectProfile(server_response.details);
    } else {
      setProjectProfile("404");
    }
  };

  useEffect(() => {
    getProjectProfile(id);
    getProjectProgress(id);
  }, [id]);

  return (
    <>
      <div
        className="box left-dot mb-30"
        style={{
          marginBottom: "30px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: "10px",
          borderRadius: "10px",
        }}>
        <div className="box-header  border-0 pd-0">
          <div className="box-title fs-20 font-w600">Project Information</div>
        </div>
        <div className="box-body pt-20 user-profile">
          <div className="table-responsive">
            <table className="table mb-0 mw-100 color-span">
              {projectProfile && (
                <tbody>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Project Name </span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="">
                        {projectProfile.project_name}
                      </span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Project Description</span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="">
                        {projectProfile.description}
                      </span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Project Overview</span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="">{projectProfile.overview}</span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Priority</span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      {projectProfile.priority === "High" ? (
                        <span
                          className="badge badge-danger"
                          style={{
                            fontSize: ".75rem",
                            lineHeight: "2em",
                            display: "inlineFlex",
                          }}>
                          {projectProfile.priority}
                        </span>
                      ) : projectProfile.priority === "Medium" ? (
                        <span
                          className="badge badge-warning"
                          style={{
                            fontSize: ".75rem",
                            lineHeight: "2em",
                            display: "inlineFlex",
                          }}>
                          {projectProfile.priority}{" "}
                        </span>
                      ) : (
                        <span
                          className="badge badge-success"
                          style={{
                            fontSize: ".75rem",
                            lineHeight: "2em",
                            display: "inlineFlex",
                          }}>
                          {projectProfile.priority}{" "}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Number of Active Tasks</span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="">
                        {projectProfile.total_tasks
                          ? projectProfile.total_tasks
                          : "0"}
                      </span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="w-50">Start Date</span>{" "}
                    </td>
                    <td>:</td>
                    <td className="py-2 px-0">
                      {" "}
                      <span className="">{projectProfile.start_date}</span>{" "}
                    </td>
                  </tr>
                  {/* <tr>
                                <td className="py-2 px-0"> <span className="w-50">Progress</span> </td>
                                <td>:</td>
                                <td className="py-2 px-0"> 
                                    <span className="">
                                        {projectProgress &&<div style={{width: 80}}>
                                            <CircularProgressbar style={{height:'50px'}} value={projectProgress.total?projectProgress.total:""} text={`${projectProfile.progress?projectProfile.progress.total:""}%`}/>
                                        </div>}
                                    </span>
                                </td>
                            </tr> */}
                </tbody>
              )}
            </table>
            {!projectProfile && <Loader />}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { Link } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import TableHeader from "../../Components/Common/TableHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ListDeliveryNotes() {
  const [deliveryNotes, setDeliveryNotes] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDeliveryNotes();
  }, []);

  const getDeliveryNotes = async () => {
    setLoading(true);
    const server_response = await ajaxProduct.fetchDeliveryNotes();
    setLoading(false);

    if (server_response.status === "OK") {
      setDeliveryNotes(server_response.details);
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "delivery notes  Report"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description  const table = document.querySelector(".table"); // Select the table element

    // Define columns for the table (add more if needed)
    const columns = ["Date", "Reference No.", "Client Name", "Received By"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    // data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`delivery_data_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <AppContainer title="Delivery Notes">
      <div className="card custom-card">
        <div className="card-body">
          {loading && <Loader />}

          <TableHeader
            title="Delivery Notes"
            subtitle="List of all the delivery notes"
            viewButton={
              <>
                <a
                  href="#"
                  onClick={exportToPDF}
                  className="btn btn-secondary btn-sm mr-2"
                  style={{ float: "right" }}>
                  Export to PDF
                </a>
                <Link
                  style={{ float: "right" }}
                  className="btn btn-success mb-4 mr-2 btn-sm"
                  to="/inventory/create">
                  Add Delivery Note
                </Link>
              </>
            }
          />

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <th>Date</th>
                <th>Reference No.</th>
                <th>Client Name</th>
                <th>Received By</th>

                <th style={{ width: "100px" }}>Comment By</th>

                <th>Delivered By</th>
                <th>vehicle_plate</th>
                <th>Delivery DateTime</th>
                <th>Store Name</th>
                <th>Store location</th>
                <th>Actions</th>
              </thead>

              <tbody>
                {!Array.isArray(deliveryNotes) && <tr>No results found</tr>}
                {Array.isArray(deliveryNotes) &&
                  deliveryNotes.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date_added}</td>
                      <td>{item.delivery_note}</td>
                      <td>{item.client_name}</td>
                      <td>{item.received_by}</td>
                      <td style={{ width: "1   By00px", wordWrap: "normal" }}>
                        {item.comment}
                      </td>
                      <td>{item.delivered_by}</td>
                      <td>{item.vehicle_plate}</td>
                      <td>{item.store_name}</td>
                      <td>{item.location}</td>
                      <td>
                        {item.date_added}/{item.time}
                      </td>

                      <td>
                        <Link
                          className="btn btn-sm btn-primary mr-2"
                          to={`products/list/${item.note_id}`}>
                          {" "}
                          View Products
                        </Link>
                        <Link
                          className="btn btn-sm btn-info mr-2"
                          to={`input/update/${item.note_id}`}>
                          {" "}
                          Add Products
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ListDeliveryNotes;

import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import toast, { Toaster } from "react-hot-toast";
//new
import Select from "react-select";
import UserContext from "../../Context/UserContext";

function Complete(props) {
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [pole, setPole] = useState(false);
  const [approved, setApproved] = useState(false);
  /////new
  const { userList } = useContext(UserContext);
  const [user, setUser] = useState("");

  const { id } = useParams();

  const handlePitDetails = async (e) => {
    e.preventDefault();
    if (id > 0 && status.length > 0 && approved.length > 0 && date.length > 0) {
      setLoading(true);
      const server_response = await ajaxProject.updatePoleState(
        id,
        status,
        approved,
        date,
        user
      );
      // console.log(approved);

      // return;
      setLoading(false);
      if (server_response.status === "OK") {
        setStatus("");
        setDate("");
        setApproved("");
        setUser(false);
        toast.success(server_response.message);
        props.navi(`/installations/create/${id}#complete`);
      }
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  useEffect(() => {
    getPole();
  }, [id]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);
    // console.log(server_response.details);
    if (server_response.status === "OK") {
      setPole(server_response.details);
      setDate(server_response.details.verified_date);
      setStatus(server_response.details.verified_comment);
      setApproved(server_response.details.approved);
      setUser(server_response.details.verified_by);
    } else {
      setPole(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Toaster />
      {pole && (
        <form onSubmit={(e) => handlePitDetails(e)}>
          <br />
          <Link
            onClick={() => props.navi(-1)}
            className="btn btn-primary btn-sm mb-2">
            Back To Previous Page
          </Link>
          <h6>
            <small className="text-muted">Pole ID : </small>
            {pole.pole_id}
          </h6>

          <br />
          <div className="mb-4 col-md-12">
            <label>
              <b>Approved</b>
            </label>
            <select
              value={approved}
              onChange={(e) => setApproved(e.target.value)}
              required
              className="form-control">
              <option value="">--- select status ---</option>
              <option value="NOT APPROVED">NOT APPROVED</option>
              <option value="APPROVED">APPROVED</option>
            </select>
          </div>

          <div className="form-group mt-3">
            <div className="mb-4 col-md-12">
              <label>
                <b>Verification Comment</b>
              </label>
              <input
                type="text"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="mb-4 col-md-12">
              <label>
                <b>Verification Date</b>
              </label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          {/* ////new */}

          <div className="mb-4 col-md-12">
            <label htmlFor="">
              <b>Verified By:</b>
            </label>
            <Select
              onChange={(e) => setUser(e.id)}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionValue={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              isSearchable
              options={userList}
              value={
                Array.isArray(userList) &&
                userList.find((value) => value.id === user)
              }
            />
          </div>
          <div className="mt-2 mb-2">
            <button type="submit" className="btn btn-success w-100">
              Update
            </button>
          </div>
        </form>
      )}
      {!pole && <h5>Pole not found</h5>}
    </>
  );
}

export default Complete;

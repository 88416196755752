import React, { useEffect, useState } from 'react'
import AppContainer from '../../Components/Structure/AppContainer'
import ajaxDailyOutput from '../../util/remote/ajaxDailyOutput'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useStateCallback from '../../util/customHooks/useStateCallback'
import ViewTask from '../Tasks/ViewTask'
import Rollcall from '../Tasks/Rollcall'
// import ViewOutput from './ViewOutput'
import AlertDialogModal from '../../Components/Common/AlertDialogModal'
import ajaxPayRate from '../../util/remote/ajaxPayRate'
import ViewPayRate from './ViewPayRate'

function ListPayRate() {

  const {id} = useParams()
  const [payRateList,setPayRateList] = useState(false)
  const [loading,setLoading] = useState(false)
  const [expandedItemIndex, setExpandedItemIndex] = useState(null);
  const navigation = useNavigate()
  const getPayRates = async() =>{
    const server_response = await ajaxPayRate.fetchPayRates(id);
    if(server_response.status==="OK"){
      setPayRateList(server_response.details)
    }
  }

  useEffect(() => {
    getPayRates()
  }, [id])
  
  const toggleDots = (index) => {
    if (expandedItemIndex === index) {
      setExpandedItemIndex(null); // Collapse the currently expanded item
    } else {
      setExpandedItemIndex(index); // Expand the clicked item
    }
  };



  const [modal, setModal] = useStateCallback(false);
       
  const handleModalViewOutput=(e,item)=>{
     
    setExpandedItemIndex(null);
    setModal(false, ()=>setModal(<ViewPayRate 
      teamId={item.team.team_id}
      teamName={item.team.team_name} 
      roleId={item.role.role_id}
      roleName={item.role.role_name}
      rate={item.rate} 
      rateId={item.id}

      isOpen={true}/>))
}



  return (
    <AppContainer title="Pay Rates">
      {modal}
      <Link
          onClick={()=>navigation(-1)}
          className="btn btn-primary btn-sm mb-2"
        >
          Back To Previous Page
        </Link>

      <div className='card custom-card'>
        <div className='card-body'>
        <div className="mb-4">
            <h6 className="card-title mb-4">
              List of Pay Rates
              <Link to="rate/create">
                <button
                  type="submit"
                  style={{ float: 'right', color: 'white' }}
                  className="btn btn-sm btn-success mb-4"
                >
                  Add Pay Rate
                </button>
              </Link>
            </h6>
          </div>

          <div className="table-responsive">
            <div className="border-top mt-3"></div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Role</th>
                  <th>Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
          {Array.isArray(payRateList) && payRateList.map((item, key) => (
    <tr key={key}>
      <td>{item.team.team_name}</td>
      <td>{item.role.role_name}</td>
      <td>{item.rate}</td>
      <td>
       
              <Link className="btn btn-sm btn-primary" href="#" onClick={(e)=>handleModalViewOutput(e,item)}>
                <i className="fe fe-eye mr-2" style={{ color: 'white' }}></i>View Details
              </Link>
         
      </td>
    </tr>
  ))}
</tbody>

            </table>
          </div>
        </div>
      </div>
    </AppContainer>
    
  )
}

export default ListPayRate
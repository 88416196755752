import {Wrapper} from "@googlemaps/react-wrapper";
import { useRef, useEffect, useState} from "react";
import SystemModal from "../Common/SystemModal";

export default function VisitLocation(props){
    return(
        <SystemModal title="Visit Location" id="model-new-property" size="md">
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY} version="beta" libraries={["marker"]}>
                <MyMap position={props.position} position1={props.position1}  />
            </Wrapper>
            <h6>KEY:</h6>
            <p> V: Team Visit</p> 
            <p> C: {props.client}</p>
        </SystemModal> 
    )
}



function MyMap({position, position1}){
    const [map, setMap] = useState();
    const ref = useRef();
   
    const mapOptions = {
        mapId: process.env.REACT_APP_MAP_ID,
        zoom: 17,
        center: {lat: position.lat, lng: position.lng },
        disableDefaultUI: true,
    };

    useEffect(() => {
        setMap(new window.google.maps.Map(ref.current, mapOptions));
    }, []);

    return(
         <>
         <div ref={ref} style={{ height: '50vh', width: '100%' }}/>
         {map && <VisitMap position={position} position1={position1} map={map} />}

    </>
    )
    
}


function VisitMap({map, position, position1}){  
    return ( 
    <>
    
    <Marker
                
                map={map}
                position={position}
                iconUrl="https://maps.google.com/mapfiles/kml/paddle/V.png"
                iconSize={{ width: 50, height: 90 }}
            />
    <Marker
               
                map={map}
                position={position1}
                iconUrl="https://maps.google.com/mapfiles/kml/paddle/C.png"
                iconSize={{ width: 30, height: 50 }}
            />

    {/* <Marker map={map} position={position}>
        <div style={{ position: 'relative', padding:'0.5rem' }}>
            <i className="fa fa-map-marker-alt fa-4x" style={{color: "#ed333b"}} aria-hidden="true"></i>
           
        </div>
    </Marker>
    <Marker map={map} position={position1}>
        <div style={{ position: 'relative', padding:'0.5rem' }}>
            <i className="fa fa-home fa-2x" style={{color: "black"}} aria-hidden="true"></i>
           
        </div>
    </Marker> */}
    
    </>
    );
}
function Marker({ map, position, iconUrl, iconSize}) {
    useEffect(() => {
        const marker = new window.google.maps.Marker({
            position,
            map,
            icon: {
                url: iconUrl,
                scaledSize: new window.google.maps.Size(iconSize.width, iconSize.height),
            },
        });

        return () => {
            marker.setMap(null); // Clean up: remove the marker when component unmounts
        };

    
    }, [map, position, iconUrl, iconSize]);

    return null;
}

// function Marker({map, children, position}){
//     const markerRef = useRef();
//     const rootRef = useRef();

//     useEffect(() => {
//         if(!rootRef.current){
//             const container = document.createElement("div");
//             rootRef.current = createRoot(container);

//             markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
//                 position,
//                 content: container
//             });

//         }

//     },[]);

//     useEffect(()=> {
//         rootRef.current.render(children);
//         markerRef.current.position = position;
//         markerRef.current.map = map;
//     }, [map, position, children])
// }
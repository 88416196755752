import React, { useContext, useRef, useState } from 'react'
import RoleContext from '../../Context/RoleContext'
import UserContext from '../../Context/UserContext'
import ajaxUser from '../../util/remote/ajaxUser'
import Select from 'react-select'
import { Toaster, toast } from 'react-hot-toast';
import AppContainer from '../Structure/AppContainer'

export default function CreateUsers() {

    const {getUserList} = useContext(UserContext);
    const {roleList} = useContext(RoleContext);
    const ref = useRef();
    const [role,setRole] =useState("")
    const [firstName,setFirstName] =useState("")
    const [lastName,setLastName] =useState("")
    const [userName,setUserName] =useState("")
    const [password,setPassword] =useState("")
    const [email,setEmail] =useState("")
    const [contact,setContact] =useState("")
    const [NIN,setNIN] =useState("")
    const [district,setDistrict] =useState("")

    const handleAdd = async(e) =>{
        e.preventDefault()
    
        if(role>0 || firstName.length>0 || lastName.length>0 || contact.length>0 || userName.length>0 || password.length>0 || email.length>0 || NIN.length>0 || district.length>0){
            const server_response = await ajaxUser.createUser(role,firstName,lastName,userName,password,email,NIN,district,contact);
            if(server_response.status==="OK"){
                
                toast.success(server_response.message)
                getUserList() 
                resetForm();
            }
            else{
                toast.error(server_response.message)
            } 
        }
        else{
            toast.error("Complete all fields and try again")
        }  
    }

    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setUserName("")
        setPassword("")
        setEmail("")
        setNIN("")
        setDistrict("")
        setContact("")
    };

    return (
        <AppContainer title="Add New User">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            
            <div className="row">
			    <div className="col-lg-12 col-md-12">
          		    <div className="card custom-card" style={{borderRadius: "10px"}}>
                        <div className="card-body">
                            <div>
                                <h6 className="card-title mb-4">Add New User</h6>
                            </div>

                            <form onSubmit={(e)=>handleAdd(e)} method="post">
                                <div className="row">
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">First Name</label>
                                        <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Last Name</label>
                                        <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Email</label>
                                        <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Username</label>
                                        <input type="text" value={userName} onChange={(e)=>setUserName(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Password</label>
                                        <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Role</label>
                                        <Select
                                            ref={ref}
                                            onChange={(e)=>setRole(e.role_id)}
                                            getOptionLabel ={(option)=>option.role_name}
                                            getOptionValue ={(option)=>option.role_id}
                                            isSearchable
                                            options={roleList}
                                            value={Array.isArray(roleList) && roleList.find(( value ) => value.role_id===role)}
                                        />
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">NIN</label>
                                        <input type="text" value={NIN} onChange={(e)=>setNIN(e.target.value)} className="form-control"/>
                                    </div>
            
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">District</label>
                                        <input type="text" value={district} onChange={(e)=>setDistrict(e.target.value)} className="form-control"/>
                                    </div>

                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Contact</label>
                                        <input type="tel" placeholder='e.g. 0701234567 ' value={contact} onChange={(e)=>setContact(e.target.value)} className="form-control"/>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <input type="submit" style={{float:"right"}} className="btn btn-success" value="Save User Details"/>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>	
						
	    </AppContainer>

    )
}

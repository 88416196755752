import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { useParams } from "react-router-dom";
import ajaxClient from "../util/remote/ajaxClient";
import { RenderSecure } from "../util/script/RenderSecure";
import RegionContext from "../Context/RegionContext";
import { Toaster, toast } from "react-hot-toast";
import ajaxUser from "../util/remote/ajaxUser";
import CreateContact from "../Components/Client/CreateContact";
import TableHeader from "../Components/Common/TableHeader";
import useStateCallback from "../util/customHooks/useStateCallback";
import ajaxClientContact from "../util/remote/ajaxClientContact";
import CreateVisit from "../Components/Client/CreateVisit";
import Select from "react-select";
import CreateFeedback from "../Components/Client/CreateFeedback";
import Loader from "../Components/Common/Loader";
import ClientContext from "../Context/ClientContext";
import ClientLocation from "../Components/Client/ClientLocation";

const ClientProfile = (props) => {
  const { id } = useParams();
  const { getClientList } = useContext(ClientContext);
  useEffect(() => {
    getClientProfile(id);
    //   getClientVisits(id);
    //   getClientContacts(id);
  }, [id]);

  const [clientProfile, setClientProfile] = useState(false);
  // const [clientContacts, setClientContacts] = useState(false);
  // const [clientVisits, setClientVisits] = useState(false);

  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const [active1, setActive1] = useState(false);
  const handleActive1 = () => setActive1(true);
  const handleInActive1 = () => setActive1(false);

  const [clientID, setClientID] = useState("");
  const [contact, setContact] = useState("");
  const [name, setClient] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [village, setVillage] = useState("");
  const [district, setDistrict] = useState("");
  const [lat, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [salesrep, setSalesRep] = useState("");
  const [salesrepList, setSalesRepList] = useState(false);
  const { regionList } = useContext(RegionContext);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  const [contactID, setContactID] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // const getLocation = () => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //     });
  // }

  // useEffect(()=>{
  //   getSalesReps()
  // },[])

  // useEffect(() => {
  //     if(!lat || !longitude){
  //         getLocation();
  //     }
  // }, [lat,longitude]);

  const getClientProfile = async (id) => {
    setLoading(true);
    const server_response = await ajaxClient.fetchProjectClientProfile(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setClientProfile(server_response.details);
    } else {
      setClientProfile("404");
    }
  };

  // const getSalesReps =async(id)=>{

  //   const server_response = await ajaxUser.fetchSalesReps();

  //     if(server_response.status==="OK"){
  //
  //         setSalesRepList(server_response.details);
  //     }else{
  //
  //         setSalesRepList("404");
  //     }
  // }

  // const getClientVisits =async(id)=>{

  //     const server_response = await ajaxClient.fetchClientVisits(id);

  //     if(server_response.status==="OK"){
  //
  //        setClientVisits(server_response.details);
  //     }else{
  //
  //        setClientVisits("404");
  //     }
  // }

  // const getClientContacts =async(id)=>{

  //   const server_response = await ajaxClientContact.fetchClientContacts(id);

  //     if(server_response.status==="OK"){
  //
  //         setClientContacts(server_response.details);
  //     }else{
  //
  //         setClientContacts("404");
  //     }
  // }

  const setClientUpdate = (item) => {
    handleActive();
    setClientID(clientProfile.client_id);
    setClient(clientProfile.name);
    setEmail(clientProfile.email);
    setDistrict(clientProfile.district);
    setContact(clientProfile.contact);
    setDescription(clientProfile.description);
  };

  const setContactUpdate = (e, item) => {
    e.preventDefault();
    handleActive1();
    setPhoneNumber(item.phone_number);
    setContactID(item.contact_id);
  };
  //    const handleUpdateContact = async(e) =>{

  //     e.preventDefault()
  //         const server_response = await ajaxClientContact.updateClientNumber(contactID,phoneNumber);
  //
  //         if(server_response.status==="OK") {
  //             setPhoneNumber('')
  //             toast.success(server_response.message)
  //             getClientContacts(id);

  //         }
  //         else{
  //         toast.error(server_response.message)
  //         }
  //     }

  // const handleModal=()=>{
  //     setModal(false, ()=>setModal(<CreateContact client_id={id} f={getClientContacts} isOpen={true}/>))
  // }

  // const handleModal2=()=>{
  //     setModal(false, ()=>setModal(<CreateVisit client_id={id} f={getClientVisits} isOpen={true}/>))
  // }

  // const handleModal3=(e,item1)=>{
  //     setModal(false, ()=>setModal(<CreateFeedback visit_id={item1.visit_id} client_id={id} f={getClientVisits}  isOpen={true}/>))
  // }

  // const handleModal4=(e,item)=>{
  //     setModal(false, ()=>setModal(<ClientLocation isOpen={true} client={clientProfile.name} position={{lat:clientProfile.lat*1,lng:clientProfile.longitude*1}}/>))
  // }

  // const handleClientUpdate = async(e) =>{
  //     e.preventDefault()

  //     if(region>0 || client_name.length>0 || email.length>0 || physicalAddress.length>0 || district.length>0){

  //         const server_response = await ajaxClient.updateProjectClient(clientID,region,salesrep,client_name,email,physicalAddress,village,district,lat,longitude,status);
  //         if(server_response.status==="OK"){
  //             setRegion("")
  //             setSalesRep("")
  //             setClient("")
  //             setEmail("")
  //             setPhysicalAddress("")
  //             setVillage("")
  //             setDistrict("")
  //             setLatitude("")
  //             setLongitude("")
  //             setStatus("")
  //             toast.success(server_response.message)
  //             getClientProfile(id)
  //             getClientList()

  //         }
  //         else{
  //             toast.error(server_response.message)
  //         }
  //     }

  //     else{
  //         toast.error("Complete all fields and try again")
  //     }
  // }

  const handleProjectClientUpdate = async (e) => {
    e.preventDefault();

    if (
      name.length > 0 ||
      email.length > 0 ||
      contact.length > 0 ||
      district.length > 0
    ) {
      const server_response = await ajaxClient.updateProjectClient(
        clientID,
        name,
        district,
        contact,
        email,
        description
      );
      if (server_response.status === "OK") {
        setClient("");
        setEmail("");
        setDistrict("");
        setContact("");
        setDescription("");
        toast.success(server_response.message);
        getClientProfile(id);
        getClientList();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  return (
    <AppContainer title={"Client Profile"}>
      <Toaster position="top-center" reverseOrder={false} />

      {modal}

      <div className="col-12 col-xl-12">
        <div
          className="box user-pro-list overflow-hidden mb-30"
          style={{
            marginBottom: "30px",
            backgroundColor: "white",
            padding: "25px",
            boxShadow: "10px",
            borderRadius: "10px",
          }}>
          {clientProfile && (
            <div className="box-body" style={{ position: "relative" }}>
              <div className="user-pic text-center">
                <div
                  className="avatar"
                  style={{
                    top: "17px",
                    margin: "0 auto",
                    width: "112px",
                    height: "112px",
                    position: "relative",
                  }}>
                  <img
                    src={
                      process.env.REACT_APP_DEVELOPMENTURL + "img/avatar.png"
                    }
                    alt=""
                    style={{ verticalAlign: "middle" }}
                  />
                </div>
                <div
                  className="pro-user mt-3"
                  style={{ marginTop: "1rem !important" }}>
                  <h5
                    className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span"
                    style={{ lineHeight: "1.5" }}>
                    {clientProfile.name}
                  </h5>
                  <h6 className="pro-user-desc text-muted fs-14">
                    {clientProfile.district}
                  </h6>
                </div>
              </div>
            </div>
          )}

          <div
            className="box-footer pt-41"
            style={{ paddingTop: "41px !important" }}>
            <div className="btn-list text-center">
              {active ? (
                <a
                  href="#"
                  onClick={handleInActive}
                  className="btn ripple btn-danger btn-sm mr-2">
                  <i className="fe fe-x"></i>Back
                </a>
              ) : (
                <a
                  href="#"
                  onClick={setClientUpdate}
                  className="btn ripple btn-warning btn-sm mr-2">
                  <i className="far fa-edit mr-1"></i>Update Details
                </a>
              )}
            </div>
          </div>
        </div>
        {loading && <Loader />}

        {active ? (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">
                {" "}
                Update Client Information
              </div>
            </div>
            <br />
            <div className="box-body pt-20 user-profile">
              <form onSubmit={handleProjectClientUpdate}>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Client Name:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={name}
                        onChange={(e) => setClient(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        E-mail:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Description:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Contact:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={contact}
                        onChange={(e) => setContact(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        District:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-sm-6">
                                            <label htmlFor="">Select Region:<span className="tx-danger">*</span></label>
                                            <Select
                                                onChange={(e)=>setRegion(e.region_id)}
                                                getOptionLabel ={(option)=>option.region_name}
                                                getOptionValue ={(option)=>option.region_id}
                                                isSearchable
                                                options={regionList}
                                                defaultValue={Array.isArray(regionList) && regionList.find(( value ) => value.region_id===region)}
                                            />
                                        </div> */}
                  </div>
                </div>
                {/* <div className="form-group">
						            <div className="row row-sm">
                                        <div className="col-sm-6">
							                <label htmlFor="">Latitude:<span className="tx-danger">*</span></label>
                                            <input type="text" defaultValue={lat} onChange={(e)=>setLatitude(e.target.value)} className="form-control" readOnly/>
                                        </div>
                                        <div className="col-sm-6">
							                <label htmlFor="">Longitude:<span className="tx-danger">*</span></label>
                                            <input type="text" defaultValue={longitude} onChange={(e)=>setLongitude(e.target.value)} className="form-control" readOnly/>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div className="form-group">
						            <div className="row row-sm">
                                        <div className="col-sm-6">
                                            <label htmlFor="">Sales Representative:<span className="tx-danger">*</span></label>
                                            <Select
                                                onChange={(e)=>setSalesRep(e.id)}
                                                getOptionLabel ={(option)=>option.first_name}
                                                getOptionValue ={(option)=>option.id}
                                                isSearchable
                                                isDisabled
                                                options={salesrepList}
                                                defaultValue={Array.isArray(salesrepList) && salesrepList.find(( value ) => value.id===salesrep)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="">Status:<span className="tx-danger">*</span></label>
                                            <select defaultValue={status} onChange={(e)=>setStatus(e.target.value)} name="" id="" className="form-control">
                                                <option selected>Select Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}

                <button className="btn ripple btn-main-primary btn-block">
                  Update Profile
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">
                Client Information
              </div>
            </div>
            <div className="box-body pt-20 user-profile">
              <div className="table-responsive">
                <table className="table mb-0 mw-100 color-span">
                  {clientProfile && (
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Client Name </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{clientProfile.name}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Description</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {clientProfile.description}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">E-mail</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{clientProfile.email}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Contact</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{clientProfile.contact}</span>{" "}
                        </td>
                      </tr>
                      {/* <tr>
                                            <td className="py-2 px-0"> <span className="w-50">Village</span> </td>
                                            <td>:</td>
                                            <td className="py-2 px-0"> <span className="">{clientProfile.village}</span> </td>
                                        </tr> */}
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">District</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {clientProfile.district}
                          </span>{" "}
                        </td>
                      </tr>
                      {/* <tr>
                                            <td className="py-2 px-0"> <span className="w-50">Region</span> </td>
                                            <td>:</td>
                                            <td className="py-2 px-0"> <span className="">{clientProfile.region_id.region_name}</span> </td>
                                        </tr> */}
                      {/* <tr>
                                            <td className="py-2 px-0"> <span className="w-50">Registered By</span> </td>
                                            <td>:</td>
                                            <td className="py-2 px-0"> <span className="">{clientProfile.user_id.first_name} {clientProfile.user_id.last_name}</span> </td>
                                        </tr> */}
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Registration Date</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {clientProfile.created_at.short_date}
                          </span>{" "}
                        </td>
                      </tr>
                      {/* <tr>
                                            <td className="py-2 px-0"> <span className="w-50">Location on Map</span> </td>
                                            <td>:</td>
                                            <td className="py-2 px-0"> <span className=""><button className="btn ripple btn-primary btn-sm" onClick={handleModal4}> <i className="fe fe-map-pin"> </i>View</button></span> </td>
                                        </tr> */}
                      {/* <tr>
                                            <td className="py-2 px-0"> <span className="w-50">Status</span> </td>
                                            <td>:</td>
                                            <td className="py-2 px-0"> {clientProfile.status==="Pending"?<span className="badge badge-warning">{clientProfile.status}</span>:
                                                                    clientProfile.status==="Rejected"?<span className="badge badge-danger">{clientProfile.status}</span>:
                                                                                                      <span className="badge badge-success">{clientProfile.status}</span>}
                                                                    
                                            </td>
                                        </tr> */}
                    </tbody>
                  )}
                </table>
                {loading && <Loader />}
              </div>
            </div>
          </div>
        )}

        {/* <div className="card custom-card" style={{borderRadius: "10px"}}>
                    <div className="card-body map-card">
                        <TableHeader
                            title="Contact Persons"
                            subtitle="List of all contact persons associated with the client"
                            viewButton={<a href='#' onClick={handleModal} className="btn btn-primary btn-sm" style={{float:"right"}}><i className="fe fe-plus mr-1"></i>Add Contact Person</a>}     
                        />
                        <div className="border-top mt-3"></div>                     
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(clientContacts) && clientContacts.length > 0 ? (
                                        clientContacts.map((item, key) => (
                                        <tr key={key}>
                                            <th scope="row">{key+1}</th>
                                            <td>{item.contact_name}</td>
                                            <td>{item.designation}</td>
                                            <td>{active1 && contactID===item.contact_id?
                                                        <form onSubmit={(e)=>handleUpdateContact(e)} method="post">
                                                             <div className="row">
                                                                <div className="mb-1 col-6">
                                                                    <input type="tel" defaultValue={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} className="form-control"/>
                                                                </div>
                                                                <div className="mb-4 col-3">
                                                                    <button className="btn ripple btn-primary btn-sm" ><i className="fe fe-check"></i>Update</button></div></div></form>
                
                                                :
                                                    item.phone_number
                                                }
                                            
                                            {active1&& contactID===item.contact_id?
                                                <button className="btn ripple btn-danger btn-sm" onClick={(e)=>handleInActive1()}><i className="fe fe-x">Cancel</i></button>
                                                :<i style={{color:"gray"}} onClick={(e)=>setContactUpdate(e,item)} className="fe fe-edit-2 ml-2"></i>}
                                            </td>
                                        </tr>
                                    ))
                                    ): (
                                        <tr>
                                            <td colSpan="4" style={{textAlign:"center"}}>No contact persons registered for this client yet.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {!clientContacts && <Loader/>}
                        </div>
                    </div>
			    </div>

                <div className="card custom-card" style={{borderRadius: "10px"}}>
                    <div className="card-body map-card">
                        <TableHeader
                            title="Team Visits"
                            subtitle="List of all visits conducted by different teams" 
                            viewButton={<a href="#" onClick={handleModal2} className="btn btn-primary btn-sm" style={{float:"right"}}><i className="fe fe-plus mr-1"></i>Register Team Visit</a>}   
                        />
                        <div className="border-top mt-3"></div>                     
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th scope="col" className="wd-5p">No.</th>
                                        <th scope="col">Team Name</th>
                                        <th scope="col">Project Marketed</th>
                                        <th scope="col">Date Of Visit</th>
                                        <th scope="col">Next Appointment</th>
                                        <th scope="col">Feedback</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(clientVisits) && clientVisits.length > 0 ? (
                                        clientVisits.map((item, key) => (
                                        <tr key={key}>
                                            <th scope="row">{key+1}</th>
                                            <td>{item.team.team_name}</td>
                                            <td>{item.project.project_name}</td>
                                            <td>{item.created_at.short_date}</td>
                                            <td>{item.revisit_date.short_date}</td>
                                            <td>
                                                {Array.isArray(item.feedback) && item.feedback.map((item1,key)=>
                                                    <span>
                                                        {item1.feedback}<br/>
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <a href="#" onClick={(e)=>handleModal3(e,item)}  className='btn btn-primary btn-sm'>
                                                    <i style={{color:'white'}} className='fe fe-plus'></i>Add Feedback
                                                </a>
                                            </td>
                                        </tr>
                                        ))
                                    ): (
                                        <tr>
                                            <td colSpan="6" style={{textAlign:"center"}}>No visits made to this client yet.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {!clientVisits && <Loader/>}
                        </div>
                    </div>
			    </div> */}
      </div>
    </AppContainer>
  );
};

export default ClientProfile;

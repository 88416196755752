import { useContext, useState } from "react"
import { toast } from 'react-hot-toast';
import ajaxIssueLog from "../../util/remote/ajaxIssueLog";
import IssueLogContext from "../../Context/IssueLogContext";
import ajaxRollCall from "../../util/remote/ajaxRollCall";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";

const RollCallDialog=(props)=>{

    const [loading, setLoading] = useState(false)

  

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                  
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={controls.close}>Ok, I understand</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Important Information"
            id="model-update-status"
            size="sm"
            footer={RenderFooter}
        >

            <div className="bg-white">
			    <div className="alert text-center">
				    <i className="fe fe-alert-circle fs-40 text-info" style={{color:'red'}}></i>
                    <h5 className="mt-2 mb-1">Once roll call is saved, changes cannot be made afterwards.</h5>
				</div>
                
			</div>
        </SystemModal>
    )

}

export default RollCallDialog

import React from 'react'
import AppContainer from '../Components/Structure/AppContainer';
import { RenderSecure } from '../util/script/RenderSecure'
import { Toaster} from 'react-hot-toast';
import useStateCallback from '../util/customHooks/useStateCallback';
import TeamsCard from '../Components/Team/TeamsCard';
import CreateTeam from '../Components/Team/CreateTeam';
import TeamsList from '../Components/Team/TeamsList';

export default function TeamPage(props) {
     
    const [modal, setModal] = useStateCallback(false);
       
    const handleModal=()=>{
        setModal(false, ()=>setModal(<CreateTeam isOpen={true}/>))
     }

    return (
        <AppContainer title="Teams Dashboard">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {modal}
            <div className="container-fluid p-0 sm_padding_15px">

                <div className="row">
                    <div className="col-lg-12">
          <RenderSecure code="ADD-TEAM">
                        <h6 className="card-title mb-4 d-flex justify-content-end">
                            <button onClick={handleModal} className="btn ripple btn-primary btn-with-icon btn-sm"><i className="fe fe-plus"></i> Add New Team</button>
                        </h6>
                </RenderSecure>
          <RenderSecure code="VIEW-TEAM-COUNT">
                        <div className="col-md-12 mt-1">
                            <TeamsCard/>
                        </div>  
                        </RenderSecure>
                    </div> 
                </div>

                <div className="row">
                    <TeamsList/>
                    {/* <TeamVisitGraph/> */}
              
                </div>
                     
            </div>
    
        </AppContainer>
    )
}


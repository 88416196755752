import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import ajaxProject from "../../util/remote/ajaxProject";

export default function ProjectCard() {
  const [projectsNumber, setProjectsNumber] = useState(false);

  const getProjectsNumber = async () => {
    const server_response = await ajaxProject.fetchProjectNumber();

    if (server_response.status === "OK") {
      setProjectsNumber(server_response.details);
    } else {
      setProjectsNumber("404");
    }
  };

  useEffect(() => {
    getProjectsNumber();
  }, []);

  return (
    <Card
      name="Projects"
      color="dark"
      icon="fe fe-folder-plus"
      value={projectsNumber ? projectsNumber.total_p : "..."}
      link="/projects"
    />
  );
}

import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxDeliverable from "../../util/remote/ajaxDeliverable";
import Select from "react-select";

const CreateSubDeliverable = (props) => {
  const [subDeliverable, setSubDeliverable] = useState("");
  const [user, setUser] = useState("");
  const [duration, setDuration] = useState("");
  const [developerList, setDeveloperList] = useState("");
  const [loading, setLoading] = useState(false);

  const getDevelopers = async () => {
    const server_response = await ajaxUser.fetchDevelopers();
    if (server_response.status === "OK") {
      setDeveloperList(server_response.details);
    } else {
      setDeveloperList("404");
    }
  };

  useEffect(() => {
    getDevelopers();
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      subDeliverable.length > 0 &&
      props.deliverable_id > 0 &&
      user > 0 &&
      duration > 0
    ) {
      setLoading(true);
      const server_response = await ajaxDeliverable.createSubDeliverable(
        props.deliverable_id,
        subDeliverable,
        user,
        duration
      );
      setLoading(false);
      if (server_response.status === "OK") {
        setSubDeliverable("");
        setUser("");
        setDuration("");
        toast.success(server_response.message);
        props.f(props.deliverable_id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Sub Deliverable
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Sub Deliverable"
      id="model-new-subDeliverable"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Sub Deliverable Details</label>
        <input
          type="text"
          value={subDeliverable}
          onChange={(e) => setSubDeliverable(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Assign Developer</label>
        <Select
          onChange={(e) => setUser(e.id)}
          getOptionLabel={(option) => option.first_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={developerList}
          value={
            Array.isArray(developerList) &&
            developerList.find((value) => value.id === user)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Duration (in hours)</label>
        <input
          type="number"
          value={duration}
          min={0}
          onChange={(e) => setDuration(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default CreateSubDeliverable;

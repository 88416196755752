import apiCall from "./apiCall";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async fetchRoleList(data) {
       
        let response = await apiCall("role/list", data)
        return response;
       
      },
      async createRole(role_name) {
        let data = {
          "role_name":role_name
        }
       
        let response = await apiCall("role/create", data)
        return response;
      
      },




}
import React from 'react'
import CountUp from "react-countup";
import { Link } from 'react-router-dom';

export default function Card(props) {
  return (
    
      <Link to={props.link}>
      <div className="card custom-card" style={{borderRadius: "10px"}}>
        <div className="card-body text-center">
          <div className={`icon-service bg-${props.color}-transparent rounded-circle text-primary`}>
            <i className={`${props.icon}`}></i>
          </div>
          <p className="mb-1 text-muted">{props.name}</p>
          <h3 className="mb-0">
            <CountUp
              start={0}
              end={props.value}
            />
          </h3>
        </div>
      </div>
      </Link>
    
  )
}

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import AppContainer from "../../Components/Structure/AppContainer";
import useStateCallback from "../../util/customHooks/useStateCallback";
import AddPole from "../../Components/ProjectTracker/AddPole";
import TableHeader from "../../Components/Common/TableHeader";
import UpdatePole from "../../Components/ProjectTracker/UpdatePole";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";
function ListPolesProducts() {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState(false);
  const { batch, id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState(false);
  // const [page, setPage] = useState(1);
  // const [meta, setMeta] = useState("");

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(<AddPole id={id} batch={batch} f={listProducts} isOpen={true} />)
    );
  };

  const handleModal2 = (e,item) => {
    setModal(false, () =>
      setModal(<UpdatePole id={id} poles_id={item.id} batch={batch} f={listProducts} isOpen={true} wkt_geom={item.wkt_geom} fid={item.fid} idd={item.idd} id_town={item.id_town} id_neighborhood={item.id_neighborhood} pole_id={item.pole_id} trunk={item.trunk} height={item.height} contractor={item.contractor}/>)
    );
  };

  

  useEffect(() => {
    SearchPoles();
  }, [searchQuery, batch]);

  const SearchPoles = async () => {
    setLoading(true);
    const server_response = await ajaxProject.searchPoles(searchQuery);
    setLoading(false);
    if (searchQuery.length > 0) {
      if (server_response.status === "OK") {
        setProductList(server_response.details);
      } else {
        setProductList(server_response.details);
      }
    } else {
      setLoading(true);
      const server_response = await ajaxProject.fetchPoleBatchProducts(batch);
      setLoading(false);

      if (server_response.status === "OK") {
        setProductList(server_response.details);
      }
    }
  };

  const listProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProject.fetchPoleBatchProducts(batch);
    setLoading(false);
    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };
 

  const exportToExcelPayment = () => {
    // Prepare data for export
    if(Array.isArray(productList)){
      const data = productList.map((item, index) => ({
        "wkt_geom": item.wkt_geom,
        "fid": item.fid,
        "id": item.idd,
        "id_town": item.id_town,
        "id_neighborhood": item.id_neighborhood,
        "Pole ID": item.pole_id,
        "Trunk": item.trunk,
        "height": item.height,
        "Contractor": item.contractor,
        "Status": item.status,
        "install_date": item.install_date
      })) 
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);
    
      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Poles");
    
      // Generate a file name
      const fileName = "poles_data.xlsx";
    
      // Save the file
      XLSX.writeFile(wb, fileName);
    }
    else{
      toast.error("Cannot export empty table to excel");
  
    }
    
  };

  return (
    <AppContainer title="Batch Poles List">
      <>
        <Toaster position="top-center" reverseOrder={false} />
        {modal}
        <Link
          to={`/project/poles/${id}`}
          className="btn btn-primary btn-sm mb-2"
        >
          Back To Poles
        </Link>
        <div className="mt-4 mb-4">
          <div class="input-group">
            <input
              class="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for..."
              type="text"
            />
            <span class="input-group-btn">
              <button
                class="btn ripple btn-primary"
                style={{
                  height: 26,
                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
                type="button"
              >
                <span class="input-group-btn">
                  <i class="fa fa-search"></i>
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="card custom-card">
          <div className="card-body map-card">
          <button style={{float:'right'}} onClick={exportToExcelPayment} className="btn btn-sm btn-secondary mb-3 ml-3 mr-3">
                                Export to Excel
                              </button>
          <TableHeader
                                title="Upload Batch Products" 
                                viewButton={
                                  <>
                                 
                                <a href="#" onClick={handleModal} className="btn btn-primary btn-sm ml-3" style={{float:"right"}}>Add Pole</a>
                               
                              </>
                            }  
                            />

            <div className="table-responsive">
              <div className="border-top mt-3"></div>
              {loading && <Loader />}
              {!loading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>wkt_geom</th>
                      <th>fid</th>
                      <th>id</th>
                      <th>id_town</th>
                      <th>id_neighborhood</th>
                      <th>Pole ID</th>
                      <th>Trunk</th>
                      <th>height</th>
                      <th>Contractor</th>
                      <th>Status</th>
                      <th>install_date</th>
                      {/* <th>edit</th> */}
                    </tr>
                  </thead>
                  <tbody>
                  
                    {!Array.isArray(productList) && <tr>No poles found</tr>}
                    {Array.isArray(productList) &&
                      productList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.wkt_geom}</td>
                          <td>{item.fid}</td>
                          <td>{item.idd}</td>
                          <td>{item.id_town}</td>
                          <td>{item.id_neighborhood}</td>
                          <td>{item.pole_id}</td>
                          <td>{item.trunk}</td>
                          <td>{item.height}</td>
                          <td>{item.contractor}</td>
                          <td>{item.status}</td>
                          <td>{item.install_date}</td>
                          <td>
                            <a
                              onClick={(e) => handleModal2(e, item)}
                              className="btn btn-primary btn-sm"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    </AppContainer>
  );
}

export default ListPolesProducts;

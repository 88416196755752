import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import Select from 'react-select'
import ajaxUser from "../../util/remote/ajaxUser";
import UserContext from "../../Context/UserContext";

const CreateTeamAllocation=(props)=>{

    const {userList} = useContext(UserContext);
    const [loading,setLoading] = useState(false)
    const [user,setUser] = useState("")

    const handleAdd = async(e) =>{

        e.preventDefault()
        if(props.team_id>0 && user>0){
            setLoading(true)
            const server_response = await ajaxUser.createUserAllocation(user,props.team_id);
            setLoading(false) 
            if(server_response.status==="OK") {
                setUser("")
                toast.success(server_response.message)
                props.f(props.team_id)
            }
            else{
                toast.error(server_response.message)
            }      
        }
        else{
   
            toast.error("Complete all fields and try again")
        }  
    }

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Allocation</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Add New User Allocation"
            id="model-new-userallocation"
            size="md"
            footer={RenderFooter}
        >
        
       
            <div className="mb-4">
                <label htmlFor="">Select User</label>
                <Select
                    onChange={(e)=>setUser(e.id)}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                    getOptionValue={(option) => `${option.first_name} ${option.last_name}`}
                    isSearchable
                    options={userList}
                    value={Array.isArray(userList) && userList.find(( value ) => value.id===user)}
                />
            </div>

        </SystemModal>
    )
}

export default CreateTeamAllocation
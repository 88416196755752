import React, { useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import Pits from "./Pits";
import PolePlanting from "./PolePlanting";
import PolePlumbing from "./PolePlumbing";
import Crosses from "./Crosses";
import OTBs from "./OTBs";
import Hardware from "./Hardware";
import ImageUpload from "./ImageUpload";
import View from "./View";
import { useNavigate } from "react-router-dom";
import Joints from "./Joints";

function AddInstallations() {
  const [activeTab, setActiveTab] = useState("view");
  const navigation = useNavigate();

  const toggleTab = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <AppContainer title="Installation Details">
      <div className="card custom-card main-content-body-profile">
        <nav className="nav main-nav-line">
          {/* <a
            className={`nav-link ${activeTab === "view" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "view")}
            href="#view"
          >
            View
          </a> */}
          <a
            className={`nav-link ${activeTab === "pit" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "pit")}
            href="#pit"
          >
            Pit{" "}
          </a>
          <a
            className={`nav-link ${activeTab === "planting" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "planting")}
            href="#planting"
          >
            Pole Planting
          </a>

          <a
            className={`nav-link ${activeTab === "plumbing" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "plumbing")}
            href="#plumbing"
          >
            Pole Plumbing
          </a>
          <a
            className={`nav-link ${activeTab === "crosses" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "crosses")}
            href="#crosses"
          >
            Crosses
          </a>

          <a
            className={`nav-link ${activeTab === "otbs" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "otbs")}
            href="#otbs"
          >
            OTBs
          </a>

          <a
            className={`nav-link ${activeTab === "joints" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "joints")}
            href="#joints"
          >
            Joints
          </a>

          <a
            className={`nav-link ${activeTab === "hardware" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "hardware")}
            href="#hardware"
          >
            Hardware
          </a>

          <a
            className={`nav-link ${activeTab === "images" ? "active" : ""}`}
            // onClick={(e) => toggleTab(e, "images")}
            href="#images"
          >
            Images
          </a>
        </nav>
        <div className="card-body tab-content h-100">
          <div
            className={`tab-pane h-100 ${activeTab === "view" ? "active" : ""}`}
            id="view"
          >
            <View navi={navigation} toggle={toggleTab} />
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default AddInstallations;

import React, { useContext, useEffect, useState } from 'react'
import AppContainer from '../../Components/Structure/AppContainer'
import SystemModal from '../../Components/Common/SystemModal'
import Loader from '../../Components/Common/Loader'
import { Toaster, toast } from 'react-hot-toast'
import ajaxTask from '../../util/remote/ajaxTask'
import { useParams } from 'react-router-dom'
import ajaxDailyOutput from '../../util/remote/ajaxDailyOutput'
import TeamContext from '../../Context/TeamContext'
import Select from 'react-select'

function ViewOutput(props) {
    const {id} = useParams()
    const {teamList} = useContext(TeamContext)
    const [loading, setLoading] = useState(false)

    const [edit,setEdit] = useState(false)
    const [outputId,setOutputId] = useState(props.outputId)
    const [teamId,setTeamID] = useState(props.teamId)
    const [teamName,setTeamName] = useState(props.teamName)
    const [dailyExpectation,setDailyExpectation] = useState(props.dailyExpectation)
    const [achievedWork,setAchievedWork] = useState(props.achievedWork)
    const [task_id,setTaskId] = useState(props.taskId)
    const [site,setSite] = useState(props.site)




    const handleUpdateOutput = async(e) =>{
        e.preventDefault()
        if(teamId>0){
            setLoading(true)
            const server_response = await ajaxDailyOutput.updateOutput(outputId,achievedWork,teamId,site);
            if(server_response.status==="OK"){
                toast.success(server_response.message)
                setTimeout(() => setLoading(false), 1000);
                props.getDailyOutput()
            }
            else{
                toast.error(server_response.message) 
            }
        }
        else{
            toast.error("Complete all fields and try again")
        }
    } 


    const RenderFooter = (controls) => {
        
          if (edit) {
            return (
              <> 
               
                <button className="btn ripple btn-dark" type="button" onClick={controls.close}>
                  Close
                </button>
                {loading &&  <button disabled className="btn btn-success" >
                        Submitting
                        </button>
                  }
                {!loading && <button type="submit" className="btn ripple btn-success" onClick={handleUpdateOutput}>
                  Update Task
                </button>}
              </>
            );
          } else {
            return (
              <button
                type="button"
                className="btn ripple btn-secondary"
                onClick={() => setEdit(true)} // Pass a function to setEdit
              >
                Edit <i className='fa fa-pencil'></i>
              </button>
            );
          }
        
      };
  return (
    <>
    <Toaster />
    <SystemModal
    title={`Output Details for ${props.teamName}`}
    id="model-output-details"
    size="md"
    footer={RenderFooter}
>
        
                <div className='row'>
                    
                    {edit ?
                     <form  method="post">
                    <div className="row m-1">
                <div className="mb-4 col-md-12">
                    <label htmlFor="">Team Name: <span className="tx-danger">*</span></label>
                    <Select
                    onChange={(e)=>setTeamID(e.team_id)}
                    getOptionLabel ={(option)=>option.team_name}
                    getOptionValue ={(option)=>option.team_id}
                    isSearchable
                    options={teamList}                        
                    value={Array.isArray(teamList) && teamList.find(( value ) => value.team_id===teamId)}
                />
                   
                </div>
                <div className="mb-4 col-md-12">
                    <label htmlFor="">Achieved Work:<span className="tx-danger">*</span></label>
                    <input type="text" defaultValue={achievedWork} onChange={(e)=>setAchievedWork(e.target.value)} className="form-control"/>
                </div>
                <div className="mb-4 col-md-12">
                    <label htmlFor="">Site:<span className="tx-danger">*</span></label>
                    <input type="text" defaultValue={site} onChange={(e)=>setSite(e.target.value)} className="form-control"/>
                </div>
                
            </div>
            </form>


                 :   

                <ul class="list-group w-100" >
                  <li class="list-group-item">Team Name: <b>{teamName}</b></li>
                  <li class="list-group-item">Achieved Work:<b>{achievedWork}</b></li>
                  <li class="list-group-item">Site: <b>{site}</b></li>
                </ul>
               
           
}
                </div>
            

   </SystemModal>
    </>
    
   
  )
}

export default ViewOutput
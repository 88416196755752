import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import TeamContext from "../../Context/TeamContext";
import Select from "react-select";
import TableHeader from "../../Components/Common/TableHeader";
import Loader from "../../Components/Common/Loader";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
function CreatePayment() {
  const { teamList } = useContext(TeamContext);

  const { id } = useParams();
  const [teamId, setTeamID] = useState("");
  const [site, setSite] = useState("");
  const [payment, setPayment] = useState("");
  const [amount, setAmount] = useState("");
  const navigation = useNavigate();

  const getPayment = async () => {
    const server_response = await ajaxDailyOutput.fetchPayment(id);

    if (server_response.status === "OK") {
      const updatedPayment = server_response.details.map((item) => ({
        ...item,
        amount: "",
      }));
      setPayment(updatedPayment);
    }
  };
  const handleAmountChange = (e, key) => {
    const newValue = e.target.value;
    const updatedPayment = [...payment];
    updatedPayment[key].amount = newValue;
    setPayment(updatedPayment);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    Array.isArray(payment) &&
      payment.map(async (item, index) => {
        if (item.amount.length > 0) {
          const server_response = await ajaxPayRate.createPayment(
            item.employee_id,
            item.role_id,
            item.roll_call_id,
            item.output_id,
            item.amount,
            item.date_added.short_date
          );

          if (server_response.status === "OK") {
            toast.success(server_response.message);
            setTimeout(() => {
              navigation("/payments");
            }, 1000);
          } else {
            toast.error(server_response.message);
          }
        } else {
          toast.error("Please fill in the amount fields");
        }
      });
  };

  useEffect(() => {
    getPayment();
  }, [id]);

  return (
    <AppContainer title="payment Details">
      <Toaster />
      <div className="card custom-card" style={{ borderRadius: "10px" }}>
        <div className="card-body map-card">
          <TableHeader
            title="Payment Details"
            subtitle="Details about payment"
          />
          <div className="border-top mt-3"></div>
          <div className="user-manager scroll-widget border-top">
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date of Output</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(payment) && payment.length > 0 ? (
                    payment.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{item.employee}</td>
                        <td>{item.role}</td>
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          value={item.amount}
                          onChange={(e) => handleAmountChange(e, key)}
                        />
                        <td>{item.date_added.short_date}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No roll call registered yet for this output.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td>
                      <button
                        type="submit"
                        onClick={(e) => handlePayment(e)}
                        className="btn btn-success btn-sm"
                        style={{ float: "right" }}>
                        Confirm Payment
                      </button>
                      <a
                        href="#"
                        className="btn btn-danger btn-sm"
                        style={{ float: "right", marginRight: "10px" }}
                        onClick={() => navigation(-1)}>
                        Cancel
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {!payment && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default CreatePayment;

import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import Select from 'react-select'
import TeamContext from "../../Context/TeamContext";
import ajaxUser from "../../util/remote/ajaxUser";
import ProjectContext from "../../Context/ProjectContext";

const CreateUserAllocation=(props)=>{

    const {teamList} = useContext(TeamContext);
    const [loading,setLoading] = useState(false)
    const [team,setTeam] = useState("")

    const handleAdd = async(e) =>{

        e.preventDefault()
        if(props.user_id>0 && team>0){
            setLoading(true)
            const server_response = await ajaxUser.createUserAllocation(props.user_id,team);
            setLoading(false) 
            if(server_response.status==="OK") {
                setTeam("")
                toast.success(server_response.message)
                props.f(props.user_id)
            }
            else{
                toast.error(server_response.message)
            }      
        }
        else{
   
            toast.error("Complete all fields and try again")
        }  
    }

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Allocation</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Add New Team Allocation"
            id="model-new-teamallocation"
            size="md"
            footer={RenderFooter}
        >
        
       
            <div className="mb-4">
                <label htmlFor="">Select Team</label>
                <Select
                    onChange={(e)=>setTeam(e.team_id)}
                    getOptionLabel ={(option)=>option.team_name}
                    getOptionValue ={(option)=>option.team_id}
                    isSearchable
                    options={teamList}
                    value={Array.isArray(teamList) && teamList.find(( value ) => value.team_id===team)}
                />
            </div>

        </SystemModal>
    )
}

export default CreateUserAllocation
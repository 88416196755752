import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ajaxProject from "../../util/remote/ajaxProject";

export default function ProjectCard() {
  const [highPriorityNumber, setHighPriorityNumber] = useState(false);

  const getHighPriorityProjects = async () => {
    const server_response = await ajaxProject.fetchHighProjectNumber();

    if (server_response.status === "OK") {
      setHighPriorityNumber(server_response.details);
    } else {
      setHighPriorityNumber("404");
    }
  };

  useEffect(() => {
    getHighPriorityProjects();
  }, []);

  return (
    <div
      className="icon-box bg-color-6 d-block mt-4 mb-3"
      style={{
        background: "#FEE9ED",
        width: "23%",
        padding: "20px",
        margin: "15px 0 !important",
      }}>
      <div className="content text-center color-6">
        <h5 className="title-box fs-17 font-w500">High Priority Projects</h5>
        <div
          className="excellencetheme-counter fs-18 font-wb"
          style={{ fontWeight: "bold", color: "#F7284A" }}>
          <span
            className="number"
            data-from="0"
            data-to="309"
            data-speed="2500"
            data-inviewport="yes">
            <CountUp
              start={0}
              end={highPriorityNumber ? highPriorityNumber.total_p : "..."}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

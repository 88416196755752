import React, { useEffect, useState } from "react";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import { Link, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

function ImageUpload(props) {
  const [picture, setPicture] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [pole, setPole] = useState(false);
  const { id, number } = useParams();

  const handleImageUpload1 = async (e) => {
    e.preventDefault();
    if (picture.length > 0 && description.length > 0) {
      const server_response = await ajaxProject.uploadImage1(
        id,
        picture,
        description
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.navi(`/installations/create/${id}#images`);
      }
    } else {
      toast.error("Complete all fields and try again!");
    }
  };

  const handleImageUpload2 = async (e) => {
    e.preventDefault();
    if (picture.length > 0 && description.length > 0) {
      const server_response = await ajaxProject.uploadImage2(
        id,
        picture,
        description
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.navi(`/installations/create/${id}#images`);
      }
    } else {
      toast.error("Complete all fields and try again!");
    }
  };

  const handleImageUpload3 = async (e) => {
    e.preventDefault();
    if (picture.length > 0 && description.length > 0) {
      const server_response = await ajaxProject.uploadImage3(
        id,
        picture,
        description
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.navi(`/installations/create/${id}#images`);
      }
    } else {
      toast.error("Complete all fields and try again!");
    }
  };

  const handleImageUpload4 = async (e) => {
    e.preventDefault();
    if (picture.length > 0 && description.length > 0) {
      const server_response = await ajaxProject.uploadImage4(
        id,
        picture,
        description
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.navi(`/installations/create/${id}#images`);
      }
    } else {
      toast.error("Complete all fields and try again!");
    }
  };

  const changePicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setPicture(e.target.result);
      // console.log(image)
    };
  };

  useEffect(() => {
    getPole();
  }, [id]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);
    // console.log(server_response.details);
    if (server_response.status === "OK") {
      setPole(server_response.details);
      if (number * 1 === 1) {
        setDescription(server_response.details.image1_desc);
      } else if (number * 1 === 2) {
        setDescription(server_response.details.image2_desc);
      } else if (number * 1 === 3) {
        setDescription(server_response.details.image3_desc);
      } else if (number * 1 === 4) {
        setDescription(server_response.details.image4_desc);
      }
    } else {
      setPole(false);
    }
  };

  return (
    <div className="row">
      <Toaster />
      {loading && <Loader />}
      {number * 1 === 1 && (
        <div className="col-md-6 mt-4 mb-4">
          <h5>Image 1</h5>

          <form onSubmit={(e) => handleImageUpload1(e)}>
            <br />
            <Link
              onClick={() => props.navi(-1)}
              className="btn btn-primary btn-sm mb-2"
            >
              Back To Previous Page
            </Link>
            <h6>
              <small className="text-muted">Pole ID : </small>
              {pole.pole_id}
            </h6>

            <br />
            <div className="form-group mt-3">
              <div className="mb-4 col-md-12">
                <label>
                  <b>Select image </b>
                  {/* <small className="text-danger">
                    * optional when updating the description *
                  </small> */}
                </label>
                <input
                  type="file"
                  onChange={(e) => changePicture(e)}
                  multiple
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label>
                  <b>Image Description</b>
                </label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiple
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-2 mb-2">
              <button type="submit" className="btn btn-success w-100">
                Upload
              </button>
            </div>
          </form>
        </div>
      )}

      {number * 1 === 2 && (
        <div className="col-md-6  mt-4 mb-4">
          <h5>Image 2</h5>

          <form onSubmit={(e) => handleImageUpload2(e)}>
            <br />
            <Link
              onClick={() => props.navi(-1)}
              className="btn btn-primary btn-sm mb-2"
            >
              Back To Previous Page
            </Link>
            <h6>
              <small className="text-muted">Pole ID : </small>
              {pole.pole_id}
            </h6>

            <br />
            <div className="form-group mt-3">
              <div className="mb-4 col-md-12">
                <label>
                  <b>Select image </b>
                  {/* <small className="text-danger">
                    * optional when updating the description *
                  </small> */}
                </label>
                <input
                  type="file"
                  onChange={(e) => changePicture(e)}
                  multiple
                  className="form-control"
                />
              </div>

              <div className="mb-4 col-md-12">
                <label>
                  <b>Image Description</b>
                </label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiple
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-2 mb-2">
              <button type="submit" className="btn btn-success w-100">
                Upload
              </button>
            </div>
          </form>
        </div>
      )}

      {number * 1 === 3 && (
        <div className="col-md-6">
          <h5>Image 3</h5>

          <form onSubmit={(e) => handleImageUpload3(e)}>
            <br />
            <Link
              onClick={() => props.navi(-1)}
              className="btn btn-primary btn-sm mb-2"
            >
              Back To Previous Page
            </Link>
            <h6>
              <small className="text-muted">Pole ID : </small>
              {pole.pole_id}
            </h6>

            <br />
            <div className="form-group mt-3">
              <div className="mb-4 col-md-12">
                <label>
                  <b>Select image </b>
                  {/* <small className="text-danger">
                    * optional when updating the description *
                  </small> */}
                </label>
                <input
                  type="file"
                  onChange={(e) => changePicture(e)}
                  multiple
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label>
                  <b>Image Description</b>
                </label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiple
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-2 mb-2">
              <button type="submit" className="btn btn-success w-100">
                Upload
              </button>
            </div>
          </form>
        </div>
      )}

      {number * 1 === 4 && (
        <div className="col-md-6">
          <h5>Image 4</h5>
          <form onSubmit={(e) => handleImageUpload4(e)}>
            <br />
            <Link
              onClick={() => props.navi(-1)}
              className="btn btn-primary btn-sm mb-2"
            >
              Back To Previous Page
            </Link>
            <h6>
              <small className="text-muted">Pole ID : </small>
              {pole.pole_id}
            </h6>

            <br />
            <div className="form-group mt-3">
              <div className="mb-4 col-md-12">
                <label>
                  <b>Select image </b>
                  {/* <small className="text-danger">
                    * optional when updating the description *
                  </small> */}
                </label>
                <input
                  type="file"
                  onChange={(e) => changePicture(e)}
                  multiple
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label>
                  <b>Image Description</b>
                </label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiple
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-2 mb-2">
              <button type="submit" className="btn btn-success w-100">
                Upload
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;

import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxRollCall from "../../util/remote/ajaxRollCall";
import ajaxReports from "../../util/remote/ajaxReports";
import Loader from "../../Components/Common/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

function RollcallReports() {
  const [startDate, setStartDate] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rollcallList, setRollcallList] = useState(false);
  const [rollcallSearch, setRollcallSearch] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRollcall();
  }, []);

  const getRollcall = async () => {
    setLoading(true);
    const server_response = await ajaxRollCall.fetchRollcall();
    setLoading(false);
    if (server_response.status === "OK") {
      setRollcallList(server_response.details);
    }
  };

  const searchRollcall = async (e) => {
    e.preventDefault();
    setLoading(true);
    const server_response = await ajaxReports.searchRollcall(
      startDate,
      endDate,
      status
    );
    setLoading(false);

    if (server_response.status === "OK") {
      if (server_response.details.length === 0) {
        // set the state to an empty array to show an empty table
        setRollcallSearch([]);
      } else {
        setRollcallSearch(server_response.details);
      }
    } else {
      setRollcallSearch([]);
    }
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const resetValues = () => {
    setEndDate("");
    setStartDate("");
    setStatus("");
    setRollcallSearch(false);
  };

  const exportToExcel = () => {
    // Prepare data for export
    const data = (rollcallSearch || rollcallList).map((item, index) => ({
      Employee: `${item.employee}`,
      Status: item.status === "1" ? "Present" : "Absent",
      Date: item.date.short_date,
    }));

    // Create a worksheet from your table data
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rollcall Data");

    // Generate a file name
    const fileName = "rollcall_data.xlsx";

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  // Function to export data to PDF
  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Rollcall data"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = ["#NO", "Employee", "Status", "Date"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`rollcal_data_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <AppContainer title="Rollcall Report">
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            {/* <TableHeader
                            title="Rollcall Report" 
                             
                        /> */}

            <div class="">
              <a
                onClick={toggleDropdown}
                href="#"
                className="btn ripple btn-secondary navresponsive-toggler mb-0">
                <i className="fe fe-filter mr-1"></i> Filter{" "}
                <i
                  className={`fas fa-caret-${
                    isDropdownVisible ? "up" : "down"
                  } ml-1`}></i>
              </a>
              <button
                style={{ float: "right" }}
                onClick={exportToExcel}
                className="btn btn-primary mb-3">
                Export to Excel
              </button>
              <button
                style={{ float: "right" }}
                onClick={exportToPDF}
                className="btn btn-primary btn-secondary mb-3 mr-3">
                Export to PDF
              </button>
            </div>
            <br />
            <br />
            {isDropdownVisible && (
              <div class="responsive-background" data-select2-id="9">
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  data-select2-id="navbarSupportedContent">
                  <div className="row">
                    <div className="col-md-12 col-xl-12 mb-0">
                      <h5>Search</h5>
                      <div className="row">
                        <div className="col-md-12 col-xl-12 mb-3">
                          <b>By Date:</b>
                        </div>

                        <div className="col-md-12 col-xl-6 mb-0">
                          <div className="form-group">
                            {" "}
                            <label className="form-label">From:</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div
                                  className="input-group-text"
                                  style={{ height: "100%" }}>
                                  <i className="fe fe-calendar"></i>{" "}
                                </div>
                              </div>
                              <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-6 mb-0">
                          <div className="form-group">
                            {" "}
                            <label className="form-label">To:</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div
                                  className="input-group-text"
                                  style={{ height: "100%" }}>
                                  <i className="fe fe-calendar"></i>{" "}
                                </div>
                              </div>
                              <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div className='form-group col-xl-6 mt-2'>
                                    <b>By Team:</b><br/>
                                    <Select />
                                  </div> */}

                        <div className="form-group col-xl-6 mt-2">
                          <b>By Status:</b>
                          <br />
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                            <option default value="">
                              Select status
                            </option>
                            <option value={""}>All</option>
                            <option value={"0"}>Absent</option>
                            <option value={"1"}>Present</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-12 mb-0">
                      <div
                        className="form-group mt-32"
                        style={{ marginTop: "32px" }}>
                        {" "}
                        <a
                          href="#"
                          onClick={searchRollcall}
                          className="btn bg-primary btn-block color-white"
                          style={{
                            color: "white",
                            display: "block",
                            width: "100%",
                          }}>
                          Search Rollcall
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="border-top mt-5"></div>
            <div className="table-responsive">
              {loading && <Loader />}
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">#NO</th>
                    <th scope="col">Employee</th>
                    <th scope="col"> Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {rollcallSearch && Array.isArray(rollcallSearch) ? (
                    rollcallSearch.length > 0 ? (
                      rollcallSearch.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          {/* {
      "call_id": "268",
      "employee": "Ayiomungu Patricia",
      "status": "1",
      "date": {
        "long_date": "05 Mar 2024 at 05:00 pm",
        "short_date": "05 Mar 2024",
        "when": "05 Mar 2024 at 05:00 pm",
        "time": "05:00 pm",
        "date": "05 Mar 2024",
        "weekday": "Tuesday",
        "db": "2024-03-05"
      }
    }, */}
                          <td>{item.employee}</td>
                          <td>
                            {item.status === "1" ? (
                              <span className="badge badge-success">
                                Present
                              </span>
                            ) : (
                              <span className="badge badge-danger">Absent</span>
                            )}
                          </td>
                          <td>{item.date.short_date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No rollcall found.</td>
                      </tr>
                    )
                  ) : (
                    Array.isArray(rollcallList) &&
                    rollcallList.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{item.employee}</td>
                        <td>
                          {item.status === "1" ? (
                            <span className="badge badge-success">Present</span>
                          ) : (
                            <span className="badge badge-danger">Absent</span>
                          )}
                        </td>
                        <td>{item.date.short_date}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {/* {!payments && <Loader/>} */}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default RollcallReports;

import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import TableHeader from "../Components/Common/TableHeader";
import ajaxClient from "../util/remote/ajaxClient";
import useStateCallback from "../util/customHooks/useStateCallback";
import VisitLocation from "../Components/Client/VisitLocation";
import { Toaster, toast } from "react-hot-toast";
import ajaxPayRate from "../util/remote/ajaxPayRate";
import CreateTaskPayment from "./PayRate/CreateTaskPayment";
import CreatePaymentDeposit from "./PayRate/CreatePaymentDeposit";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function PaymentsPage() {
  const [payments, setPayments] = useState(false);
  const [paymentsSearch, setPaymentsSearch] = useState(false);
  const [modal, setModal] = useStateCallback(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const searchPayments = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates.");
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error("Start date should be before end date.");
    } else {
      const server_response = await ajaxPayRate.searchPayments(
        startDate,
        endDate
      );
      if (server_response.status === "OK") {
        if (server_response.details.length === 0) {
          // set the state to an empty array to show an empty table
          setPaymentsSearch([]);
        } else {
          setPaymentsSearch(server_response.details);
        }
      } else {
        setPaymentsSearch([]);
      }
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Payment Summary Report"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table
    const columns = [
      "Payment Date",
      "Employee Name",
      "Debit",
      "Credit",
      "Deposited By",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180, // , // Start after the company details
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`payment_data_${new Date().toLocaleDateString()}.pdf`);
  };

  const exportToExcel = () => {
    // Prepare data for export
    const data = (paymentsSearch || payments).map((item, index) => ({
      "Payment Date": item.created_at.long_date,
      "First Name": item.employee,
      Debit: item.amount_out.amount_out_c,
      Credit: item.amount.amount_c,
      "Deposited By": item.added_by,
    }));

    // Create a worksheet from your table data
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payment Data");

    // Generate a file name
    const fileName = "payment_data.xlsx";

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  const getPayments = async () => {
    setLoading(true);
    const server_response = await ajaxPayRate.fetchAllPayments();
    setLoading(false);
    if (server_response.status === "OK") {
      setPayments(server_response.details);
    } else {
      setPayments("404");
    }
  };
  const setPay = (e) => {
    e.preventDefault();
    setPaymentsSearch(false);
    setEndDate("");
    setStartDate("");
  };

  const handleModal2 = () => {
    setModal(false, () =>
      setModal(<CreateTaskPayment f={getPayments} isOpen={true} />)
    );
  };
  const handleModal = () => {
    setModal(false, () =>
      setModal(<CreatePaymentDeposit f={getPayments} isOpen={true} />)
    );
  };

  useEffect(() => {
    getPayments();
    searchPayments();
  }, []);

  const calculateTotal = (column) => {
    let total = 0;
    if (Array.isArray(payments) && payments.length > 0) {
      payments.forEach((item) => {
        const keys = column.split(".");
        let value = item;
        keys.forEach((key) => {
          value = value?.[key];
        });
        total += parseFloat(value) || 0;
      });
    }
    return total;
  };

  const totalDebit = calculateTotal("amount_out.amount_out");
  const totalCredit = calculateTotal("amount.amount");

  return (
    <AppContainer title="Payments">
      <Toaster position="top-center" reverseOrder={false} />
      {modal}
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Payments List"
              viewButton={
                <>
                  <a
                    href="#"
                    onClick={handleModal}
                    className="btn btn-info btn-sm "
                    style={{ float: "right" }}>
                    Debit Employee
                  </a>
                  <a
                    href="#"
                    onClick={handleModal2}
                    className="btn btn-primary btn-sm mr-2 "
                    style={{ float: "right" }}>
                    Credit Employee
                  </a>
                  <a
                    href="#"
                    onClick={exportToPDF}
                    className="btn btn-secondary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to PDF
                  </a>
                  <a
                    href="#"
                    onClick={exportToExcel}
                    className="btn btn-primary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to Excel
                  </a>
                </>
              }
            />

            <div className="row">
              <div className="col-md-12 col-xl-8 mb-0">
                <div className="row">
                  <div className="col-md-12 col-xl-6 mb-0">
                    <div className="form-group">
                      {" "}
                      <label className="form-label">From:</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ height: "100%" }}>
                            <i className="fe fe-calendar"></i>{" "}
                          </div>
                        </div>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-6 mb-0">
                    <div className="form-group">
                      {" "}
                      <label className="form-label">To:</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ height: "100%" }}>
                            <i className="fe fe-calendar"></i>{" "}
                          </div>
                        </div>
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-2 mb-0">
                <div className="form-group mt-32" style={{ marginTop: "32px" }}>
                  {" "}
                  <button
                    // href="#"
                    onClick={
                      (e) =>
                        // e.preventDefault(); // Prevent the default anchor tag behavior
                        searchPayments(e) // Call your searchPayments function
                    }
                    className="btn bg-primary btn-block color-white"
                    style={{ color: "white", display: "block", width: "100%" }}>
                    Search Payments
                  </button>{" "}
                </div>
              </div>
              <div className="col-md-12 col-xl-2 mb-0">
                <div className="form-group mt-32" style={{ marginTop: "32px" }}>
                  {" "}
                  <a
                    href="#"
                    onClick={setPay}
                    className="btn bg-light btn-block color-white"
                    style={{ color: "black", display: "block", width: "50%" }}>
                    Reset
                  </a>{" "}
                </div>
              </div>
            </div>

            <div className="border-top mt-2"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">Payment Date</th>
                    <th scope="col"> Employee Name</th>
                    <th scope="col"> Debit</th>
                    <th scope="col">Credit</th>

                    <th scope="col">Deposited By</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentsSearch && Array.isArray(paymentsSearch) ? (
                    paymentsSearch.length > 0 ? (
                      paymentsSearch.map((item, key) => (
                        <tr key={key}>
                          <td>{item.created_at.long_date}</td>
                          <td>{item.employee}</td>
                          <td> {item.amount_out.amount_out_c} </td>
                          <td> {item.amount.amount_c} </td>
                          <td> {item.added_by} </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">
                          No payments found for the selected date range.
                        </td>
                      </tr>
                    )
                  ) : (
                    Array.isArray(payments) &&
                    payments.map((item, key) => (
                      <tr key={key}>
                        <td>{item.created_at.long_date}</td>
                        <td>{item.employee}</td>
                        <td> {item.amount_out.amount_out_c} </td>
                        <td> {item.amount.amount_c} </td>

                        <td> {item.added_by} </td>
                      </tr>
                    ))
                  )}
                  <tr>
                    <td colSpan="2">
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{totalDebit}</strong>
                    </td>
                    <td>
                      <strong>{totalCredit}</strong>
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              </table>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

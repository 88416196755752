import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import TableHeader from "../Components/Common/TableHeader";
import ajaxClient from "../util/remote/ajaxClient";
import useStateCallback from "../util/customHooks/useStateCallback";
import VisitLocation from "../Components/Client/VisitLocation";
import { Toaster, toast } from "react-hot-toast";

export default function VisitsPage() {
  const [clientVisits, setClientVisits] = useState(false);
  const [modal, setModal] = useStateCallback(false);
  const [visitsSearch, setVisitsSearch] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const searchVisits = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates.");
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error("Start date should be before end date.");
    } else {
      const server_response = await ajaxClient.searchVisits(startDate, endDate);
      if (server_response.status === "OK") {
        if (server_response.details.length === 0) {
          // set the state to an empty array to show an empty table
          setVisitsSearch([]);
        } else {
          setVisitsSearch(server_response.details);
        }
      } else {
        setVisitsSearch([]);
      }
    }
  };

  const getClientVisits = async () => {
    const server_response = await ajaxClient.fetchAllClientVisits();

    if (server_response.status === "OK") {
      setClientVisits(server_response.details);
    } else {
      setClientVisits("404");
    }
  };
  const setVisits = (e) => {
    e.preventDefault();
    setVisitsSearch(false);
    setEndDate("");
    setStartDate("");
  };

  useEffect(() => {
    getClientVisits();
    searchVisits();
  }, []);

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <VisitLocation
          isOpen={true}
          client={item.client.name}
          position={{ lat: item.lat * 1, lng: item.longitude * 1 }}
          position1={{
            lat: item.client.lat * 1,
            lng: item.client.longitude * 1,
          }}
        />
      )
    );
  };

  return (
    <AppContainer title="Visits">
      <Toaster position="top-center" reverseOrder={false} />
      {modal}
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader title="Visits List" />

            <div className="row">
              <div className="col-md-12 col-xl-8 mb-0">
                <div className="row">
                  <div className="col-md-12 col-xl-6 mb-0">
                    <div className="form-group">
                      {" "}
                      <label className="form-label">From:</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ height: "100%" }}>
                            <i className="fe fe-calendar"></i>{" "}
                          </div>
                        </div>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-6 mb-0">
                    <div className="form-group">
                      {" "}
                      <label className="form-label">To:</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ height: "100%" }}>
                            <i className="fe fe-calendar"></i>{" "}
                          </div>
                        </div>
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-2 mb-0">
                <div className="form-group mt-32" style={{ marginTop: "32px" }}>
                  {" "}
                  <a
                    href="#"
                    onClick={searchVisits}
                    className="btn bg-primary btn-block color-white"
                    style={{ color: "white", display: "block", width: "100%" }}>
                    Search Visits
                  </a>{" "}
                </div>
              </div>
              <div className="col-md-12 col-xl-2 mb-0">
                <div className="form-group mt-32" style={{ marginTop: "32px" }}>
                  {" "}
                  <a
                    href="#"
                    onClick={setVisits}
                    className="btn bg-light btn-block color-white"
                    style={{ color: "black", display: "block", width: "50%" }}>
                    Reset
                  </a>{" "}
                </div>
              </div>
            </div>

            <div className="border-top mt-2"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Client</th>
                    <th scope="col">Team</th>
                    <th scope="col">Project</th>
                    <th scope="col">Visit Location</th>
                    <th scope="col">Date Of Visit</th>
                    <th scope="col">Next Appointment</th>
                  </tr>
                </thead>
                <tbody>
                  {visitsSearch && Array.isArray(visitsSearch) ? (
                    visitsSearch.length > 0 ? (
                      visitsSearch.map((item, key) => (
                        <tr key={key}>
                          <th scope="row">
                            {" "}
                            <a href="#" className="question_content">
                              {" "}
                              {key + 1}
                            </a>
                          </th>
                          <td>{item.client.name}</td>
                          <td>{item.team.team_name}</td>
                          <td>{item.project.project_name}</td>
                          <td>
                            <button
                              className="btn ripple btn-primary btn-sm"
                              onClick={(e) => handleModal(e, item)}>
                              {" "}
                              <i className="fe fe-map-pin"> </i>View
                            </button>
                          </td>
                          <td>{item.created_at.short_date}</td>
                          <td>{item.revisit_date.date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">
                          No visits found for the selected date range.
                        </td>
                      </tr>
                    )
                  ) : (
                    Array.isArray(clientVisits) &&
                    clientVisits.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">
                          {" "}
                          <a href="#" className="question_content">
                            {" "}
                            {key + 1}
                          </a>
                        </th>
                        <td>{item.client.name}</td>
                        <td>{item.team.team_name}</td>
                        <td>{item.project.project_name}</td>
                        <td>
                          <button
                            className="btn ripple btn-primary btn-sm"
                            onClick={(e) => handleModal(e, item)}>
                            {" "}
                            <i className="fe fe-map-pin"> </i>View
                          </button>
                        </td>
                        <td>{item.created_at.short_date}</td>
                        <td>{item.revisit_date.date}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {!clientVisits && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

import React, { useState, useEffect, useContext } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Comment from "./Comment";
import UserContext from "../Context/UserContext";
import ajaxComment from "../util/remote/ajaxComment";
import { useParams } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import Chat from "../Components/Chat/Chat";
import { Toaster, toast } from "react-hot-toast";
import Loader from "../Components/Common/Loader";
import useStateCallback from "../util/customHooks/useStateCallback";
import AssignDeveloper from "../Components/IssueLog/AssignDeveloper";
import ajaxIssueLog from "../util/remote/ajaxIssueLog";
import UpdateStatus from "../Components/IssueLog/UpdateStatus";

export default function IssueLogChat() {
  const { id } = useParams();
  const [comments, setComments] = useState(false);
  // const [user,setUser] = useState("");

  // const [user,setUser] = useState("");
  const [userID, setUserID] = useState("");
  const { user, userId } = useContext(AuthContext);
  const { userList } = useContext(UserContext);
  const [data, setData] = useState("");
  const [v, setV] = useState("");
  const [loading, setLoading] = useState(false);
  const [logInfo, setLogInfo] = useState(false);
  useEffect(() => {
    getComments();
  }, [id]);

  useEffect(() => {
    getLogInfo(id);
  }, [id]);

  const [active, setActive] = useState(false);

  const getLogInfo = async (id) => {
    setLoading(true);
    const server_response = await ajaxIssueLog.fetchLogInfo(id);

    setLoading(false);
    if (server_response.status === "OK") {
      setLogInfo(server_response.details);
    } else {
      setLogInfo("404");
    }
  };

  const getUserList = (e) => {
    setData(e.target.value);
    if (e.target.value[e.target.value.length - 1] === "@") {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const setTag = (e) => {
    setData(data + e.target.value);
    focusTextArea();
  };

  const selected = (e, item) => {
    if (v === "") {
      setV(item.id);
    } else {
      setV(v + "," + item.id);
    }

    setData(data + item.first_name + " ");
    focusTextArea();
  };

  const focusTextArea = () => {
    let txt = document.getElementById("exampleFormControlTextarea1");
    txt.focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let txt = document.getElementById("exampleFormControlTextarea1");

    if (id > 0 && userId > 0 && data.length > 0) {
      const server_response = await ajaxComment.createComment(
        id,
        userId,
        data,
        v
      );
      if (server_response.status === "OK") {
        setData("");
      }
      getComments();
    }
  };

  const getComments = async () => {
    const server_response = await ajaxComment.listComments(id);
    if (server_response.status === "OK") {
      setComments(server_response.details);
    }
  };

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(<AssignDeveloper logID={id} isOpen={true} />)
    );
  };

  const handleModal2 = () => {
    setModal(false, () => setModal(<UpdateStatus logID={id} isOpen={true} />));
  };
  const toggleTabs = (e) => {
    const tab = e.target.getAttribute("href").slice(1);
    const content = document.getElementById(tab);
    if (e.target.classList.contains("active")) {
    } else {
      e.target.classList.add("active");
      content.classList.add("active");
      content.classList.add("show");
    }
  };

  return (
    <>
      <Toaster />
      <AppContainer title="Issue Log Comments">
        <div className="col-md-12">
          <div className="row">
            {modal}

            <div className="col-md-8">
              <div className="card custom-card">
                <div className="main-content-app pt-0">
                  <div
                    className="main-content-body main-content-body-chat"
                    style={{ msOverflowY: "auto" }}>
                    <div className="main-chat-header pt-3">
                      <div className="main-img-user online">
                        <img
                          alt="avatar"
                          src={
                            process.env.REACT_APP_DEVELOPMENTURL +
                            "img/avatar.png"
                          }
                        />
                      </div>
                      <div className="main-chat-msg-name">
                        <h6>{user.first_name + " " + user.last_name}</h6>
                        <span className="dot-label bg-success"></span>
                        <small className="mr-3">online</small>
                      </div>
                      <nav className="nav">
                        <a
                          className="nav-link"
                          href="#"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="fe fe-more-horizontal"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow">
                          <a className="dropdown-item" href="#">
                            <i className="fe fe-phone-call mr-1"></i> Phone Call
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fe fe-video mr-1"></i> Video Call
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fe fe-user-plus mr-1"></i> Add Contact
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fe fe-trash-2 mr-1"></i> Delete
                          </a>
                        </div>
                        <a
                          className="nav-link"
                          data-toggle="tooltip"
                          href="#"
                          title="Phone Call">
                          <i className="fe fe-phone-call"></i>
                        </a>
                        <a
                          className="nav-link"
                          data-toggle="tooltip"
                          href="#"
                          title="Video Call">
                          <i className="fe fe-video"></i>
                        </a>
                        <a
                          className="nav-link"
                          data-toggle="tooltip"
                          href="#"
                          title="Add Contact">
                          <i className="fe fe-user-plus"></i>
                        </a>
                        <a
                          className="nav-link"
                          data-toggle="tooltip"
                          href="#"
                          title="Delete">
                          <i className="fe fe-trash-2"></i>
                        </a>
                      </nav>
                    </div>
                    <div
                      className="main-chat-body"
                      id="ChatBody"
                      style={{ overflowY: "auto" }}>
                      <div
                        className="content-inner"
                        style={{ overflowY: "auto" }}>
                        <label className="main-chat-time">
                          <span>3 days ago</span>
                        </label>
                        {Array.isArray(comments) &&
                          comments.map((item, key) =>
                            item.comment_id.user_id === userId ? (
                              <div className="media flex-row-reverse">
                                <div className="main-img-user online">
                                  <img
                                    alt="avatar"
                                    src={
                                      process.env.REACT_APP_DEVELOPMENTURL +
                                      "img/avatar.png"
                                    }
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="main-msg-wrapper">
                                    {item.comment_id.comment}
                                  </div>
                                  <div>
                                    <span>
                                      {item.comment_id.created_at.when}
                                    </span>{" "}
                                    <a href="#">
                                      <i className="icon ion-android-more-horizontal"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="media">
                                <div className="main-img-user online">
                                  <img
                                    alt="avatar"
                                    src={
                                      process.env.REACT_APP_DEVELOPMENTURL +
                                      "img/avatar.png"
                                    }
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="main-msg-wrapper">
                                    {item.comment_id.comment}
                                  </div>
                                  <div>
                                    <span>
                                      {item.comment_id.created_at.when}
                                    </span>{" "}
                                    <a href="#">
                                      <i className="icon ion-android-more-horizontal"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="main-chat-footer">
                        <nav className="nav">
                          <a
                            className="nav-link"
                            data-toggle="tooltip"
                            href="#"
                            title="Add Photo">
                            <i className="fe fe-image"></i>
                          </a>
                          <a
                            className="nav-link"
                            data-toggle="tooltip"
                            href="#"
                            title="Attach a File">
                            <i className="fe fe-paperclip"></i>
                          </a>
                          <a
                            className="nav-link"
                            data-toggle="tooltip"
                            href="#"
                            title="Emoji">
                            <i className="far fa-smile"></i>
                          </a>
                          <a
                            className="nav-link"
                            data-toggle="tooltip"
                            href="#"
                            title="Record Voice">
                            <i className="fe fe-mic"></i>
                          </a>
                        </nav>

                        <input
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          value={data}
                          onChange={getUserList}
                          placeholder="Type your message here..."
                          type="text"
                        />
                        <button
                          type="submit"
                          className="main-msg-send btn"
                          href="#">
                          <i className="far fa-paper-plane"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <h6 className="card-title mb-4 d-flex justify-content-end">
                <button
                  onClick={handleModal}
                  className="btn ripple btn-primary btn-with-icon btn-sm">
                  <i className="fe fe-user"></i> Assign Developer
                </button>
              </h6>
              {logInfo && (
                <h6 className="card-title mb-4 d-flex justify-content-end">
                  {logInfo.status === "Not Handled" ? (
                    <button
                      onClick={handleModal2}
                      className="btn ripple btn-success btn-with-icon btn-sm">
                      <i className="fe fe-check"></i> Update Status
                    </button>
                  ) : (
                    ""
                  )}
                </h6>
              )}
              {active ? (
                <ul className="list-group hover">
                  {Array.isArray(userList) &&
                    userList.map((item, key) => (
                      <li className="list-group-item" key={key}>
                        <button
                          onClick={(e) => selected(e, item)}
                          className="btn w-100 btn-light">
                          {item.first_name}
                        </button>
                      </li>
                    ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
}

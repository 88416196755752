import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import ajaxRegion from "../../util/remote/ajaxRegion";

export default function RegionsCard() {
  const [regionsNumber, setRegionsNumber] = useState(false);

  const getRegionsNumber = async () => {
    const server_response = await ajaxRegion.fetchRegionNumber();

    if (server_response.status === "OK") {
      setRegionsNumber(server_response.details);
    } else {
      setRegionsNumber("404");
    }
  };

  useEffect(() => {
    getRegionsNumber();
  }, []);

  return (
    <Card
      name="Total Regions"
      color="dark"
      icon="fe fe-map-pin"
      value={regionsNumber ? regionsNumber.total_p : "..."}
    />
  );
}

import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Link, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Loader from "../Components/Common/Loader";
import ajaxTask from "../util/remote/ajaxTask";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import useStateCallback from "../util/customHooks/useStateCallback";
import EndTask from "./Tasks/EndTask";
import UpdateTask from "../Components/Team/UpdateTask";

export default function ProjectTasks(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectTasks, setProjectTasks] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  const getProjectTasks = async () => {
    setLoading(true);
    const server_response = await ajaxTask.fetchTasks(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setProjectTasks(server_response.details);
    } else {
      setProjectTasks("404");
    }
  };

  const getCompletedTasks = async () => {
    setLoading(true);
    const server_response = await ajaxTask.fetchCompletedTasks(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setCompletedTasks(server_response.details);
    } else {
      setCompletedTasks("404");
    }
  };

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateTask
          taskID={item.id}
          f={getProjectTasks}
          projectID={id}
          isOpen={true}
          taskName={item.task_name}
          dailyExpectation={item.daily_expectation}
          duration={item.duration}
          total={item.total}
          unit_measure={item.unit_measure}
        />
      )
    );
  };

  const handleModal2 = (e, item) => {
    setModal(false, () =>
      setModal(
        <EndTask
          task_id={item.id}
          project_id={id}
          f={getProjectTasks}
          g={getCompletedTasks}
          isOpen={true}
        />
      )
    );
  };

  useEffect(() => {
    getProjectTasks(id);
    getCompletedTasks(id);
  }, [id]);

  return (
    <AppContainer title="Task Board">
      <Toaster position="top-center" reverseOrder={false} />
      {loading && <Loader />}

      {modal}

      <div className="col-lg-12 mb-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row mb-4">
                <Nav.Item>
                  <Nav.Link size="sm" eventKey="first">
                    Active Tasks{" "}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link size="sm" eventKey="second">
                    Completed Tasks{" "}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        <div className="mb-4">
                          <h6 className="card-title mb-4">Active Tasks</h6>
                        </div>

                        <div className="table-responsive">
                          <div className="border-top mt-3"></div>

                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Task</th>
                                <th>Duration</th>
                                <th>Daily Expectation</th>
                                <th> Unit OF Measure</th>
                                <th>Daily Expectation</th>
                                <th>Actions</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(projectTasks) &&
                                projectTasks.map((item, key) => (
                                  <tr key={key}>
                                    <td>{item.task_name}</td>
                                    <td>{item.duration}</td>
                                    <td>{item.daily_expectation}</td>
                                    <td>{item.unit_measure}</td>
                                    <td>{item.total}</td>
                                    <td>
                                      <Link
                                        className="btn btn-sm btn-info mr-2"
                                        href="#"
                                        onClick={(e) => handleModal2(e, item)}>
                                        End Task
                                      </Link>
                                    </td>
                                    <td>
                                      <i
                                        style={{ color: "gray" }}
                                        onClick={(e) => handleModal(e, item)}
                                        className="fe fe-edit-2"></i>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <div className="col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        <div className="mb-4">
                          <h6 className="card-title mb-4">Completed Tasks</h6>
                        </div>

                        <div className="table-responsive">
                          <div className="border-top mt-3"></div>

                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Task</th>
                                <th>Duration</th>
                                <th>Daily Expectation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(completedTasks) &&
                                completedTasks.map((item, key) => (
                                  <tr key={key}>
                                    <td>{item.task_name}</td>
                                    <td>{item.duration}</td>
                                    <td>{item.daily_expectation}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </AppContainer>
  );
}


import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async getDeliverableList(id) {
        let data={
            "project_id":id
          }
        let response = await apiCall("deliverable/list", data)
        return response;
       
      },
      async getPendingDeliverableList(id) {
        let data={
            "project_id":id
          }
        let response = await apiCall("deliverable/list/pending", data)
        return response;
       
      },
      async getSubDeliverableList(deliverable_id) {
        let data={
            "deliverable_id":deliverable_id
          }
        let response = await apiCall("sub_deliverable/list", data)
        return response;
       
      },
      async createDeliverable(project_id,name,user_id,duration)
      {
          let data={
            "project_id":project_id,
            "name":name,
            "user_id":user_id,
            "duration":duration
          }
          let response = await apiCall("deliverable/create", data)
          return response;
      },
      async createSubDeliverable(deliverable_id,name,user_id,duration)
      {
          let data={
            "deliverable_id":deliverable_id,
            "name":name,
            "user_id":user_id,
            "duration":duration
          }
          let response = await apiCall("sub_deliverable/create", data)
          return response;
      },
      async getSingleDeliverable(id) {
        let data={
            "deliverable_id":id
          }
        let response = await apiCall("deliverable/list/single", data)
        return response;
       
      },
      async updateDeliverable(id,name,user_id,duration,status) {
        let data={
            "deliverable_id":id,
            "name":name,
            "user_id":user_id,
            "duration":duration,
            "status":status
          }
        let response = await apiCall("deliverable/update", data)
        return response;
       
      },
      async updateDeliverableStatus(id,status) {
        let data={
            "deliverable_id":id,
            "status":status
          }
        let response = await apiCall("deliverable/update/status", data)
        return response;
       
      },
      async updateSubDeliverableStatus(id,status) {
        let data={
            "sub_deliverable_id":id,
            "status":status
          }
        let response = await apiCall("sub_deliverable/update/status", data)
        return response;
       
      },
      async updateSubDeliverable(sub_deliverable_id,name,user_id,duration,status) {
        let data={
            "sub_deliverable_id":sub_deliverable_id,
            "name":name,
            "user_id":user_id,
            "duration":duration,
            "status":status
          }
        let response = await apiCall("sub_deliverable/update", data)
        return response;
       
      },

      async fetchDeliverableProgress(deliverable_id)
      {
          let data={
              "deliverable_id":deliverable_id
          }
          let response = await apiCall("sub_deliverable/count/completed", data)
  
          return response;
  
      },

      async fetchDeliverableCount(deliverable_id)
      {
          let data={
              "deliverable_id":deliverable_id
          }
          let response = await apiCall("sub_deliverable/count", data)
  
          return response;
  
      },
      

      async countInProgressDeliverable(project_id)
      {
          let data={
              "project_id":project_id
          }
          let response = await apiCall("deliverable/count/in/progress", data)
  
          return response;
  
      },

      async countPendingDeliverable(project_id)
      {
          let data={
              "project_id":project_id
          }
          let response = await apiCall("deliverable/count/pending", data)
  
          return response;
  
      },

      async countCompletedDeliverable(project_id)
      {
          let data={
              "project_id":project_id
          }
          let response = await apiCall("deliverable/count/completed", data)
  
          return response;
  
      }


      
}
import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
  async createMeeting(meeting_title,minutes,type,date_held,additional_file){
    let data = {
      "meeting_title":meeting_title,
      "minutes":minutes,
      "type":type,
      "date_held":date_held,
      "additional_file":additional_file
    }
   
    let response = await apiCall("meeting/create", data)
    return response;
  
  },
      
      async fetchMeetingRecords(data)
      {
          
          let response = await apiCall("meeting/list", data)
  
          return response;
  
      }




}
import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { Link } from "react-router-dom";
import { RenderSecure } from "../../util/script/RenderSecure";
import Loader from "../../Components/Common/Loader";
import TableHeader from "../../Components/Common/TableHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useStateCallback from "../../util/customHooks/useStateCallback";
import UpdateProduct from "./UpdateProduct";

function ListProducts() {
  const [productList, setProductList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProduct.fetchProductList();
    setLoading(false);

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateProduct
          productID={item.id}
          f={getProducts}
          isOpen={true}
          productName={item.product_name}
          singular={""}
          plural={""}
          productType={item.product_type}
          unitCost={item.unit_cost.unit_cost}
        />
      )
    );
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Products List"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = [
      "No.",
      "Product Name",
      "Singular",
      "Plural",
      "Product Type",
      "Unit Cost",
      "Quantity In",
      "Quantity Out",
      "Closing Balance",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    // data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`products_data_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <AppContainer title="Products">
      <div className="card custom-card">
        <div className="card-body">
          {loading && <Loader />}
          {modal}

          <TableHeader
            title="Products"
            subtitle="List of all the products"
            viewButton={
              <>
                <a
                  href="#"
                  onClick={exportToPDF}
                  className="btn btn-secondary btn-sm mr-2"
                  style={{ float: "right" }}>
                  Export to PDF
                </a>
                <RenderSecure code="ADD-PRODUCTS">
                  <Link
                    style={{ float: "right" }}
                    className="btn btn-success mb-4 mr-2 btn-sm"
                    to="/products/create">
                    Add Product
                  </Link>{" "}
                </RenderSecure>
              </>
            }
          />

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <th>#</th>
                <th>Product Name</th>
                <th>unit of Measure</th>
                <th>Product Type</th>
                <th>Unit Cost</th>
                <th>Quantity In</th>
                <th>Quantity Out</th>
                <th>Closing Balance</th>
                <th>Edit</th>
              </thead>
              <tbody>
                {!Array.isArray(productList) && <tr>No products found</tr>}
                {Array.isArray(productList) &&
                  productList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.product_name}</td>
                      <td>{item.unit_measure}</td>
                      <td>{item.product_type}</td>
                      <td>{item.unit_cost.unit_cost_c}</td>
                      <td>{item.qty_in.qty_in_c}</td>
                      <td>{item.qty_out.qty_out_c}</td>
                      <td>{item.balance.balance_c}</td>
                      <td>
                        <i
                          style={{ color: "gray" }}
                          onClick={(e) => handleModal(e, item)}
                          className="fe fe-edit-2"></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ListProducts;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import AppContainer from "../../Components/Structure/AppContainer";
import useStateCallback from "../../util/customHooks/useStateCallback";
import TableHeader from "../../Components/Common/TableHeader";
import AddJoint from "../../Components/ProjectTracker/AddJoint";
import UpdateJoint from "../../Components/ProjectTracker/UpdateJoint";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

function ListJointsProducts() {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState(false);
  const { batch, id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(
        <AddJoint id={id} batch={batch} f={listProducts} isOpen={true} />
      )
    );
  };

  const handleModal2 = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateJoint
          id={id}
          joint_id={item.id}
          batch={batch}
          f={listProducts}
          isOpen={true}
          wkt_geom={item.wkt_geom}
          city={item.city}
          pole_location={item.pole_location}
          manhole_location={item.manhole_location}
          fid={item.fid}
          name={item.name}
          template={item.template}
          nb_splice_est={item.nb_splice_est}
          nb_splice_est_12={item.nb_splice_est_12}
          nb_splice_est_48={item.nb_splice_est_48}
          trunk={item.trunk}
          cores={item.cores}
          joint_droit={item.joint_droit}
          nb_splice_joint={item.nb_splice_joint}
          cable_entree={item.cable_entree}
        />
      )
    );
  };

  useEffect(() => {
    SearchJoints();
  }, [searchQuery, batch]);

  const SearchJoints = async () => {
    setLoading(true);
    const server_response = await ajaxProject.searchJoints(searchQuery);
    setLoading(false);
    if (searchQuery.length > 0) {
      if (server_response.status === "OK") {
        setProductList(server_response.details);
      } else {
        setProductList(server_response.details);
      }
    } else {
      setLoading(true);
      const server_response = await ajaxProject.fetchJointBatchProducts(batch);
      setLoading(false);

      if (server_response.status === "OK") {
        setProductList(server_response.details);
      }
    }
  };

  const listProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProject.fetchJointBatchProducts(batch);
    setLoading(false);

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    if (Array.isArray(productList)) {
      const data = productList.map((item, index) => ({
        wkt_geom: item.wkt_geom,
        fid: item.fid,
        city: item.city,
        name: item.name,
        pole_location: item.pole_location,
        manhole_location: item.manhole_location,
        template: item.template,
        trunk: item.trunk,
        cores: item.cores,
        nb_splice_est: item.nb_splice_est,
        nb_splice_est_12: item.nb_splice_est_12,
        nb_splice_est_48: item.nb_splice_est_48,
        joint_droit: item.joint_droit,
        nb_splice_joint: item.nb_splice_joint,
        cable_entree: item.cable_entree,
        Status: item.status,
        install_date: item.install_date,
      }));
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Joints");

      // Generate a file name
      const fileName = "joints_data.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  return (
    <AppContainer title="Batch Joints List">
      <>
        <Toaster position="top-center" reverseOrder={false} />
        {modal}
        <Link
          to={`/project/joints/${id}`}
          className="btn btn-primary btn-sm mb-2">
          Back To Joints
        </Link>
        <div className="mt-4 mb-4">
          <div class="input-group">
            <input
              class="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for..."
              type="text"
            />
            <span class="input-group-btn">
              <button
                class="btn ripple btn-primary"
                style={{
                  height: 26,
                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
                type="button">
                <span class="input-group-btn">
                  <i class="fa fa-search"></i>
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="card custom-card">
          <div className="card-body map-card">
            <button
              style={{ float: "right" }}
              onClick={exportToExcel}
              className="btn btn-sm btn-secondary mb-3 ml-3 mr-3">
              Export to Excel
            </button>
            <TableHeader
              title="Upload Batch Products"
              viewButton={
                <a
                  href="#"
                  onClick={handleModal}
                  className="btn btn-primary btn-sm"
                  style={{ float: "right" }}>
                  Add Joint
                </a>
              }
            />

            <div className="table-responsive">
              <div className="border-top mt-3"></div>
              {loading && <Loader />}
              {!loading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>wkt_geom</th>
                      <th>fid</th>
                      <th>city</th>
                      <th>name</th>
                      <th>pole_location</th>
                      <th>manhole_location</th>
                      <th>template</th>
                      <th>trunk</th>
                      <th>cores</th>
                      <th>nb_splice_est</th>
                      <th>nb_splice_est_12</th>
                      <th>nb_splice_est_48</th>
                      <th>joint_droit</th>
                      <th>nb_splice_joint</th>
                      <th>cable_entree</th>
                      <th>status</th>
                      <th>install_date</th>
                      <th>edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!Array.isArray(productList) && <tr>No joints found</tr>}
                    {Array.isArray(productList) &&
                      productList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.wkt_geom}</td>
                          <td>{item.fid}</td>
                          <td>{item.city}</td>
                          <td>{item.name}</td>
                          <td>{item.pole_location}</td>
                          <td>{item.manhole_location}</td>
                          <td>{item.template}</td>
                          <td>{item.trunk}</td>
                          <td>{item.cores}</td>
                          <td>{item.nb_splice_est}</td>
                          <td>{item.nb_splice_est_12}</td>
                          <td>{item.nb_splice_est_48}</td>
                          <td>{item.joint_droit}</td>
                          <td>{item.nb_splice_joint}</td>
                          <td>{item.cable_entree}</td>
                          <td>{item.status}</td>
                          <td>{item.install_date}</td>
                          <td>
                            <a
                              onClick={(e) => handleModal2(e, item)}
                              className="btn btn-primary btn-sm">
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    </AppContainer>
  );
}

export default ListJointsProducts;

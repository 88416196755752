import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import Select from "react-select";
import ajaxTask from "../../util/remote/ajaxTask";

const CreateTaskAllocation = (props) => {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState("");
  const [site, setSite] = useState("");
  const [task_date, setTaskDate] = useState("");
  const [achieved_work, setAchievedWork] = useState("");
  const [taskList, setTaskList] = useState(false);

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks("");

    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (props.team_id > 0 && task > 0) {
      setLoading(true);
      const server_response = await ajaxTask.createTaskAllocation(
        task,
        props.team_id,
        site,
        task_date,
        achieved_work
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setTask("");
        setSite("");
        setTaskDate("");
        toast.success(server_response.message);
        props.f(props.team_id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Allocation
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Task Allocation"
      id="model-new-taskallocation"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select Task</label>
        <Select
          onChange={(e) => setTask(e.id)}
          getOptionLabel={(option) =>
            `${option.task_name} (${option.project.project_name})`
          }
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(taskList) ? taskList : []}
          value={
            Array.isArray(taskList) &&
            taskList.find((value) => value.id === task)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Site</label>
        <input
          type="text"
          value={site}
          onChange={(e) => setSite(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Task Date</label>
        <input
          type="date"
          value={task_date}
          onChange={(e) => setTaskDate(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default CreateTaskAllocation;

import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { RenderSecure } from "../util/script/RenderSecure";
import { useParams } from "react-router-dom";
import ajaxDeliverable from "../util/remote/ajaxDeliverable";
import CreateSubDeliverable from "../Components/ProjectProfile/CreateSubDeliverable";
import { Toaster, toast } from "react-hot-toast";
import TableHeader from "../Components/Common/TableHeader";
import useStateCallback from "../util/customHooks/useStateCallback";
import Loader from "../Components/Common/Loader";

const DeliverableProfile = (props) => {
  const { id } = useParams();
  const [subDeliverablesList, setSubDeliverablesList] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  const [status, setStatus] = useState("");
  const [deliverableID, setDeliverableID] = useState("");

  const [isHovering, setIsHovering] = useState(false);

  const [active1, setActive1] = useState(false);
  const handleActive1 = () => setActive1(true);
  const handleInActive1 = () => setActive1(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const setDeliverableUpdate = (e, item) => {
    e.preventDefault();
    handleActive1();
    setStatus(item.status);
    setDeliverableID(item.id);
  };

  const handleUpdateDeliverableStatus = async (e) => {
    e.preventDefault();
    const server_response = await ajaxDeliverable.updateSubDeliverableStatus(
      deliverableID,
      status
    );

    if (server_response.status === "OK") {
      setStatus("");
      getSubDeliverables(id);
      toast.success(server_response.message);
    } else {
      toast.error(server_response.message);
    }
  };
  const getSubDeliverables = async (id) => {
    const server_response = await ajaxDeliverable.getSubDeliverableList(id);

    if (server_response.status === "OK") {
      setSubDeliverablesList(server_response.details);
    } else {
      setSubDeliverablesList("404");
    }
  };

  const handleModal = () => {
    setModal(false, () =>
      setModal(
        <CreateSubDeliverable
          deliverable_id={id}
          f={getSubDeliverables}
          isOpen={true}
        />
      )
    );
  };

  useEffect(() => {
    getSubDeliverables(id);
  }, [id]);

  return (
    <AppContainer title={"Sub-Deliverables"}>
      <Toaster position="top-center" reverseOrder={false} />

      {modal}
      <div className="col-12 col-xl-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Sub Deliverables"
              subtitle="List of the subdeliverables"
              viewButton={
                <a
                  href="#"
                  onClick={handleModal}
                  className="btn btn-primary btn-sm"
                  style={{ float: "right" }}>
                  <i className="fe fe-plus mr-1"></i>Add Sub Deliverable
                </a>
              }
            />

            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Sub Deliverable</th>
                    <th scope="col">Assigned Developer</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(subDeliverablesList) &&
                    subDeliverablesList.map((item, key) => (
                      <tr
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        key={key}>
                        <th scope="row">{key + 1}</th>
                        <td> {item.name}</td>
                        <td> {item.user.first_name}</td>
                        <td> {item.duration} hours</td>
                        <td>
                          {active1 && deliverableID === item.id ? (
                            <form
                              onSubmit={(e) => handleUpdateDeliverableStatus(e)}
                              method="post">
                              <div className="row">
                                <div className="mb-1 col-6">
                                  <select
                                    onChange={(e) => setStatus(e.target.value)}
                                    name=""
                                    id=""
                                    className="form-control">
                                    <option defaultValue={status}>
                                      {item.status}
                                    </option>
                                    <option value="Pending">Pending</option>
                                    <option value="In Progress">
                                      In Progress
                                    </option>
                                    <option value="Completed">Completed</option>
                                  </select>
                                </div>
                                <div className="mb-4 col-3">
                                  <button className="btn ripple btn-primary btn-sm">
                                    <i className="fe fe-check"></i>Update
                                  </button>
                                </div>
                              </div>
                            </form>
                          ) : item.status === "Pending" ? (
                            <span className="badge badge-danger">
                              {item.status}
                            </span>
                          ) : item.status === "In Progress" ? (
                            <span className="badge badge-warning">
                              {item.status}
                            </span>
                          ) : (
                            <span className="badge badge-success">
                              {item.status}
                            </span>
                          )}

                          {active1 && deliverableID === item.id ? (
                            <button
                              className="btn ripple btn-danger btn-sm"
                              onClick={(e) => handleInActive1()}>
                              <i className="fe fe-x">Cancel</i>
                            </button>
                          ) : (
                            isHovering && (
                              <i
                                style={{ color: "gray" }}
                                onClick={(e) => setDeliverableUpdate(e, item)}
                                className="fe fe-edit-2 ml-2"></i>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!subDeliverablesList && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default DeliverableProfile;

import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Select from "react-select";
import ajaxUser from "../util/remote/ajaxUser";
import { Toaster, toast } from "react-hot-toast";
import RoleContext from "../Context/RoleContext";
import TeamContext from "../Context/TeamContext";
import ChangePassword from "../Components/Users/ChangePassword";
import useStateCallback from "../util/customHooks/useStateCallback";
import AuthContext from "../Context/AuthContext";
import Loader from "../Components/Common/Loader";
import TableHeader from "../Components/Common/TableHeader";
import { Link } from "react-router-dom";
import CreateUserAllocation from "../Components/Users/CreateUserAllocation";

export default function ProfileUser() {
  const { userId, user, getUserInfo } = useContext(AuthContext);
  const { roleList } = useContext(RoleContext);
  const { teamList } = useContext(TeamContext);
  const [modal, setModal] = useStateCallback(false);
  // const [userTeams, setUserTeams] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [district, setDistrict] = useState("");
  const [NIN, setNIN] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [contact, setContact] = useState("");
  const [user_id, setUserId] = useState("");

  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const setUserUpdate = () => {
    handleActive();
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setUsername(user.username);
    setDistrict(user.district);
    setEmail(user.email);
    setNIN(user.nin);
    setRole(user.role.role_id);
    setUserId(user.id);
    setContact(user.contact);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const server_response = await ajaxUser.updateUser(
      userId,
      first_name,
      last_name,
      username,
      email,
      NIN,
      district,
      role,
      contact
    );
    if (server_response.status === "OK") {
      toast.success(server_response.message);
      getUserInfo(userId);
    }
  };

  //   const getUserTeams =async()=>{
  //     const server_response = await ajaxUser.fetchUserTeams(userId);
  //     if(server_response.status==="OK"){
  //
  //        setUserTeams(server_response.details);
  //     }else{
  //
  //         setUserTeams("404");
  //      }
  // }

  const handleModal = () => {
    setModal(false, () =>
      setModal(<ChangePassword userID={userId} isOpen={true} />)
    );
  };

  //   const handleModal2=()=>{
  //     setModal(false, ()=>setModal(<CreateUserAllocation user_id={userId} f={getUserTeams} isOpen={true}/>))
  // }

  //   useEffect(()=>{
  //     getUserTeams(userId);
  // }, [userId])

  return (
    <AppContainer title={"My Profile"}>
      <Toaster position="top-center" reverseOrder={false} />
      {modal}

      <div className="col-12 col-xl-12">
        <div
          className="box user-pro-list overflow-hidden mb-30"
          style={{
            marginBottom: "30px",
            backgroundColor: "white",
            padding: "25px",
            boxShadow: "10px",
            borderRadius: "10px",
          }}>
          <div className="box-body" style={{ position: "relative" }}>
            <div className="user-pic text-center">
              <div
                className="main-avatar"
                style={{
                  margin: "0 auto",
                  fontSize: "50px",
                  height: "112px",
                  width: "112px",
                  backgroundColor: "#8760fb",
                  borderRadius: "50%",
                }}>
                {user.short_name}
              </div>
              <div
                className="pro-user mt-3"
                style={{ marginTop: "1rem !important" }}>
                <h5
                  className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span"
                  style={{ lineHeight: "1.5" }}>
                  {user.first_name} {user.last_name}
                </h5>
                <h6 className="pro-user-desc text-muted fs-14">
                  {user.role ? user.role.role_name : ""}
                </h6>
              </div>
            </div>
          </div>

          <div
            className="box-footer pt-41"
            style={{ paddingTop: "41px !important" }}>
            <div className="btn-list text-center">
              {active ? (
                <a
                  href="#"
                  onClick={handleInActive}
                  className="btn ripple btn-danger btn-sm mr-2">
                  <i className="fe fe-x"></i>Back
                </a>
              ) : (
                <a
                  href="#"
                  onClick={setUserUpdate}
                  className="btn ripple btn-warning btn-sm mr-2">
                  <i className="far fa-edit mr-1"></i>Update Details
                </a>
              )}
              <a
                href="#"
                onClick={handleModal}
                className="btn ripple btn-primary btn-sm">
                <i className="fas fa-user-lock mr-1"></i>Change Password
              </a>
            </div>
          </div>
        </div>

        {active ? (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">
                {" "}
                Update User Information
              </div>
            </div>
            <br />
            <div className="box-body pt-20 user-profile">
              <form onSubmit={handleUpdate}>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        First Name:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Last Name:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Role:<span className="tx-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => setRole(e.role_id)}
                        getOptionLabel={(option) => option.role_name}
                        getOptionValue={(option) => option.role_id}
                        isSearchable
                        options={roleList}
                        defaultValue={
                          Array.isArray(roleList) &&
                          roleList.find((value) => value.role_id === role)
                        }
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Username:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        E-mail:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        NIN:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={NIN}
                        onChange={(e) => setNIN(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        District:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Contact:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={contact}
                        onChange={(e) => setContact(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <button className="btn ripple btn-main-primary btn-block">
                  Update User Profile
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">User Information</div>
            </div>
            <div className="box-body pt-20 user-profile">
              <div className="table-responsive">
                <table className="table mb-0 mw-100 color-span">
                  <tbody>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">First Name </span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">{user.first_name}</span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">Last Name</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">{user.last_name}</span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">E-mail Address</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">{user.email}</span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">Role</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">
                          {user.role ? user.role.role_name : ""}
                        </span>{" "}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="py-2 px-0"> <span className="w-50">Team</span> </td>
                      <td>:</td>
                      <td className="py-2 px-0"> <span className="">{user.team?user.team.team_name:"Not Assigned"}</span> </td>
                    </tr> */}
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">NIN</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">{user.nin}</span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">District</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">{user.district}</span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="w-50">Contact</span>{" "}
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {" "}
                        <span className="">+{user.contact}</span>{" "}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="py-2 px-0"> <span className="w-50">Clients Registered</span> </td>
                      <td>:</td>
                      <td className="py-2 px-0"> <span className="">{user.clients_registered?user.clients_registered.total:0}</span> </td>
                    </tr> */}
                  </tbody>
                </table>
                {!user && <Loader />}
              </div>
            </div>
          </div>
        )}

        {/* <div className="card custom-card" style={{borderRadius: "10px"}}>
                        <div className="card-body map-card">
                            <TableHeader
                                title="My Teams"
                                subtitle="List of all the assigned teams" 
                                viewButton={<a href="#" onClick={handleModal2} className="btn btn-primary btn-sm" style={{float:"right"}}>Assign Team</a>}
                                
                            />
                            <div className="border-top mt-3"></div>                    
                            <div className="table-responsive">
                                    <table className="table table-hover text-nowrap mg-b-0">
                                        <thead>
                                            <tr>
                                            <th scope="col">No.</th>
                                             
                                                <th scope="col"> Team Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(userTeams) && userTeams.length > 0 ? (
                                                userTeams.map((item, key) => (
                                            
                                              <tr key={key} >
                                                    <th scope="row">{key+1}</th>
                                                    <td> <span><Link to={`../teams/team-profile/${item.team.team_id}`} > {item.team.team_name}</Link></span></td>
                                               
                                                </tr>
                                            ))
                                        ): (
                                            <tr>
                                                <td colSpan="5" style={{textAlign:"center"}}>No teams assigned to me yet.</td>
                                            </tr>
                                    )}
                                        </tbody>
                                    </table>
                                    {!userTeams && <Loader/>}
                            </div>
                              
                        </div>
			        </div> */}
      </div>
    </AppContainer>
  );
}

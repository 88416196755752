import React, { useEffect, useState, useContext } from "react";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
//new
import ajaxTeam from "../../util/remote/ajaxTeam";
import Select from "react-select";
import UserContext from "../../Context/UserContext";
import ajaxProduct from "../../util/remote/ajaxProduct";

function OTBs(props) {
  const [status, setStatus] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [crossa, setCross] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pole, setPole] = useState(false);
  const [labeling_image, setLabelingImage] = useState("");
  const [splicing_image, setSplicingImage] = useState("");
  const [closed_image, setClosedImage] = useState("");
  const [tree_image, setTreeImage] = useState("");
  /////new
  const [teamList, setTeamList] = useState(false);
  const [teamId, setTeamId] = useState("");
  const { userList } = useContext(UserContext);
  const [user, setUser] = useState("");

  const { id, cross } = useParams();
  const [product, setProduct] = useState("");
  const [productList, setProductList] = useState(false);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const handleCrossDetails = async (e) => {
    e.preventDefault();
    if (id > 0 && status.length > 0) {
      if (status === "INSTALLED" && installationDate.length < 1) {
        toast.error("Select the installation date to proceed!");
      } else {
        setLoading(true);
        const server_response = await ajaxProject.updateOtbStatus(
          cross,
          status,
          installationDate,
          teamId,
          user,
          labeling_image,
          splicing_image,
          closed_image,
          tree_image,
          product
        );
        setLoading(false);

        if (server_response.status === "OK") {
          setStatus("");
          setInstallationDate("");
          toast.success(server_response.message);
          props.navi(`/installations/create/${id}`);
        }
      }
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  const changeLabelingPicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setLabelingImage(e.target.result);
      // console.log(image)
    };
  };

  const changeSplicingPicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setSplicingImage(e.target.result);
      // console.log(image)
    };
  };

  const changeClosedPicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setClosedImage(e.target.result);
      // console.log(image)
    };
  };
  const changeTreePicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setTreeImage(e.target.result);
      // console.log(image)
    };
  };

  ///new
  useEffect(() => {
    getTeams();
    getProducts();
  }, []);

  const getTeams = async () => {
    const server_response = await ajaxTeam.getTeamList();

    if (server_response.status === "OK") {
      setTeamList(server_response.details);
    }
  };

  useEffect(() => {
    getPole();
  }, [id]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);
    // console.log(server_response.details);
    if (server_response.status === "OK") {
      setPole(server_response.details);
    } else {
      setPole(false);
    }
  };

  useEffect(() => {
    getPoleOtbs();
  }, [pole]);

  const getPoleOtbs = async () => {
    if (pole) {
      setLoading(true);
      const server_response = await ajaxProject.getPoleOtbs(pole.pole_id);
      setLoading(false);

      if (server_response.status === "OK") {
        let crossa = server_response.details.filter(function (crossing) {
          return crossing.id == cross;
        });
        crossa = crossa[0];
        setCross(crossa);
        setStatus(crossa.status);
        setInstallationDate(crossa.install_date);
      } else {
        setCross(false);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleCrossDetails(e)}>
      <Toaster />
      {loading && <Loader />}
      {crossa && (
        <>
          <br />
          <Link
            onClick={() => props.navi(-1)}
            className="btn btn-primary btn-sm mb-2">
            Back To Previous Page
          </Link>
          <h6>
            <small className="text-muted">Otb name : </small>
            {crossa.name}
          </h6>
          <h6>
            <small className="text-muted">Otb template : </small>
            {crossa.template}
          </h6>
          <br />
          <div className="form-group mt-3">
            <div className="mb-4 col-md-12">
              <label>
                <b>Otb Status</b>
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
                className="form-control">
                <option value="">--- select status ---</option>
                <option value="NOT INSTALLED">NOT INSTALLED</option>
                <option value="INSTALLED">INSTALLED</option>
              </select>
            </div>
            <div className="mb-4 col-md-12">
              <label htmlFor="">Product Type</label>
              <Select
                onChange={(e) => setProduct(e.id)}
                getOptionLabel={(option) => option.product_name}
                getOptionValue={(option) => option.id}
                isSearchable
                options={Array.isArray(productList) ? productList : []}
                value={
                  Array.isArray(productList) &&
                  productList.find((value) => value.id === product)
                }
              />
            </div>

            <div className="mb-4 col-md-12">
              <label>
                <b>Install Date</b>
              </label>
              <input
                type="date"
                value={installationDate}
                onChange={(e) => setInstallationDate(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          {/* ////new */}
          <div className="mb-4 col-md-12">
            <label htmlFor="">
              <b>Installed By:</b>
            </label>
            <Select
              onChange={(e) => setTeamId(e.team_id)}
              getOptionLabel={(option) => option.team_name}
              getOptionValue={(option) => option.team_id}
              isSearchable
              options={Array.isArray(teamList) ? teamList : []}
              value={
                Array.isArray(teamList) &&
                teamList.find((value) => value.team_id === teamId)
              }
            />
          </div>
          <div className="mb-4 col-md-12">
            <label>
              <b>Labeling image </b>
            </label>
            <input
              type="file"
              onChange={(e) => changeLabelingPicture(e)}
              multiple
              className="form-control"
            />
          </div>
          <div className="mb-4 col-md-12">
            <label>
              <b>Splicing image </b>
            </label>
            <input
              type="file"
              onChange={(e) => changeSplicingPicture(e)}
              multiple
              className="form-control"
            />
          </div>
          <div className="mb-4 col-md-12">
            <label>
              <b>Closed image </b>
            </label>
            <input
              type="file"
              onChange={(e) => changeClosedPicture(e)}
              multiple
              className="form-control"
            />
          </div>
          <div className="mb-4 col-md-12">
            <label>
              <b>On tree image </b>
            </label>
            <input
              type="file"
              onChange={(e) => changeTreePicture(e)}
              multiple
              className="form-control"
            />
          </div>
          <div className="mb-4 col-md-12">
            <label htmlFor="">
              <b>Approved By:</b>
            </label>
            <Select
              onChange={(e) => setUser(e.id)}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionValue={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              isSearchable
              options={userList}
              value={
                Array.isArray(userList) &&
                userList.find((value) => value.id === user)
              }
            />
          </div>
          <div className="mt-2 mb-2">
            <button type="submit" className="btn btn-success w-100">
              Update
            </button>
          </div>
        </>
      )}
    </form>
  );
}

export default OTBs;

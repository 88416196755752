import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import AppContainer from "../../Components/Structure/AppContainer";
import useStateCallback from "../../util/customHooks/useStateCallback";
import AddOtb from "../../Components/ProjectTracker/AddOtb";
import UpdateOtb from "../../Components/ProjectTracker/UpdateOtb";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

function ListOTBsProducts() {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState(false);
  const { batch, id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(<AddOtb id={id} batch={batch} f={listProducts} isOpen={true} />)
    );
  };

  const handleModal2 = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateOtb
          id={id}
          otb_id={item.id}
          batch={batch}
          f={listProducts}
          isOpen={true}
          otb_desat={item.otb_desat}
          wkt_geom={item.wkt_geom}
          fid={item.fid}
          city={item.city}
          trunk={item.trunk}
          name={item.name}
          pole_id={item.pole_id}
          nb_splitter={item.nb_splitter}
          is_derivation={item.is_derivation}
          template={item.template}
          cable_entree={item.cable_entree}
          contractor={item.contractor}
          nb_splice_est_sc={item.nb_splice_est_sc}
          nb_splice_est_otb={item.nb_splice_est_otb}
        />
      )
    );
  };

  useEffect(() => {
    SearchOTBs();
  }, [searchQuery, batch]);

  const SearchOTBs = async () => {
    setLoading(true);
    const server_response = await ajaxProject.searchOTBs(searchQuery);
    setLoading(false);
    if (searchQuery.length > 0) {
      if (server_response.status === "OK") {
        setProductList(server_response.details);
      } else {
        setProductList(server_response.details);
      }
    } else {
      setLoading(true);
      const server_response = await ajaxProject.fetchOTBBatchProducts(batch);
      setLoading(false);

      if (server_response.status === "OK") {
        setProductList(server_response.details);
      }
    }
  };

  const listProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProject.fetchOTBBatchProducts(batch);
    setLoading(false);

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    if (Array.isArray(productList)) {
      const data = productList.map((item, index) => ({
        wkt_geom: item.wkt_geom,
        fid: item.fid,
        id: item.idd,
        id_town: item.id_town,
        id_neighborhood: item.id_neighborhood,
        "Pole ID": item.pole_id,
        Trunk: item.trunk,
        height: item.height,
        Contractor: item.contractor,
        Status: item.status,
        install_date: item.install_date,
      }));
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Cables");

      // Generate a file name
      const fileName = "cables_data.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  return (
    <AppContainer title="Batch OTBs List">
      <>
        <Toaster position="top-center" reverseOrder={false} />
        {modal}
        <Link
          to={`/project/otbs/${id}`}
          className="btn btn-primary btn-sm mb-2">
          Back To OTBs
        </Link>
        <div className="mt-4 mb-4">
          <div class="input-group">
            <input
              class="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for..."
              type="text"
            />
            <span class="input-group-btn">
              <button
                class="btn ripple btn-primary"
                style={{
                  height: 26,
                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
                type="button">
                <span class="input-group-btn">
                  <i class="fa fa-search"></i>
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="card custom-card">
          <div className="card-body">
            <div className="mb-4">
              <h6 style={{ float: "left" }} className="card-title mb-4">
                Upload Batch Products
              </h6>
              <button
                style={{ float: "right" }}
                onClick={exportToExcel}
                className="btn btn-sm btn-secondary mb-3 ml-3 mr-3">
                Export to Excel
              </button>
              <a
                style={{ float: "right", color: "white" }}
                onClick={() => handleModal()}
                className="btn btn-sm btn-primary">
                Add Otb
              </a>
            </div>

            <div className="table-responsive">
              <div className="border-top mt-3"></div>
              {loading && <Loader />}
              {!loading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>wkt_geom</th>
                      <th>fid</th>
                      <th>city</th>
                      <th>name</th>
                      <th>template</th>
                      <th>trunk</th>
                      <th>pole_id</th>
                      <th>contractor</th>
                      <th>nb_splitter</th>
                      <th>comment</th>
                      <th>is_derivation</th>
                      <th>cable_entree</th>
                      <th>nb_splice_est_sc</th>
                      <th>nb_splice_est_otb</th>
                      <th>otb_desat</th>
                      <th>status</th>
                      <th>install_date</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!Array.isArray(productList) && <tr>No otbs found</tr>}
                    {Array.isArray(productList) &&
                      productList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.wkt_geom}</td>
                          <td>{item.fid}</td>
                          <td>{item.city}</td>
                          <td>{item.name}</td>
                          <td>{item.template}</td>
                          <td>{item.trunk}</td>
                          <td>{item.pole_id}</td>
                          <td>{item.contractor}</td>
                          <td>{item.nb_splitter}</td>
                          <td>{item.comment}</td>
                          <td>{item.is_derivation}</td>
                          <td>{item.cable_entree}</td>
                          <td>{item.nb_splice_est_sc}</td>
                          <td>{item.nb_splice_est_otb}</td>
                          <td>{item.otb_desat}</td>
                          <td>{item.status}</td>
                          <td>{item.install_date}</td>
                          <td>
                            <a
                              onClick={(e) => handleModal2(e, item)}
                              className="btn btn-primary btn-sm">
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    </AppContainer>
  );
}

export default ListOTBsProducts;

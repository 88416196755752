import React, { useContext, useEffect, useState } from "react";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxClient from "../../util/remote/ajaxClient";
import UserContext from "../../Context/UserContext";
import ajaxStores from "../../util/remote/ajaxStores";

export default function CreateInventory() {
  const [client, setClient] = useState("");
  const [delivery_note, setDeliveryNote] = useState("");
  const [received_by, setReceivedBy] = useState("");
  const [date, setDate] = useState("");
  const [store_id, setStore_id] = useState("");
  const [vehicle_plate, setVehicle_plate] = useState("");
  const [time, setTime] = useState("");
  const [delivered_by, setDelivered_by] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { userList } = useContext(UserContext);

  const navigator = useNavigate();

  const handleDeliveryNote = async (e) => {
    e.preventDefault();

    if (delivery_note.length > 0 && client > 0 && store_id.length > 0) {
      setLoading(true);
      var data = {
        client: client,
        date_added: date,
        delivery_note: delivery_note,
        received_by: received_by,
        delivered_by: delivered_by,
        vehicle_plate: vehicle_plate,
        time: time,
        comment: comment,
        store_id: store_id,
      };

      const server_response = await ajaxProduct.createDeliveryNote(data);
      setLoading(false);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        setTimeout(() => {
          navigator("/delivery-notes/input/add/" + server_response.details);
        }, 1500);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const [clientList, setClientList] = useState(false);

  const getClients = async () => {
    const server_response = await ajaxClient.fetchProjectClientList();

    if (server_response.status === "OK") {
      setClientList(server_response.details);
    }
  };
  const [store, setStores] = useState(false);

  const get_stores = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    }
  };

  useEffect(() => {
    getClients();
    get_stores();
  }, []);

  return (
    <AppContainer title="Record Inventory">
      <Toaster />
      <div className="card custom-card">
        <div className="card-body">
          <div>
            <h6 className="card-title mb-4">Record Inventory</h6>
          </div>
          <form onSubmit={(e) => handleDeliveryNote(e)} method="post">
            <div className="row">
              <div className="mb-4 col-md-6">
                <label htmlFor="">Reference NO:</label>
                <input
                  type="text"
                  value={delivery_note}
                  onChange={(e) => setDeliveryNote(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Client:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setClient(e.client_id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.client_id}
                  isSearchable
                  options={Array.isArray(clientList) ? clientList : []}
                  value={
                    Array.isArray(clientList) &&
                    clientList.find((value) => value.client_id === client)
                  }
                />
              </div>

              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Received By:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setReceivedBy(e.id)}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  getOptionValue={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  isSearchable
                  options={Array.isArray(userList) ? userList : []}
                  value={
                    Array.isArray(userList) &&
                    userList.find((value) => value.id === received_by)
                  }
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Store:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setStore_id(e.id)}
                  getOptionLabel={(option) => option.storeName}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={Array.isArray(store) ? store : []}
                  value={
                    Array.isArray(store) &&
                    store.find((value) => value.id === store_id)
                  }
                />
              </div>

              <div className="mb-4 col-md-3">
                <label htmlFor="">
                  Delivery Date:<span className="tx-danger">*</span>
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-3">
                <label htmlFor="">
                  Delivery Time:<span className="tx-danger">*</span>
                </label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Delivered_by:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={delivered_by}
                  onChange={(e) => setDelivered_by(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Vehicle Number_palte:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={vehicle_plate}
                  onChange={(e) => setVehicle_plate(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="mb-4 col-md-12">
                <label htmlFor="">comment:</label>

                <textarea
                  value={comment}
                  style={{ fontSize: "32px", color: "red" }}
                  onChange={(e) => setComment(e.target.value)} // Corrected to onChange
                  className="form-control"
                  rows="2"
                  // placeholder="comment about the delivery if any"
                />
              </div>
              <div className="mb-4 col-md-12">
                {loading && (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-success">
                    Adding...
                  </button>
                )}
                {!loading && (
                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    className="btn btn-success">
                    Add Inventory
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppContainer>
  );
}

import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchProductList(data) {
    let response = await apiCall("inventory/products/list", data);
    return response;
  },
  async fetchInventory(data) {
    let response = await apiCall("inventory/records/list", data);
    return response;
  },
  async fetchInventoryPerClient(data) {
    let response = await apiCall("inventory/products/client", data);
    return response;
  },
  async createInventory(product_id, quantity, description, note_id, unit_cost) {
    let data = {
      product_id: product_id,
      quantity: quantity,
      description: description,
      note_id: note_id,
      unit_cost: unit_cost,
    };

    let response = await apiCall("inventory/record/create", data);

    return response;
  },
  async updateInventory(
    inventory_id,
    product_id,
    quantity,
    description,
    unit_cost
  ) {
    let data = {
      inventory_id: inventory_id,
      product_id: product_id,
      quantity: quantity,
      description: description,
      unit_cost: unit_cost,
    };
    let response = await apiCall("inventory/record/update", data);
    return response;
  },
  async countInventory(data) {
    let response = await apiCall("inventory/products/count", data);
    return response;
  },

  async createProduct(product_name, singular, plural, product_type, unitCost) {
    let data = {
      product_name: product_name,
      singular: singular,
      plural: plural,
      product_type: product_type,
      unit_cost: unitCost,
    };

    let response = await apiCall("inventory/product/create", data);
    return response;
  },

  async updateProduct(
    product_id,
    product_name,
    singular,
    plural,
    product_type,
    unitCost
  ) {
    let data = {
      product_id: product_id,
      product_name: product_name,
      singular: singular,
      plural: plural,
      product_type: product_type,
      unit_cost: unitCost,
    };

    let response = await apiCall("inventory/product/update", data);
    return response;
  },
  async createInventoryOutput(data) {
    let response = await apiCall("inventory/record/output/create", data);

    return response;
  },
  async createInventoryTransfer(data) {
    let response = await apiCall("inventory/record/inventory_transfer", data);

    return response;
  },

  async createUserInventoryAllocation(data) {
    let response = await apiCall("inventory/allocation/create/user", data);

    return response;
  },

  async createTeamInventoryAllocation(data) {
    let response = await apiCall("inventory/allocation/create/team", data);

    return response;
  },

  async fetchUserProductAllocationList(user_id) {
    let data = {
      user_id: user_id,
    };
    let response = await apiCall("inventory/products/list/user", data);
    return response;
  },
  async fetchTeamProductAllocationList(team_id) {
    let data = {
      team_id: team_id,
    };
    let response = await apiCall("inventory/products/list/team", data);
    return response;
  },
  async fetchToolsList(data) {
    let response = await apiCall("inventory/tools/list", data);
    return response;
  },
  async fetchPPEList(data) {
    let response = await apiCall("inventory/ppe/list", data);
    return response;
  },

  async fetchProductTypes(data) {
    let response = await apiCall("product/types/list", data);
    return response;
  },

  async fetchInstallations(data) {
    let response = await apiCall("project/installations/all", data);
    return response;
  },
  async returnTeamTool(data) {
    let response = await apiCall("inventory/allocation/return/team", data);

    return response;
  },
  async returnUserTool(data) {
    let response = await apiCall("inventory/allocation/return/user", data);
    return response;
  },
  async filterClientInventory(client) {
    let data = {
      client: client,
    };

    let response = await apiCall("inventory/products/client", data);

    return response;
  },
  async createDeliveryNote(data) {
    let response = await apiCall("inventory/notes/create", data);

    return response;
  },
  async fetchDeliveryNotes(data) {
    let response = await apiCall("inventory/notes/list", data);
    return response;
  },
  async fetchProductsPerNote(note_id) {
    let data = {
      note_id: note_id,
    };
    let response = await apiCall("inventory/products/note", data);
    return response;
  },
  async fetchNoteInfo(note_id) {
    let data = {
      note_id: note_id,
    };
    let response = await apiCall("inventory/notes/info", data);
    return response;
  },

  async fetchAllTools(data) {
    let response = await apiCall("inventory/tools/all", data);
    return response;
  },
  async fetchClientProductTransactions(product, client) {
    let data = {
      product_id: product,
      client: client,
    };
    let response = await apiCall("inventory/list/product_client", data);
    return response;
  },
};

import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async getRegionList(data) {
       
        let response = await apiCall("region/list", data)
        return response;
       
      },
      async createRegion(region_name)
    {
        let data={
            "region_name":region_name
        }
        let response = await apiCall("region/create", data)

        return response;

    }

    ,
    async fetchRegionNumber(data) {
       
        let response = await apiCall("region/count", data)
        return response;
       
      },
      async updateRegion(region_id,region_name)
    {
        let data={
            "region_id":region_id,
            "region_name":region_name
        }
        let response = await apiCall("region/update", data)

        return response;

    },
    async deleteRegion(region_id)
    {
        let data={
            "id":region_id
        }
        let response = await apiCall("region/delete", data)

        return response;

    }




}
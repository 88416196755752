import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import ProjectContext from '../../Context/ProjectContext';
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import IssueLogContext from '../../Context/IssueLogContext';
import Select from 'react-select'
import ajaxIssueLog from "../../util/remote/ajaxIssueLog";

const CreateIssueLog=()=>{
    const {getIssueLogList} = useContext(IssueLogContext);
    const [loading,setLoading] = useState(false)
    const {projectList} = useContext(ProjectContext);
    const [project,setProject] = useState("")
    const [logDetails,setLogDetails] = useState("")

    const handleAdd = async(e) =>{
        e.preventDefault()

        if(project>0 || logDetails.length>0){
            setLoading(true)
            const server_response = await ajaxIssueLog.createIssueLog(project,logDetails);
            setLoading(false)
            if(server_response.status==="OK"){
                setProject("")
                setLogDetails('')
                toast.success(server_response.message)
                getIssueLogList()
            }
            else{
                toast.error(server_response.message)
            }
        }
        else{
            toast.error("Complete all fields and try again")
        }  
    }

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Issue Log</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Add New Issue Log"
            id="model-new-log"
            size="md"
            footer={RenderFooter}
        >

            <div className="mb-4">
                <label htmlFor="" className="mb-1">Issue Log Details</label>
                <textarea type="text" rows="3" onChange={(e)=>setLogDetails(e.target.value)} value={logDetails} className="form-control"></textarea>
            </div>
            <div className="mb-4">
                <label htmlFor="">Project Affected</label>                   
                <Select
                    onChange={(e)=>setProject(e.project_id)}
                    getOptionLabel ={(option)=>option.project_name}
                    getOptionValue ={(option)=>option.project_id}
                    isSearchable
                    options={projectList}
                    value={Array.isArray(projectList) && projectList.find(( value ) => value.project_id===project)}
                />
            </div>

        </SystemModal>
    )

}

export default CreateIssueLog
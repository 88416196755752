import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import UserContext from "../../Context/UserContext";
import Select from "react-select";
import Loader from "../../Components/Common/Loader";
import SystemModal from "../../Components/Common/SystemModal";

const CreatePaymentDeposit = (props) => {
  const [loading, setLoading] = useState(false);
  const [amount_out, setAmount] = useState("");
  const [added_by, setAddedBy] = useState("");
  const [date_added, setDateAdded] = useState("");
  const [user, setUser] = useState("");
  const { userList } = useContext(UserContext);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (user > 0 && amount_out > 0) {
      setLoading(true);
      const server_response = await ajaxPayRate.createDeposit(
        user,
        amount_out,
        added_by,
        date_added
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setAmount("");
        setAddedBy("");
        setDateAdded("");
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Register Debit
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Debit"
      id="model-new-paydeposits"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select Employee</label>
        <Select
          onChange={(e) => setUser(e.id)}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          getOptionValue={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          isSearchable
          options={Array.isArray(userList) ? userList : []}
          value={
            Array.isArray(userList) &&
            userList.find((value) => value.id === user)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Amount</label>
        <input
          onChange={(e) => setAmount(e.target.value)}
          value={amount_out}
          type="number"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Added By</label>
        <input
          onChange={(e) => setAddedBy(e.target.value)}
          value={added_by}
          type="text"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Date Paid</label>
        <input
          onChange={(e) => setDateAdded(e.target.value)}
          value={date_added}
          type="date"
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default CreatePaymentDeposit;

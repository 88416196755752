import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const AddHardware = (props) => {
  const [pole_id, setPoleId] = useState("");
  const [nb_cables, setNbCables] = useState("");
  const [nb_ofc12, setNbOfc12] = useState("");
  const [nb_ofc144, setNbOfc144] = useState("");
  const [nb_ofc48, setNbOfc48] = useState("");
  const [trunk, setTrunk] = useState("");
  const [estimated_hardware, setEstimatedhardware] = useState("");
  const [Nb_spriral_11, setNbSpriral11] = useState("");
  const [Nb_spriral_15, setNbSpriral15] = useState("");
  const [Nb_DeadN_11, setNbDead11] = useState("");
  const [Nb_DeadN_15, setNbDead15] = useState("");
  const [contractor, setContractor] = useState("");

  const [loading, setLoading] = useState(false);
  const handleAdd = async (e) => {
    e.preventDefault();
    // console.log(props);
    if (
      props.batch.length > 0 &&
      props.id * 1 > 0 &&
      nb_cables.length > 0 &&
      estimated_hardware.length > 0 &&
      trunk.length > 0 &&
      contractor.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProject.createHardware(
        props.id,
        props.batch,
        pole_id,
        nb_cables,
        nb_ofc12,
        nb_ofc144,
        nb_ofc48,
        trunk,
        estimated_hardware,
        Nb_spriral_11,
        Nb_spriral_15,
        Nb_DeadN_11,
        Nb_DeadN_15,
        contractor
      );
      setLoading(false);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        setPoleId("");
        setNbCables("");
        setNbOfc12("");
        setNbOfc144("");
        setNbOfc48("");
        setTrunk("");
        setEstimatedhardware("");
        setNbSpriral11("");
        setNbSpriral15("");
        setNbDead11("");
        setNbDead15("");
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Hardware
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add Hardware"
      id="model-new-hardware"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Pole ID</label>
        <input
          type="text"
          value={pole_id}
          placeholder={"pole_id"}
          onChange={(e) => setPoleId(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb_Cables</label>
        <input
          type="text"
          value={nb_cables}
          placeholder={"nb_cables"}
          onChange={(e) => setNbCables(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb_Ofc12</label>
        <input
          type="text"
          value={nb_ofc12}
          placeholder={"nb_ofc12"}
          onChange={(e) => setNbOfc12(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb_Ofc144</label>
        <input
          type="text"
          value={nb_ofc144}
          placeholder={"nb_ofc144"}
          onChange={(e) => setNbOfc144(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">nb_ofc48</label>
        <input
          type="text"
          value={nb_ofc48}
          placeholder={"nb_ofc48"}
          onChange={(e) => setNbOfc48(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">estimated_hardware</label>
        <input
          type="text"
          value={estimated_hardware}
          placeholder={"estimated_hardware"}
          onChange={(e) => setEstimatedhardware(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb spriral 11</label>
        <input
          type="text"
          value={Nb_spriral_11}
          placeholder={"nb_spriral_11"}
          onChange={(e) => setNbSpriral11(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb spriral 15</label>
        <input
          type="text"
          value={Nb_spriral_15}
          placeholder={"nb_spriral_15"}
          onChange={(e) => setNbSpriral15(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Nb_DeadN_11</label>
        <input
          type="text"
          value={Nb_DeadN_11}
          placeholder={"Nb_DeadN_11"}
          onChange={(e) => setNbDead11(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb_DeadN_11</label>
        <input
          type="text"
          value={Nb_DeadN_15}
          placeholder={"Nb_DeadN_15"}
          onChange={(e) => setNbDead15(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Contractor</label>
        <input
          type="text"
          value={contractor}
          placeholder={"contractor"}
          onChange={(e) => setContractor(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default AddHardware;

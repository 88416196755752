import { useEffect, useState } from "react";
import Select from "react-select";
import ajaxProduct from "../../util/remote/ajaxProduct";
import Alert from "../../Components/Common/Alert";
import Loader from "../../Components/Common/Loader";
import SystemModal from "../../Components/Common/SystemModal";

const UpdateProduct = (props) => {
  const [productName, setProductName] = useState(props.productName);
  const [loading, setLoading] = useState(false);
  const [plural, setPlural] = useState(props.plural);
  const [singular, setSingular] = useState(props.singular);
  const [product_type, setProductType] = useState(props.productType);
  const [unitCost, setUnitCost] = useState(props.unitCost);
  const [productTypes, setProductTypes] = useState(false);
  const [info, setInfo] = useState(false);

  const getProductTypes = async () => {
    const server_response = await ajaxProduct.fetchProductTypes();

    if (server_response.status === "OK") {
      setProductTypes(server_response.details);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (props.productID > 0 || productName.length > 0 || unitCost > 0) {
      setLoading(true);
      const server_response = await ajaxProduct.updateProduct(
        props.productID,
        productName,
        singular,
        plural,
        product_type,
        unitCost
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setInfo(<Alert type="success" message={server_response.message} />);
        props.f();
      }
    } else {
      setInfo(<Alert type="danger" message="Complete all fields" />);
    }
  };

  useEffect(() => {
    getProductTypes();
  }, []);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Product"
      id="model-update-product"
      size="md"
      footer={RenderFooter}>
      {info}
      <div className="mb-4">
        <label htmlFor="">
          Product Name:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">
          Singular:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={singular}
          onChange={(e) => setSingular(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">
          Plural:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={plural}
          onChange={(e) => setPlural(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Product Type</label>
        <Select
          onChange={(e) => setProductType(e.type_name)}
          getOptionLabel={(option) => option.type_name}
          getOptionValue={(option) => option.type_name}
          isSearchable
          options={Array.isArray(productTypes) ? productTypes : []}
          value={
            Array.isArray(productTypes) &&
            productTypes.find((value) => value.type_name === product_type)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">
          Unit Cost:<span className="tx-danger">*</span>
        </label>
        <input
          type="number"
          value={unitCost}
          onChange={(e) => setUnitCost(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateProduct;

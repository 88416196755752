import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import TableHeader from "../../Components/Common/TableHeader";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import ajaxReports from "../../util/remote/ajaxReports";
import ajaxProduct from "../../util/remote/ajaxProduct";
import Loader from "../../Components/Common/Loader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TeamContext from "../../Context/TeamContext";
// import { teal } from '@material-ui/core/colors';
import toast, { Toaster } from "react-hot-toast";

function InstallationsReports() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [project, setProject] = useState("");
  const [status, setStatus] = useState("");
  const [task, setTask] = useState("");
  const [team, setTeam] = useState("");
  const { teamList } = useContext(TeamContext);
  const [projectList, setProjectList] = useState(false);
  const [installationsList, setInstallationsList] = useState(false);
  const [installationsSearch, setInstallationsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const getProjects = async () => {
    // setLoading(true);
    const server_response = await ajaxProject.fetchProjectList();
    // setLoading(false);
    // console.log(server_response.details);
    if (server_response.status === "OK") {
      let pdt = server_response.details;
      pdt.unshift({ project_id: "", project_name: "All projects" });
      setProjectList(server_response.details);
    }
  };

  const getInstallations = async () => {
    setLoading(true);
    const server_response = await ajaxProduct.fetchInstallations();
    setLoading(false);
    if (server_response.status === "OK") {
      setInstallationsList(server_response.details);
    }
  };

  const searchInstallations = async () => {
    // Ensure task, team, and status are provided
    if (!task || !team || !status) {
      toast.error("Task, team and status are required fields!");
    }

    setLoading2(true);
    const server_response = await ajaxProject.filterInstallations(
      project,
      task,
      team,
      status
    );
    setLoading2(false);

    if (server_response.status === "OK") {
      if (server_response.details.length === 0) {
        setInstallationsSearch([]);
      } else {
        setInstallationsSearch(server_response.details);
      }
    } else {
      setInstallationsSearch([]);
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Installation   Report"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = [
      "No.",
      "Pole ID",
      "Project",
      "Height",
      "Contractor",
      "Status",
      "Install Date",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    // data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`installations_report_${new Date().toLocaleDateString()}.pdf`);
  };

  useEffect(() => {
    getProjects();
    getInstallations();
  }, []);
  return (
    <AppContainer title="Installations Report">
      <Toaster />
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Installations Report"
              viewButton={
                <a
                  href="#"
                  onClick={exportToPDF}
                  className="btn btn-secondary btn-sm mr-2"
                  style={{ float: "right" }}>
                  Export to PDF
                </a>
              }
            />

            <div class="">
              <a
                onClick={toggleDropdown}
                href="#"
                className="btn ripple btn-secondary btn-sm navresponsive-toggler mb-0">
                <i className="fe fe-filter mr-1"></i> Filter{" "}
                <i
                  className={`fas fa-caret-${
                    isDropdownVisible ? "up" : "down"
                  } ml-1`}></i>
              </a>
            </div>
            {isDropdownVisible && (
              <div class="responsive-background" data-select2-id="9">
                {/* {loading && <Loader />}
                {loading2 && <Loader />} */}
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  data-select2-id="navbarSupportedContent">
                  <div className="row">
                    <div className="col-md-12 col-xl-12 mb-0">
                      <div className="row">
                        <div className="form-group col-xl-6 mt-2">
                          <b>By Project:</b>
                          <br />
                          <Select
                            onChange={(e) => setProject(e.project_id)}
                            getOptionLabel={(option) => option.project_name}
                            getOptionValue={(option) => option.project_id}
                            isSearchable
                            options={
                              Array.isArray(projectList) ? projectList : []
                            }
                            value={
                              Array.isArray(projectList) &&
                              projectList.find(
                                (value) => value.project_id === project
                              )
                            }
                          />
                        </div>

                        <div className="form-group col-xl-6 mt-2">
                          <b>By Task:</b>
                          <br />

                          <select
                            defaultValue={task}
                            onChange={(e) => setTask(e.target.value)}
                            name=""
                            id=""
                            className="form-control">
                            <option value="">---Select Task---</option>
                            <option value="1">Pole Pit</option>
                            <option value="2">Pole Planting</option>
                            <option value="3">Pole Plumbing</option>
                            <option value="4">Cross Installation</option>
                            <option value="5">Otb Installation</option>
                            <option value="6">Joint Installation</option>
                            <option value="7">Hardware Installation</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-xl-6 mt-2">
                          <b>By Team:</b>
                          <br />
                          <Select
                            onChange={(e) => setTeam(e.team_id)}
                            getOptionLabel={(option) => option.team_name}
                            getOptionValue={(option) => option.team_id}
                            isSearchable
                            options={Array.isArray(teamList) ? teamList : []}
                            value={
                              Array.isArray(teamList) &&
                              teamList.find((value) => value.team_id === team)
                            }
                          />
                        </div>

                        <div className="form-group col-xl-6 mt-2">
                          <b>By Status:</b>
                          <br />

                          <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            required
                            className="form-control">
                            <option value="">--- select status ---</option>
                            <option value="NOT INSTALLED">NOT INSTALLED</option>
                            <option value="INSTALLED">INSTALLED</option>
                            <option value="ROW">ROW</option>
                            <option value="FROZEN">FROZEN</option>
                            <option value="ELIMINATED">ELIMINATED</option>
                            <option value="ROCK">ROCK</option>
                            <option value="SWAMP">SWAMP</option>
                            <option value="POLE RELOCATION">
                              POLE RELOCATION
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-12 mb-5">
                      <div
                        className="form-group "
                        style={{ marginTop: "32px" }}>
                        {" "}
                        <a
                          href="#"
                          onClick={searchInstallations}
                          className="btn bg-primary btn-block color-white"
                          style={{
                            color: "white",
                            display: "block",
                            width: "100%",
                          }}>
                          Search Installations
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="border-top mt-2"></div>
            <div className="table-responsive">
              {loading && <Loader />}
              {loading2 && <Loader />}

              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">#NO</th>
                    <th scope="col">POLE ID</th>
                    <th>Project</th>
                    <th>Height</th>
                    <th>Contractor</th>
                    <th>Verified Status</th>
                    <th>install_date</th>
                  </tr>
                </thead>
                <tbody>
                  {installationsSearch && Array.isArray(installationsSearch) ? (
                    installationsSearch.length > 0 ? (
                      installationsSearch.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>

                          <td>{item.pole_id}</td>
                          <td>{item.project}</td>

                          <td>{item.height}</td>
                          <td>{item.contractor}</td>
                          <td>
                            {item.state === "3"
                              ? "Verified / Complete Installation"
                              : item.state === "2"
                              ? "Pending Installation"
                              : "Not started"}
                          </td>
                          <td>
                            {item.verified_date
                              ? item.verified_date
                              : "Not installed"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No installations found.
                        </td>
                      </tr>
                    )
                  ) : (
                    Array.isArray(installationsList) &&
                    installationsList.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item.pole_id}</td>
                        <td>{item.project}</td>
                        <td>{item.height}</td>
                        <td>{item.contractor}</td>
                        <td>
                          {item.state === "3"
                            ? "Verified / Complete Installation"
                            : item.state === "2"
                            ? "Pending Installation"
                            : "Not started"}
                        </td>
                        <td>
                          {item.verified_date
                            ? item.verified_date
                            : "Not installed"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default InstallationsReports;

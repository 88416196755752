import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
  

      async fetchProjectClientList(data) {
       
        let response = await apiCall("project_client/list", data)
        return response;
       
      },
      async fetchProjectClientProfile(client_id)
      {
          let data={
              "client_id":client_id
          }
          let response = await apiCall("project_client/profile", data)
  
          return response;
  
      },
      async fetchClientVisits(client_id)
      {
          let data={
              "client_id":client_id
          }
          let response = await apiCall("visit/list", data)
  
          return response;
  
      },
      async fetchAllClientVisits(data)
      {
         
          let response = await apiCall("visit/list", data)
          return response;
  
      },
      async fetchClientVisitsDashboard(limit)
      {
          let data={
              "limit":limit
          }
          let response = await apiCall("visit/list", data)
  
          return response;
  
      },
      async fetchVisitsToday(data)
      {
         
          let response = await apiCall("visit/today", data)
          return response;
  
      },
      async createClientVisit(project_id,team_id,client_id,revisit_date,feedback,lat,longitude) {
        let data = {
          "project_id":project_id,
          "team_id":team_id,
          "client_id":client_id,
          "revisit_date":revisit_date,
          "feedback":feedback,
          "lat":lat,
          "longitude":longitude
        }
       
        let response = await apiCall("visit/create", data)
        return response;
      
      },
      async searchVisits(startDate, endDate) {
        let data = {
          from: startDate,
          to: endDate,
        };
        let response = await apiCall("visit/search", data);
    
        return response;
      },
      async createFeedback(visit_id,feedback) {
        let data = {
          "visit_id":visit_id,
          "feedback":feedback
        }
       
        let response = await apiCall("feedback/create", data)
        return response;
      
      },
      async fetchFeedback(visit_id)
      {
          let data={
              "visit_id":visit_id
          }
          let response = await apiCall("feedback/list", data)
  
          return response;
  
      },
      async updateClientVisit(visit_id,feedback_id,project_id,team_id,client_id,revisit_date,feedback) {
        let data = {
          "visit_id":visit_id,
          "feedback_id":feedback_id,
          "project_id":project_id,
          "team_id":team_id,
          "client_id":client_id,
          "revisit_date":revisit_date,
          "feedback":feedback
        }
       
        let response = await apiCall("visit/update", data)
        return response;
      
      },
      async fetchClientContacts(client_id)
      {
          let data={
              "client_id":client_id
          }
          let response = await apiCall("contact/list", data)
  
          return response;
  
      },
      async fetchUserClients(user_id)
      {
          let data={
              "user_id":user_id
          }
          let response = await apiCall("user/clientlist", data)
  
          return response;
  
      },
      async createClient(region_id,user_id, client_name, email, physicalAddress, village, district,latitude,longitude){
          let data={
            "region_id":region_id,
            "user_id":user_id,
            "name":client_name,
            "address":physicalAddress,
            "district":district,
            "village":village,
            "lat":latitude,
            "long":longitude,
            "email":email
          }
          let response = await apiCall("client/create", data)
          return response;
      },
      async createProjectClient(name, district, email, contact, description){
        let data={
          "name":name,
          "district":district,
          "contact":contact,
          "email":email,
          "description":description
        }
        let response = await apiCall("project_client/create", data)
        return response;
    },
      async updateClient(client_id,region_id,user_id, client_name, email, physicalAddress, village, district,lat,longitude,status)
      {
          let data={
            "client_id":client_id,
            "region_id":region_id,
            "user_id":user_id,
            "name":client_name,
            "address":physicalAddress,
            "district":district,
            "village":village,
            "lat":lat,
            "longitude":longitude,
            "email":email,
            "status":status
          }
          let response = await apiCall("client/update", data)
          return response;
      },

      async updateProjectClient(client_id,name, district, contact, email, description)
      {
          let data={
            "client_id":client_id,
            "name":name,
            "district":district,
            "contact":contact,
            "email":email,
            "description":description
          }
          let response = await apiCall("project_client/update", data)
          return response;
      },

      async searchClients(query)
      {
          let data={
              "query":query
          }
          let response = await apiCall("client/search", data)
  
          return response;
  
      }



      
}
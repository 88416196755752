import React, { useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";

function CreateProduct() {
  const [productName, setProductName] = useState("");
  const [loading, setLoading] = useState(false);
  const [plural, setPlural] = useState("");
  const [singular, setSingular] = useState("");
  const [product_type, setProductType] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [productTypes, setProductTypes] = useState(false);
  const navigator = useNavigate();

  const getProductTypes = async () => {
    const server_response = await ajaxProduct.fetchProductTypes();

    if (server_response.status === "OK") {
      setProductTypes(server_response.details);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (
      productName.length > 0 &&
      singular.length > 0 &&
      plural.length > 0 &&
      unitCost.length > 0
    ) {
      const server_response = await ajaxProduct.createProduct(
        productName,
        singular,
        plural,
        product_type,
        unitCost
      );

      setLoading(true);
      if (server_response.status === "OK") {
        toast.success(server_response.message);

        setTimeout(() => {
          setLoading(false);
        }, 500);
        setTimeout(() => {
          navigator(-1);
        }, 1500);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  useEffect(() => {
    getProductTypes();
  }, []);

  return (
    <AppContainer title="Add Product">
      <Toaster />
      <div className="card custom-card">
        <div className="card-body">
          <div>
            <h6 className="card-title mb-4">Add New Product</h6>
          </div>
          <form onSubmit={(e) => handleAddProduct(e)} method="post">
            <div className="row">
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Product Name:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Singular:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={singular}
                  onChange={(e) => setSingular(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Plural:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={plural}
                  onChange={(e) => setPlural(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">Product Type</label>
                <Select
                  onChange={(e) => setProductType(e.type_name)}
                  getOptionLabel={(option) => option.type_name}
                  getOptionValue={(option) => option.type_name}
                  isSearchable
                  options={Array.isArray(productTypes) ? productTypes : []}
                  value={
                    Array.isArray(productTypes) &&
                    productTypes.find(
                      (value) => value.type_name === product_type
                    )
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Unit Cost:<span className="tx-danger">*</span>
                </label>
                <input
                  type="number"
                  value={unitCost}
                  onChange={(e) => setUnitCost(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                {loading && (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-success">
                    Adding...
                  </button>
                )}

                {!loading && (
                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    className="btn btn-success">
                    Add Product
                  </button>
                )}
              </div>
            </div>
          </form>

          {/* <h3 className='mt-4' style={{textAlign:'center'}}>OR</h3>
                <div className='mt-5'>
                    <h4>Upload products with excel.</h4>
                    
                    <form >
                        <div className="mb-4 col-md-12">
                            <label htmlFor="">Products File :<span className="tx-danger">*</span></label><br/>
                            <input type="file" class="dropify" data-height="200" className="form-control"/>
                        </div>
                        <div className="mb-4 col-md-12">
                            <button type="submit" style={{width:'100%'}} className="btn btn-success" >
                                Upload
                            </button>
                        </div>
                    </form>
                </div> */}
        </div>
      </div>
    </AppContainer>
  );
}

export default CreateProduct;

import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import Select from "react-select";
import ajaxProduct from "../../util/remote/ajaxProduct";
import AuthContext from "../../Context/AuthContext";
import ajaxStores from "../../util/remote/ajaxStores";

const CreateUserProductAllocation = (props) => {
  const { userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [ppe, setPPE] = useState("");
  const [ppeList, setPPEList] = useState("");
  const [date_allocated, setDateAllocated] = useState("");

  const [storeID, setStoreiD] = useState("");
  const [stores, setStores] = useState("");
  useEffect(() => {
    fetch_store();
  }, []);

  const fetch_store = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    } else {
      setStores("404");
    }
  };

  useEffect(() => {
    getPPE();
  }, []);

  const getPPE = async () => {
    const server_response = await ajaxProduct.fetchAllTools();

    if (server_response.status === "OK") {
      setPPEList(server_response.details);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (props.user_id > 0 && ppe > 0) {
      setLoading(true);
      let data = {
        product_id: ppe,
        user_id: props.user_id,
        quantity: quantity,
        added_by: userId,
        date_allocated: date_allocated,
        store_id: storeID,
      };
      const server_response = await ajaxProduct.createUserInventoryAllocation(
        data
      );

      setLoading(false);
      if (server_response.status === "OK") {
        setQuantity("");
        setDateAllocated("");
        toast.success(server_response.message);
        props.f(props.user_id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Allocation
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New PPE Allocation"
      id="model-new-ppeallocation"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select PPE</label>
        <Select
          onChange={(e) => setPPE(e.id)}
          getOptionLabel={(option) => option.product_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(ppeList) ? ppeList : []}
          value={
            Array.isArray(ppeList) && ppeList.find((value) => value.id === ppe)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">
          Source Store:<span className="tx-danger">*</span>
        </label>
        <Select
          onChange={(e) => setStoreiD(e.id)}
          getOptionLabel={(option) => option.storeName}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(stores) ? stores : []}
          value={
            Array.isArray(stores) &&
            stores.find((value) => value.id === storeID)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Quantity</label>
        <input
          onChange={(e) => setQuantity(e.target.value)}
          value={quantity}
          type="number"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Date Allocated</label>
        <input
          onChange={(e) => setDateAllocated(e.target.value)}
          value={date_allocated}
          type="date"
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default CreateUserProductAllocation;

import React from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { useNavigate } from "react-router-dom";
import Hardware from "./Hardware";

function UpdateHardware() {
  const navigation = useNavigate();

  return (
    <AppContainer title="Installation Details">
      <div className="card custom-card main-content-body-profile">
        <div className="card-body tab-content h-100">
          <h4 className="card-title">Update pole Hardware</h4>

          <Hardware navi={navigation} />
        </div>
      </div>
    </AppContainer>
  );
}

export default UpdateHardware;

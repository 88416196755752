import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Link, useNavigation } from "react-router-dom";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";
import { Toaster, toast } from "react-hot-toast";
import ajaxTask from "../../util/remote/ajaxTask";
import ajaxRollCall from "../../util/remote/ajaxRollCall";
import AlertDialogModal from "../../Components/Common/AlertDialogModal";
import RollCallDialog from "../DailyOutput/RollCallDialog";
import useStateCallback from "../../util/customHooks/useStateCallback";
import ajaxUser from "../../util/remote/ajaxUser";

function Rollcall(props) {
  const [loading, setLoading] = useState(false);
  const [team_members, setTeamMembers] = useState(props.team);
  const [output, setOut] = useState(props.output);
  const [presentChecked, setPresentChecked] = useState({});
  const [absentChecked, setAbsentChecked] = useState({});
  const [status, setStatus] = useState(0);
  const [modal, setModal] = useStateCallback(false);

  // fetch team members
  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    setLoading(true);
    const server_response = await ajaxUser.fetchTeamMembers(props.teamID);
    setLoading(false);
    if (server_response.status === "OK") {
      setTeamMembers(server_response.details);
    } else {
      setTeamMembers("404");
    }
  };

  useEffect(() => {
    setModal(false, () => setModal(<RollCallDialog isOpen={true} />));
  }, []);

  const handlePresentCheckboxChange = (e, itemId) => {
    // Update the presentChecked and absentChecked state based on the selected checkbox
    setPresentChecked({ ...presentChecked, [itemId]: true });
    setAbsentChecked({ ...absentChecked, [itemId]: false });
  };

  const handleAbsentCheckboxChange = (e, itemId) => {
    // Update the presentChecked and absentChecked state based on the selected checkbox
    setPresentChecked({ ...presentChecked, [itemId]: false });
    setAbsentChecked({ ...absentChecked, [itemId]: true });
  };

  const handleRollCall = async (e) => {
    e.preventDefault();
    console.log(props.output_id);
    Array.isArray(team_members) &&
      team_members.map(async (item, index) => {
        if (presentChecked[item.user_id]) {
          const server_response = await ajaxRollCall.createRollCall(
            props.output,
            item.user_id,
            1
          );
          if (server_response.status === "OK") {
            toast.success(server_response.message);
            props.getTasksList(props.teamID);
          } else {
            toast.error(server_response.message);
          }
        } else if (absentChecked[item.user_id]) {
          // Handle absent case, e.g., make an API call to create/update roll call
          const server_response = await ajaxRollCall.createRollCall(
            props.output,
            item.user_id,
            0
          );
          if (server_response.status === "OK") {
            toast.success(server_response.message);
            props.getTasksList();
          } else {
            toast.error(server_response.message);
          }
        }
      });
    setTimeout(() => {
      props.navigation(-1);
    }, 1000);
  };

  const handleModalRollCall = () => {
    setModal(false, () => setModal(<RollCallDialog isOpen={true} />));
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="submit"
            onClick={(e) => handleRollCall(e)}
            className={`btn ripple btn-success`}>
            Submit
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title={`Roll Call List ` + props.team_name}
      id="model-roll-call"
      size="md"
      footer={RenderFooter}>
      <Toaster />
      {modal}
      <table
        className="table table-hover table-bordered w-100"
        style={{ width: "100%" }}>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Member</th>
            <th>Present</th>
            <th>Absent</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(team_members) &&
            team_members.map((item, index) => (
              <tr key={item.user_id}>
                <td>
                  <li class="list-group-item d-flex align-items-center">
                    <div class="main-avatar mr-3">
                      {item.first_name.charAt(0) + item.last_name.charAt(0)}
                    </div>
                    <div>
                      <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                        {item.User_name}
                      </h6>
                      <span class="d-block tx-11 text-muted">
                        {item.role_name}
                      </span>
                    </div>
                  </li>
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <input
                    type="checkbox"
                    checked={presentChecked[item.user_id] || false}
                    onChange={(e) =>
                      handlePresentCheckboxChange(e, item.user_id)
                    }
                  />
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <input
                    type="checkbox"
                    checked={absentChecked[item.user_id] || false}
                    onChange={(e) =>
                      handleAbsentCheckboxChange(e, item.user_id)
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SystemModal>
  );
}

export default Rollcall;

import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async getPaymentDepositList(employee,from,to) {
        let data={
            "employee":employee,
            "from":from,
            "to":to
        }
        let response = await apiCall("payment/search/deposits", data)
        return response;
       
      },

      async getPaymentTasksList(employee,from,to) {
        let data={
            "employee":employee,
            "from":from,
            "to":to
        }
        let response = await apiCall("payment/search/task_payments", data)
        return response;
       
      },
      async createRegion(region_name)
    {
        let data={
            "region_name":region_name
        }
        let response = await apiCall("region/create", data)

        return response;

    }

    ,
    async fetchRegionNumber(data) {
       
        let response = await apiCall("region/count", data)
        return response;
       
      },
      async updateRegion(region_id,region_name)
    {
        let data={
            "region_id":region_id,
            "region_name":region_name
        }
        let response = await apiCall("region/update", data)

        return response;

    },
    async deleteRegion(region_id)
    {
        let data={
            "id":region_id
        }
        let response = await apiCall("region/delete", data)

        return response;

    },
    async getInventoryList(from, to, product,client) {
        let data={
            // "client":client,
            "from":from,
            "to":to,
            "product":product,
            "client":client
        }
        let response = await apiCall("inventory/search", data)
        return response;
       
      },
      
      async searchRollcall(from,to,status){
        let data={
          "from":from,
          "to":to,
          "status":status
        }
        let response = await apiCall("call/search", data)
        return response;
      
      },

      async searchInstallations(from,to,project,state){
        let data={
          "from":from,
          "to":to,
          "project":project,
          "state":state
        }
        let response = await apiCall("project/installations/search", data)
        return response;
      
      },

    
}
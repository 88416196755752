import React from 'react'
import AppContainer from '../Components/Structure/AppContainer'
import { RenderSecure } from '../util/script/RenderSecure'
import ClientCard from '../Components/Dashboard/ClientCard'
import ProjectCard from '../Components/Dashboard/ProjectCard'
import TeamsCard from '../Components/Dashboard/TeamsCard'
import RecentVisits from '../Components/Dashboard/RecentVisits'
import ClientsGraph from '../Components/Dashboard/ClientsGraph'
import WelcomeCard from '../Components/Dashboard/WelcomeCard'
import RecentProjects from '../Components/Dashboard/RecentProjects'
import RecentPayments from '../Components/Dashboard/RecentPayments'
import MyTeams from '../Components/Dashboard/MyTeams'

const DashboardPage = props => {

   return (
      <AppContainer title={"Dashboard"} >

         <div className="container-fluid p-0 sm_padding_15px">

            <div class="row">
               
               <div class="col-xl-5">
                  <WelcomeCard/>

                  <RenderSecure code='VIEW-PROJECT'>
                     {/* <RecentProjects/> */}
                  </RenderSecure>
               </div>
        
               <div class="col-xl-7">
                  <div class="row">
                     {/* <div class="col-md-4">
                        <ClientCard/>
                     </div> */}

                     <RenderSecure code='VIEW-PROJECT'>
                        <div class="col-md-4">
                           <ProjectCard/>
                        </div>
                     </RenderSecure>

                     <RenderSecure code='VIEW-TEAM'>
                        <div class="col-md-4">
                           <TeamsCard/> 
                        </div>
                     </RenderSecure>

                     {/* <ClientsGraph/> */}
                  </div>

               </div>
            </div>

            <div className="row">
                  <MyTeams/>
                  <RecentPayments/>
             
				</div>
           
								
			</div>


      </AppContainer>
   )
}

export default DashboardPage;
import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Toaster } from "react-hot-toast";
import TotalProjectsCard from "../Components/Project/TotalProjectsCard";
import HighPriorityCard from "../Components/Project/HighPriorityCard";
import MediumPriorityCard from "../Components/Project/MediumPriorityCard";
import LowPriorityCard from "../Components/Project/LowPriorityCard";
import useStateCallback from "../util/customHooks/useStateCallback";
import CreateProject from "../Components/Project/CreateProject";
import ProjectsList from "../Components/Project/ProjectsList";

const ProjectsPage = (props) => {
  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () => setModal(<CreateProject isOpen={true} />));
  };

  return (
    <AppContainer title={"Projects Dashboard"}>
      <Toaster position="top-center" reverseOrder={false} />
      {modal}

      {/* First row displaing the cards start */}
      <div className="container-fluid p-0 sm_padding_15px">
        <div
          className="row"
          style={{ marginright: "-5px", marginLeft: "-5px" }}>
          <div
            className="col-12 col-xl-12 col-md-12 col-sm-12"
            style={{ marginBottom: "40px" }}>
            <h6 className="card-title mb-4 d-flex justify-content-end">
              <button
                onClick={handleModal}
                style={{
                  borderRadius: "10px",
                }}
                className="btn ripple btn-primary btn-with-icon btn-sm">
                <i className="fe fe-plus"></i> Add New Project
              </button>
            </h6>
            <div
              className="box card-box"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
              }}>
              <TotalProjectsCard />
              <HighPriorityCard />
              <MediumPriorityCard />
              <LowPriorityCard />
            </div>
          </div>
        </div>
      </div>
      {/* First row displaing the cards ends */}

      {/* Second row displaing the list of projects start*/}
      <div className="container-fluid p-3 sm_padding_15px">
        <ProjectsList />
      </div>
      {/* Second row displaing the list of projects ends */}
    </AppContainer>
  );
};

export default ProjectsPage;

import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import ajaxTeam from "../../util/remote/ajaxTeam";

export default function TeamsCard() {
  const [teamsNumber, setTeamsNumber] = useState(false);

  const getTeamsNumber = async () => {
    const server_response = await ajaxTeam.fetchTeamNumber();
    if (server_response.status === "OK") {
      setTeamsNumber(server_response.details);
    } else {
      setTeamsNumber("404");
    }
  };

  useEffect(() => {
    getTeamsNumber();
  }, []);

  return (
    <Card
      name="Teams"
      color="dark"
      icon="fe fe-users"
      value={teamsNumber ? teamsNumber.total_p : "..."}
      link="/teams"
    />
  );
}

import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Link, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ajaxProject from "../util/remote/ajaxProject";
import Poles from "../Components/ProjectProfile/Poles.js";
import Loader from "../Components/Common/Loader";
import ListPolesBatches from "./ProductTracker/ListPolesBatches";
import Cables from "../Components/ProjectProfile/Cables";
import ListCablesBatches from "./ProductTracker/ListCablesBatches";
import OTBs from "../Components/ProjectProfile/OTBs";
import ListOTBsBatches from "./ProductTracker/ListOTBsBatches";
import Joints from "../Components/ProjectProfile/Joints";
import ListJointsBatches from "./ProductTracker/ListJointsBatches";

const ProjectJoints = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProjectProfile(id);
  }, [id]);

  const [projectProfile, setProjectProfile] = useState(false);

  const getProjectProfile = async (id) => {
    const server_response = await ajaxProject.fetchProjectProfile(id);
    if (server_response.status === "OK") {
      setProjectProfile(server_response.details);
    } else {
      setProjectProfile("404");
    }
  };

  return (
    <AppContainer title={"Products Tracker Joints"}>
      <Toaster position="top-center" reverseOrder={false} />
      {loading && <Loader />}

      {!loading && (
        <div className="col-lg-12 mb-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              {projectProfile && (
                <h5
                  className="pro-user-username text-dark mb-2 fs-30 mt-42 color-span"
                  style={{ lineHeight: "1.5" }}>
                  <small>Project :</small> {projectProfile.project_name}
                </h5>
              )}
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="col-12 col-xl-12">
                      <Joints />

                      <ListJointsBatches />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </AppContainer>
  );
};

export default ProjectJoints;

import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import TextInput from "../Common/TextInput"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import RegionContext from "../../Context/RegionContext";
import ajaxRegion from "../../util/remote/ajaxRegion";

const CreateRegion=()=>{

    const [loading, setLoading] = useState(false)
    const [region_name,setRegion] = useState('')
    const {getRegionList} = useContext(RegionContext);

    const handleAdd = async(e) =>{

        e.preventDefault()
        if(region_name.length>0){
            setLoading(true)
            const server_response = await ajaxRegion.createRegion(region_name);
            setTimeout(() => setLoading(false), 1000);
              
            if(server_response.status==="OK"){
                setRegion('')
                toast.success(server_response.message)
                getRegionList();
            }
            else{
                    toast.error(server_response.message) 
            }
        }
        else{
                
            toast.error("Complete all fields and try again")
        }
    } 

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Region</button>
                    </>
        }
    }


    return(
        <SystemModal
            title="Add Region"
            id="model-new-region"
            size="md"
            footer={RenderFooter}
        >

            <TextInput
                label="Region Name"
                value={region_name}
                type="text"
                placeholder="Enter region name"
                onChange={(e)=>setRegion(e.target.value)}
            />


        </SystemModal>
    )

}

export default CreateRegion



import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import AppContainer from "../../Components/Structure/AppContainer";
import useStateCallback from "../../util/customHooks/useStateCallback";
import UpdateCableStatus from "../../Components/ProjectProfile/UpdateCableStatus";
import TableHeader from "../../Components/Common/TableHeader";
import AddCable from "../../Components/ProjectTracker/AddCable";
import UpdateCable from "../../Components/ProjectTracker/UpdateCable";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

function ListCablesProducts() {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState(false);
  const { batch, id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [modal, setModal] = useStateCallback(false);

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateCableStatus
          cableID={item.id}
          batch={batch}
          f={listProducts}
          isOpen={true}
          install_date={item.install_date}
          start_length={item.start_length}
          end_length={item.end_length}
          status={item.status}
          comment={item.comment}
        />
      )
    );
  };

  const handleModal3 = () => {
    setModal(false, () =>
      setModal(
        <AddCable id={id} batch={batch} f={listProducts} isOpen={true} />
      )
    );
  };

  const handleModal2 = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateCable
          id={id}
          cable_id={item.id}
          batch={batch}
          f={listProducts}
          isOpen={true}
          fid={item.fid}
          city={item.city}
          name={item.name}
          template={item.template}
          gis_length={item.gis_length}
          real_length={item.real_length}
          role={item.role}
          trunk={item.trunk}
          cores={item.cores}
          contractor={item.contractor}
        />
      )
    );
  };

  useEffect(() => {
    SearchCables();
  }, [searchQuery, batch]);

  const SearchCables = async () => {
    setLoading(true);
    const server_response = await ajaxProject.searchCables(searchQuery);
    setLoading(false);
    if (searchQuery.length > 0) {
      if (server_response.status === "OK") {
        setProductList(server_response.details);
      } else {
        setProductList(server_response.details);
      }
    } else {
      setLoading(true);
      const server_response = await ajaxProject.fetchCablesBatchProducts(batch);
      setLoading(false);

      if (server_response.status === "OK") {
        setProductList(server_response.details);
      }
    }
  };

  const listProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProject.fetchCablesBatchProducts(batch);
    setLoading(false);

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    if (Array.isArray(productList)) {
      const data = productList.map((item, index) => ({
        fid: item.fid,
        city: item.city,
        name: item.name,
        template: item.template,
        gis_length: item.gis_length,
        real_length: item.real_length,
        role: item.role,
        cores: item.cores,
        Contractor: item.contractor,
        acceptance_date: item.acceptance_date,
        status: item.status,
        install_date: item.install_date,
        start_length: item.start_length,
        variance: item.variance,
        comment: item.comment,
      }));
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Cables");

      // Generate a file name
      const fileName = "cables_data.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  return (
    <AppContainer title="Batch Cables List">
      <>
        <Toaster position="top-center" reverseOrder={false} />
        {modal}
        <Link
          to={`/project/cables/${id}`}
          className="btn btn-primary btn-sm mb-2">
          Back To Cables
        </Link>
        <div className="mt-4 mb-4">
          <div class="input-group">
            <input
              class="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for..."
              type="text"
            />
            <span class="input-group-btn">
              <button
                class="btn ripple btn-primary"
                style={{
                  height: 26,
                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
                type="button">
                <span class="input-group-btn">
                  <i class="fa fa-search"></i>
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="card custom-card">
          <div className="card-body map-card">
            <button
              style={{ float: "right" }}
              onClick={exportToExcel}
              className="btn btn-sm btn-secondary mb-3 ml-3 mr-3">
              Export to Excel
            </button>
            <TableHeader
              title="Upload Batch Products"
              viewButton={
                <a
                  href="#"
                  onClick={handleModal3}
                  className="btn btn-primary btn-sm"
                  style={{ float: "right" }}>
                  Add Cable
                </a>
              }
            />

            <div className="table-responsive">
              <div className="border-top mt-3"></div>
              {loading && <Loader />}
              {!loading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>fid</th>
                      <th>city</th>
                      <th>name</th>
                      <th>template</th>
                      <th>gis_length</th>
                      <th>real_length</th>
                      <th>role</th>
                      <th>trunk</th>
                      <th>cores</th>
                      <th>contractor</th>
                      <th>acceptance_date</th>
                      <th>status</th>
                      <th>install_date</th>
                      <th>start_length</th>
                      <th>end_length</th>
                      <th>variance</th>
                      <th>comment</th>
                      <th>...</th>
                      <th>edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!Array.isArray(productList) && <tr>No cables found</tr>}
                    {Array.isArray(productList) &&
                      productList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.fid}</td>
                          <td>{item.city}</td>
                          <td>{item.name}</td>
                          <td>{item.template}</td>
                          <td>{item.gis_length}</td>
                          <td>{item.real_length}</td>
                          <td>{item.role}</td>
                          <td>{item.trunk}</td>
                          <td>{item.cores}</td>
                          <td>{item.contractor}</td>
                          <td>{item.acceptance_date}</td>
                          <td>{item.status}</td>
                          <td>{item.install_date}</td>
                          <td>{item.start_length}</td>
                          <td>{item.end_length}</td>
                          <td>{item.variance}</td>
                          <td>{item.comment}</td>
                          <td>
                            <i
                              style={{ color: "gray" }}
                              onClick={(e) => handleModal(e, item)}
                              className="fe fe-edit-2"></i>
                          </td>
                          <td>
                            <a
                              onClick={(e) => handleModal2(e, item)}
                              className="btn btn-primary btn-sm">
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    </AppContainer>
  );
}

export default ListCablesProducts;

import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import TeamContext from "../../Context/TeamContext";
import ajaxTeam from "../../util/remote/ajaxTeam";
import Select from 'react-select'

const CreateTeam=()=>{

    const [loading, setLoading] = useState(false)
    const [team_name,setTeam] = useState("")
    // const [region,setRegion] = useState("")
    // const {regionList} = useContext(RegionContext);
    const {getTeamList} = useContext(TeamContext);

    const handleAdd = async(e) =>{
        e.preventDefault()
        
        if(team_name.length>0){
            setLoading(true)
            const server_response = await ajaxTeam.createTeam(team_name);
            setLoading(false);
            if(server_response.status==="OK"){
                setTeam("")
                toast.success(server_response.message)
                getTeamList() 
            }
            else{
                toast.error(server_response.message)
            }
        }
        else{
            toast.error("Complete all fields and try again")
        }  
    }

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Team</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Register Team"
            id="model-new-team"
            size="md"
            footer={RenderFooter}
        >

            <div className="mb-4">
                <label htmlFor="">Team Name</label>
                <input onChange={(e)=>setTeam(e.target.value)} value={team_name} type="text" className="form-control"/>
            </div>

        </SystemModal>
    )
}

export default CreateTeam

import React from 'react'
import AppContainer from '../Components/Structure/AppContainer';
import CreateRegion from '../Components/Region/CreateRegion';
import { RenderSecure } from '../util/script/RenderSecure'
import { Toaster} from 'react-hot-toast';
import RegionsCard from '../Components/Region/RegionsCard'
import TeamsPerRegionChart from '../Components/Region/TeamsPerRegionChart';
import ClientsPerRegionChart from '../Components/Region/ClientsPerRegionChart';
import RegionsList from '../Components/Region/RegionsList';
import useStateCallback from '../util/customHooks/useStateCallback';

export default function RegionPage(props) {
     
    const [modal, setModal] = useStateCallback(false);
       
    const handleModal=()=>{
        setModal(false, ()=>setModal(<CreateRegion isOpen={true}/>))
    }

    return (
        <AppContainer title="Regions Dashboard">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {modal}
            <div className="container-fluid p-0 sm_padding_15px">

                <div className="row">
                    <div className="col-lg-12">
                        <h6 className="card-title mb-4 d-flex justify-content-end">
                            <button onClick={handleModal} className="btn ripple btn-primary btn-with-icon btn-sm"><i className="fe fe-plus"></i> Add New Region</button>
                        </h6>
                
                        <div className="col-md-12 mt-1">
                            <RegionsCard/>
                        </div>  
                    </div> 
                </div>

                <div className="row">

                    <RegionsList/>
                    <TeamsPerRegionChart/>
                    {/* <ClientsPerRegionChart/>   */}
              
                </div>
                     
            </div>
    
        </AppContainer>
    )
}

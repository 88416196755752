import React from 'react'

export default function Loader() {
  return (
    
                    <div className="text-center">
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
  )
}

import React, { useContext} from 'react'
import { Link } from 'react-router-dom'
import AppContainer from '../Components/Structure/AppContainer'
import IssueLogContext from '../Context/IssueLogContext'
import CreateIssueLog from '../Components/IssueLog/CreateIssueLog'
import Button from 'react-bootstrap/Button';
import { Toaster } from 'react-hot-toast';
import Loader from '../Components/Common/Loader'
import useStateCallback from '../util/customHooks/useStateCallback';
import { RenderSecure } from '../util/script/RenderSecure'
import TotalIssueLogCard from '../Components/IssueLog/TotalIssueLogsCard'
import HandledLogsCard from '../Components/IssueLog/HandledLogsCard'
import NotHandledLogsCard from '../Components/IssueLog/NotHandledLogsCard'
import TableHeader from '../Components/Common/TableHeader'

export default function IssueLogs() {

    const {issueLogList} = useContext(IssueLogContext);
    const [modal, setModal] = useStateCallback(false);
       
    const handleModal=()=>{
        setModal(false, ()=>setModal(<CreateIssueLog isOpen={true}/>))
    }

    return (
   
        <AppContainer title="Issue Logs Dashboard">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {modal}

            <div className="container-fluid p-0 sm_padding_15px">
                <div className="row">
                    <div className="col-12 col-xl-12 col-md-12 col-sm-12">
                        <h6 className="card-title mb-4 d-flex justify-content-end">
                            <button onClick={handleModal} className="btn ripple btn-primary btn-with-icon btn-sm"><i className="fe fe-plus"></i> Register issue Log</button>
                        </h6>
                
                        <div className="box card-box" style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", backgroundColor: "white", padding: "25px", borderRadius: "10px"}}>
                            <TotalIssueLogCard/>
                            <HandledLogsCard/>
                            <NotHandledLogsCard/>
                        </div>  
                    </div> 
                </div>
       

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card custom-card" style={{marginTop:"20px", borderRadius: "10px"}}>
                            <div className="card-body map-card">
                                <TableHeader
                                    title="Issue Logs List"
                                    subtitle="List of all the issue logs sorted by the most recent"    
                                />
                                <div className="border-top mt-3"></div>
                                        
                                <div className="table-responsive">
                                    <table className="table table-hover text-nowrap mg-b-0">
                                        <thead>
                                            <tr>
                                                <th>Issued At</th>
                                                <th>Log Details</th>
                                                <th>Project Affected</th>
                                                <th>Assigned To</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(issueLogList) && issueLogList.length > 0 ? (
                                                issueLogList.map((item, key) => (
                                                    <tr key={key}>
                                                    <td>{item.created_at.long_date}</td>
                                                    <td><Link to={`/issue-logs-chat/${item.id}`} > {item.log_details}</Link></td>
                                                    <td> {item.project_id.project_name}</td>
                                                    <td>{item.user_id === false ? "Not assigned" :item.user_id.first_name + " "+item.user_id.last_name}</td>
                                                        {item.status==="Not Handled" ?
                                                    <td><span className="badge bg-danger">{item.status}</span></td>:<td><span className="badge bg-success">{item.status}</span></td>}
                                                    </tr>
                                                ))
                                            ): (
                                                <tr>
                                                    <td colSpan="5" style={{textAlign:"center"}}>No issue logs registered yet.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {!issueLogList && <Loader/>}
                                </div>
                            </div>
			            </div>
					</div>
                </div>
            </div>
        </AppContainer>

    )
}

import { useState } from "react"
import { toast } from 'react-hot-toast';
import Loader from "../../Components/Common/Loader";
import SystemModal from "../../Components/Common/SystemModal";
import ajaxTask from "../../util/remote/ajaxTask";

const EndTask=(props)=>{

    const [loading, setLoading] = useState(false)

    const endTask = async(e) =>{
        e.preventDefault()
        if(props.task_id>0){
            setLoading(true)
            const server_response = await ajaxTask.endTask(props.task_id);
            setLoading(false);
            if(server_response.status==="OK"){
                toast.success(server_response.message);
                props.f(props.project_id)
                props.g(props.project_id)
            }
            else{
                toast.error(server_response.message); 
            }
        }
        else{
            toast.error("Please enter all fields!")
        }  
    }
    

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={endTask}>Confirm End Task</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="End Task"
            id="model-end-task"
            size="md"
            footer={RenderFooter}
        >

            <div className="bg-white">
			    <div className="alert text-center">
				    <i className="fe fe-alert-circle fs-50 text-warning"></i>
			        <h3 className="mt-2 mb-1">Are you sure you want to mark this task as completed?</h3>
				    <p className="mb-3 mb-3 tx-inverse">Task will be moved to the completed tasks list.</p>
				</div>
			</div>
       
        </SystemModal>
    )
}

export default EndTask

import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const UpdateOtb = (props) => {
  const [wkt_geom, setWkt_geom] = useState(props.wkt_geom);
  const [fid, setFid] = useState(props.fid);
  const [city, setCity] = useState(props.city);
  const [name, setName] = useState(props.name);
  const [template, setTemplate] = useState(props.template);
  const [pole_id, setPole_id] = useState(props.pole_id);
  const [trunk, setTrunk] = useState(props.trunk);
  const [nb_splitter, setNb_splitter] = useState(props.nb_splitter);
  const [is_derivation, setIs_derivation] = useState(props.is_derivation);
  const [cable_entree, setCable_entree] = useState(props.cable_entree);
  const [nb_splice_est_sc, setNb_splice_est_sc] = useState(
    props.nb_splice_est_sc
  );
  const [nb_splice_est_otb, setNb_splice_est_otb] = useState(
    props.nb_splice_est_otb
  );
  const [otb_desat, setOtb_desat] = useState(props.otb_desat);
  const [contractor, setContractor] = useState(props.contractor);

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(props);
    if (
      props.batch.length > 0 &&
      props.id * 1 > 0 &&
      wkt_geom.length > 0 &&
      fid.length > 0 &&
      city.length > 0 &&
      name.length > 0 &&
      template.length > 0 &&
      pole_id.length > 0 &&
      trunk.length > 0 &&
      contractor.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProject.updateOtb(
        props.otb_id,
        props.id,
        props.batch,
        wkt_geom,
        fid,
        city,
        name,
        pole_id,
        template,
        nb_splitter,
        trunk,
        is_derivation,
        cable_entree,
        nb_splice_est_sc,
        nb_splice_est_otb,
        otb_desat,
        contractor
      );

      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update Otb Details
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Otb Details"
      id="model-update-otb"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Wkt Geom</label>
        <input
          type="text"
          value={wkt_geom}
          placeholder={"wkt_geom"}
          onChange={(e) => setWkt_geom(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Fid</label>
        <input
          type="text"
          value={fid}
          placeholder={"fid"}
          onChange={(e) => setFid(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">City</label>
        <input
          type="text"
          value={city}
          placeholder={"city"}
          onChange={(e) => setCity(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Name</label>
        <input
          type="text"
          value={name}
          placeholder={"name"}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Pole ID</label>
        <input
          type="text"
          value={pole_id}
          placeholder={"pole_id"}
          onChange={(e) => setPole_id(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Template</label>
        <input
          type="text"
          value={template}
          placeholder={"template"}
          onChange={(e) => setTemplate(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">NB Splitter</label>
        <input
          type="text"
          value={nb_splitter}
          placeholder={"nb_splitter"}
          onChange={(e) => setNb_splitter(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Is Derivation</label>
        <input
          type="text"
          value={is_derivation}
          placeholder={"is_derivation"}
          onChange={(e) => setIs_derivation(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Cable Entree</label>
        <input
          type="text"
          value={cable_entree}
          placeholder={"cable_entree"}
          onChange={(e) => setCable_entree(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">nb_splice_est_sc</label>
        <input
          type="text"
          value={nb_splice_est_sc}
          placeholder={"nb_splice_est_sc"}
          onChange={(e) => setNb_splice_est_sc(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">nb_splice_est_otb</label>
        <input
          type="text"
          value={nb_splice_est_otb}
          placeholder={"nb_splice_est_otb"}
          onChange={(e) => setNb_splice_est_otb(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">otb_desat</label>
        <input
          type="text"
          value={otb_desat}
          placeholder={"otb_desat"}
          onChange={(e) => setOtb_desat(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Contractor</label>
        <input
          type="text"
          value={contractor}
          placeholder={"contractor"}
          onChange={(e) => setContractor(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateOtb;

import { useContext, useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import ajaxIssueLog from "../../util/remote/ajaxIssueLog";
import IssueLogContext from "../../Context/IssueLogContext";

const UpdateStatus=(props)=>{

    const [loading, setLoading] = useState(false)
    const {getIssueLogList} = useContext(IssueLogContext);

    const handleUpdate= async(e) =>{
        e.preventDefault()
        if(props.logID>0){
            setLoading(true)
            const server_response = await ajaxIssueLog.updateStatus(props.logID);
            setLoading(false);
            if(server_response.status==="OK"){
                
                toast.success(server_response.message) 
                getIssueLogList()
            }
        }
        else{
            toast.error("Please try again!")
        }  
    }
    

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Cancel</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleUpdate}>Confirm Update</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Update Status"
            id="model-update-status"
            size="md"
            footer={RenderFooter}
        >

            <div className="bg-white">
			    <div className="alert text-center">
				    <i className="fe fe-alert-circle fs-50 text-warning"></i>
			        <h3 className="mt-2 mb-1">Update status to <b style={{color:"green"}}>Handled</b>?</h3>
				    <p className="mb-3 mb-3 tx-inverse">Changes made to status cannot be reversed.</p>
				</div>
			</div>
	
        </SystemModal>
    )

}

export default UpdateStatus

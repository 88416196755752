import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxIssueLog from "../../util/remote/ajaxIssueLog";
import Select from "react-select";
import IssueLogContext from "../../Context/IssueLogContext";

const AssignDeveloper = (props) => {
  const [loading, setLoading] = useState(false);
  const { getIssueLogList } = useContext(IssueLogContext);
  const [developers, setDevelopers] = useState(false);
  const [user, setUser] = useState("");

  const handleAssign = async (e) => {
    e.preventDefault();
    if (props.logID > 0 || user.length > 0) {
      setLoading(true);
      const server_response = await ajaxIssueLog.assignIssueLog(
        props.logID,
        user
      );
      setLoading(false);
      if (server_response.status === "OK") {
        setUser("");
        getIssueLogList();
        toast.success(server_response.message);
      }
    } else {
      toast.error("Please select a developer!");
    }
  };

  const getDevelopers = async () => {
    const server_response = await ajaxUser.fetchDevelopers();

    if (server_response.status === "OK") {
      setDevelopers(server_response.details);
    } else {
      setDevelopers("404");
    }
  };

  useEffect(() => {
    getDevelopers();
  }, []);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAssign}>
            Assign to Developer
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Assign Issue Log"
      id="model-assign-log"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select Developer</label>
        <Select
          onChange={(e) => setUser(e.id)}
          getOptionLabel={(option) => option.first_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={developers}
          defaultValue={
            Array.isArray(developers) &&
            developers.find((value) => value.id === user)
          }
        />
      </div>
    </SystemModal>
  );
};

export default AssignDeveloper;

import { useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import ajaxPayRate from "../../util/remote/ajaxPayRate";

const CreateDeposit=(props)=>{

    const [loading,setLoading] = useState(false)
    const [amount_out,setAmount] = useState("")
    const [added_by,setAddedBy] = useState("")
    const [date_added,setDateAdded] = useState("")

    const handleAdd = async(e) =>{

        e.preventDefault()
        if(props.user_id>0 && amount_out>0){
            setLoading(true)
            const server_response = await ajaxPayRate.createDeposit(props.user_id,amount_out,added_by,date_added);
            setLoading(false) 
            if(server_response.status==="OK") {
                setAmount("")
                setAddedBy("")
                setDateAdded("")
                toast.success(server_response.message)
                props.f(props.user_id)
                props.g(props.user_id)
            }
            else{
                toast.error(server_response.message)
            }      
        }
        else{
   
            toast.error("Complete all fields and try again")
        }  
    }

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAdd}>Save Deposit</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Add New Payment Deposit"
            id="model-new-paydeposit"
            size="md"
            footer={RenderFooter}
        >
        
       
           
            <div className="mb-4">
                <label htmlFor="">Amount</label>
                <input onChange={(e)=>setAmount(e.target.value)} value={amount_out} type="number" className="form-control"/>
            </div>
            <div className="mb-4">
                <label htmlFor="">Added By</label>
                <input onChange={(e)=>setAddedBy(e.target.value)} value={added_by} type="text" className="form-control"/>
            </div>
            <div className="mb-4">
                <label htmlFor="">Date Paid</label>
                <input onChange={(e)=>setDateAdded(e.target.value)} value={date_added} type="date" className="form-control"/>
            </div>


        </SystemModal>
    )
}

export default CreateDeposit
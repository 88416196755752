import { ajax } from 'jquery'
import React from 'react'

export default function PageHeader(props) {


    
    
//     const [isActive,setActive] = useState(false)
//     const [searchResults,setSearchResults] = useState(false)
//     const [startDate,setStartDate] = useState('')
//     const [endDate,setEndDate] = useState('')

//     const searchTenantByDate = async(e) =>{
//         e.preventDefault()

//         const server_response = await ajaxTenant.searchTenantDate(startDate,endDate);
//         if(server_response.status==="OK"){
//             setSearchResults()
//         }
//     }


//     const exportToggle = (e) =>{
//         e.preventDefault()
//         const labelAria = document.getElementById("exportToggle");
//         if(e.target.parentElement.classList.contains("show") 
//         && e.target.nextSibling.classList.contains("show")){
//         e.target.setAttribute('aria-expanded', 'false');
//         e.target.parentElement.classList.remove("show")
//         e.target.nextSibling.classList.remove("show")

//     }
//     else{
//         e.target.setAttribute('aria-expanded', 'true');
//         e.target.parentElement.classList.add("show")
//         e.target.nextSibling.classList.add("show")
//     }
// }

//     const filterToggle = (e) =>{
//         e.preventDefault()
    
//         const labelAria = document.getElementById("filterToggle");
     
//         if(labelAria.getAttribute('aria-expanded')==='false'){
//             if(e.target.classList.contains("collapsed")){
//                 e.target.classList.remove("collapsed")
//                 e.target.setAttribute('aria-expanded','true')
//                 setActive(true)
//             }
//             else{
//                 e.target.setAttribute('aria-expanded','true')
//                 setActive(true)
//             }
  
//         }
//         else{
//         e.target.setAttribute('aria-expanded','false')
//         e.target.classList.add("collapsed")
//         setActive(false)
//         }

//     }


  return (
    <>
    <div className="page-header">
        <div>
            <h2 className="main-content-title tx-24 mg-b-5">{props.title}</h2>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
            </ol>
        </div>
        
    </div>
    
</>
  )
}

import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async createStore(data) {
    let response = await apiCall("store/create", data);
    return response;
  },
  async updateTask(data) {
    let response = await apiCall("store/update", data);
    return response;
  },
  async fetchStores() {
    let response = await apiCall("store/list");
    return response;
  },
};

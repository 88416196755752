import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RenderSecure } from "../util/script/RenderSecure";
import { Toaster, toast } from "react-hot-toast";
import ajaxTeam from "../util/remote/ajaxTeam";
import TableHeader from "../Components/Common/TableHeader";
import useStateCallback from "../util/customHooks/useStateCallback";
import Loader from "../Components/Common/Loader";
import ajaxUser from "../util/remote/ajaxUser";
import ajaxTask from "../util/remote/ajaxTask";
import CreateTaskAllocation from "../Components/Team/CreateTaskAllocation";
import CreateTeamAllocation from "../Components/Team/CreateTeamAllocation";
import ajaxProduct from "../util/remote/ajaxProduct";
import CreateTeamProductAllocation from "../Components/Team/CreateTeamProductAllocation";
import CreateOutput from "./DailyOutput/CreateOutput";
import Rollcall from "./Tasks/Rollcall";
import ReturnItem from "../Components/Team/ReturnItem";
import AddTeamPhoto from "../Components/Team/AddTeamPhoto";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

const TeamProfile = (props) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [modal, setModal] = useStateCallback(false);
  const [teamMembers, setTeamMembers] = useState(false);
  const [tasksList, setTasksList] = useState(false);
  const [allTasksList, setAllTasksList] = useState(false);
  const [teamProfile, setTeamProfile] = useState(false);
  const [teamProducts, setTeamProducts] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamID, setTeamID] = useState("");
  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);
  const navigation = useNavigate();
  useEffect(() => {
    getTasksList(id);
    getTeamProfile(id);
    getTeamProducts(id);
    getAllTasksList(id);
  }, [id]);

  useEffect(() => {
    getTeamMembers();
  }, [id]);

  const getTasksList = async (id) => {
    setLoading2(true);
    const server_response = await ajaxTask.fetchTasksPerTeam(id);
    setLoading2(false);
    if (server_response.status === "OK") {
      setTasksList(server_response.details);
    } else {
      setTasksList("404");
    }
  };

  const getAllTasksList = async (id) => {
    setLoading4(true);
    const server_response = await ajaxTask.fetchAllTasksPerTeam(id);
    setLoading4(false);
    if (server_response.status === "OK") {
      setAllTasksList(server_response.details);
    } else {
      setAllTasksList("404");
    }
  };

  const getTeamProfile = async (id) => {
    setLoading(true);
    const server_response = await ajaxTeam.fetchTeamProfile(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setTeamProfile(server_response.details);
    } else {
      setTeamProfile("404");
    }
  };

  const getTeamProducts = async (id) => {
    setLoading3(true);
    const server_response = await ajaxProduct.fetchTeamProductAllocationList(
      id
    );
    setLoading3(false);
    if (server_response.status === "OK") {
      setTeamProducts(server_response.details);
    } else {
      setTeamProducts("404");
    }
  };

  const getTeamMembers = async () => {
    setLoading1(true);
    const server_response = await ajaxUser.fetchTeamMembers(id);
    setLoading1(false);
    if (server_response.status === "OK") {
      setTeamMembers(server_response.details);
    } else {
      setTeamMembers("404");
    }
  };

  const exportMembersPDF = () => {
    const table = document.getElementById("first"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");

    // Define columns for the table (add more if needed)
    const columns = ["Name", "Role", "District"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("members_data.pdf");
  };

  const exportTasksPDF = () => {
    const table = document.getElementById("second"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");

    // Define columns for the table (add more if needed)
    const columns = [
      "Task Name",
      "Project",
      "Site",
      "Start Date",
      "Achieved Work",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("tasks_data.pdf");
  };

  const exportAllTasksPDF = () => {
    const table = document.getElementById("seventh"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");

    // Define columns for the table (add more if needed)
    const columns = [
      "Task Name",
      "Project",
      "Site",
      "Start Date",
      "Achieved Work",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("all_tasks_data.pdf");
  };

  const exportToolsPDF = () => {
    const table = document.getElementById("third"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");

    // Define columns for the table (add more if needed)
    const columns = [
      "Tool Name",
      "Quantity Allocated",
      "Date Allocated",
      "Quantity Returned",
      "Date Returned",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("tools_data.pdf");
  };

  const exportPaymentsPDF = () => {
    const table = document.getElementById("fourth"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");

    // Define columns for the table (add more if needed)
    const columns = [
      "Name",
      "Phone Number",
      "Debit Sum",
      "Credit Sum",
      "Balance",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("payments_data.pdf");
  };

  const exportPaymentsExcel = () => {
    // Prepare data for export
    const data = teamMembers.map((item, index) => ({
      "First Name": item.user.first_name,
      "Last Name": item.user.first_name,
      "Phone Number": item.user.contact,
      "Debit Sum": item.user.debit_sum?.debit_sum,
      "Credit Sum": item.user.credit_sum?.credit_sum,
      Balance: item.user.balance?.balance,
    }));

    // Create a worksheet from your table data
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payment Data");

    // Generate a file name
    const fileName = "payment_data.xlsx";

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  const handleModalRollCall = (e, output, team_name) => {
    e.preventDefault();

    setModal(false, () =>
      setModal(
        <Rollcall
          teamID={id}
          team_name={team_name}
          output={output}
          navigation={navigation}
          isOpen={true}
          getTasksList={getTasksList}
        />
      )
    );
  };

  const setTeamUpdate = () => {
    handleActive();
    setTeamID(teamProfile.team_id);
    setTeamName(teamProfile.team_name);
  };

  const handleModal2 = () => {
    setModal(false, () =>
      setModal(
        <CreateTaskAllocation team_id={id} f={getTasksList} isOpen={true} />
      )
    );
  };
  const handleModal3 = () => {
    setModal(false, () =>
      setModal(
        <CreateTeamAllocation team_id={id} f={getTeamMembers} isOpen={true} />
      )
    );
  };
  const handleModal4 = () => {
    setModal(false, () =>
      setModal(
        <CreateTeamProductAllocation
          team_id={id}
          f={getTeamProducts}
          isOpen={true}
        />
      )
    );
  };
  const handleModal5 = (e, item) => {
    setModal(false, () =>
      setModal(
        <CreateOutput
          output_id={item.output_id}
          team_id={id}
          f={getTasksList}
          isOpen={true}
        />
      )
    );
  };
  const handleModal6 = (e, item) => {
    setModal(false, () =>
      setModal(
        <ReturnItem
          id={item.allocation_id}
          team_id={id}
          f={getTeamProducts}
          isOpen={true}
        />
      )
    );
  };
  const handleModal8 = (e, item) => {
    setModal(false, () =>
      setModal(
        <AddTeamPhoto
          output_id={item.output_id}
          team_id={id}
          f={getTasksList}
          isOpen={true}
        />
      )
    );
  };

  const handleTeamUpdate = async (e) => {
    e.preventDefault();

    if (teamName.length > 0) {
      const server_response = await ajaxTeam.updateTeam(teamID, teamName);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getTeamProfile(id);
      }
    } else {
      toast.error("Complete all fields and try again!!");
    }
  };

  const removerUser = async (e, item) => {
    const server_response = await ajaxUser.removeUser(item, id);
    if (server_response.status === "OK") {
      toast.success(server_response.message);
      getTeamMembers();
    }
  };

  const removeTask = async (e, item) => {
    const server_response = await ajaxTask.removeTask(item, id);
    if (server_response.status === "OK") {
      toast.success(server_response.message);
      getTasksList();
    }
  };

  const calculateTotal = (column) => {
    let total = 0;
    if (Array.isArray(teamMembers) && teamMembers.length > 0) {
      teamMembers.forEach((item) => {
        const keys = column.split(".");
        let value = item;
        keys.forEach((key) => {
          value = value?.[key];
        });
        total += parseFloat(value) || 0;
      });
    }
    return total;
  };

  const totalDebit = calculateTotal("debit_sum.debit_sum");
  const totalCredit = calculateTotal("credit_sum.credit_sum");

  return (
    <AppContainer title={"Team Profile"}>
      <Toaster position="top-center" reverseOrder={false} />

      {modal}

      <div className="col-12 col-xl-12">
        <div
          className="box user-pro-list overflow-hidden mb-30"
          style={{
            marginBottom: "30px",
            backgroundColor: "white",
            padding: "25px",
            boxShadow: "10px",
            borderRadius: "10px",
          }}>
          {teamProfile && (
            <div className="box-body" style={{ position: "relative" }}>
              <div className="user-pic text-center">
                <div
                  className="pro-user mt-3"
                  style={{ marginTop: "1rem !important" }}>
                  <h5
                    className="pro-user-username text-dark mb-2 fs-30 mt-42 color-span"
                    style={{ lineHeight: "1.5" }}>
                    {teamProfile.team_name}
                  </h5>
                </div>
              </div>
            </div>
          )}

          <RenderSecure code="EDIT-TEAM">
            <div
              className="box-footer pt-41"
              style={{ paddingTop: "41px !important" }}>
              <div className="btn-list text-center">
                {active ? (
                  <a
                    href="#"
                    onClick={handleInActive}
                    className="btn ripple btn-danger btn-sm mr-2">
                    <i className="fe fe-x"></i>Back
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={setTeamUpdate}
                    className="btn ripple btn-warning btn-sm mr-2">
                    <i className="far fa-edit mr-1"></i>Update Details
                  </a>
                )}
              </div>
            </div>
          </RenderSecure>
        </div>

        {active ? (
          <RenderSecure code="ADD-TEAM">
            <div
              className="box left-dot mb-30"
              style={{
                marginBottom: "30px",
                backgroundColor: "white",
                padding: "25px",
                boxShadow: "10px",
                borderRadius: "10px",
              }}>
              <div className="box-header  border-0 pd-0">
                <div className="box-title fs-20 font-w600">
                  {" "}
                  Update Team Details
                </div>
              </div>
              <br />
              <div className="box-body pt-20 user-profile">
                <form onSubmit={handleTeamUpdate}>
                  <div className="form-group">
                    <div className="row row-sm">
                      <div className="col-sm-6">
                        <label htmlFor="">Team Name:</label>
                        <input
                          type="text"
                          defaultValue={teamName}
                          onChange={(e) => setTeamName(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <button className="btn ripple btn-main-primary btn-block">
                    Update Details
                  </button>
                </form>
              </div>
            </div>
          </RenderSecure>
        ) : (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">Team Information</div>
            </div>
            <div className="box-body pt-20 user-profile">
              <div className="table-responsive">
                <table className="table mb-0 mw-100 color-span">
                  {teamProfile && (
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Team Name </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{teamProfile.team_name}</span>{" "}
                        </td>
                      </tr>

                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Creation Date</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {teamProfile.created_at.short_date}
                          </span>{" "}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                {loading && <Loader />}
              </div>
            </div>
          </div>
        )}

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Team Members"
              subtitle="List of all the members assigned to this team"
              viewButton={
                <RenderSecure code="ASSIGN-TEAM">
                  <a
                    href="#"
                    onClick={handleModal3}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Add Member
                  </a>
                  <a
                    href="#"
                    onClick={exportMembersPDF}
                    className="btn btn-secondary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to PDF
                  </a>
                </RenderSecure>
              }
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table
                className="table table-hover text-nowrap mg-b-0"
                id="first">
                <thead>
                  <tr>
                    <th scope="col">No.</th>

                    <th scope="col">Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">District</th>
                    <th scope="col">Actions</th>
                    {/* <th scope="col">Clients Registered</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamMembers) && teamMembers.length > 0 ? (
                    teamMembers.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          <span>
                            <Link to={`../users/user-profile/${item.user_id}`}>
                              {item.User_name}
                            </Link>
                          </span>
                        </td>
                        <td> {item.role}</td>
                        <td> {item.district} </td>

                        <RenderSecure code="DROP-FROM-TEAM">
                          <td>
                            {" "}
                            <button
                              onClick={(e) => removerUser(e, item.user_id)}
                              className="btn btn-danger btn-sm">
                              Delete
                            </button>{" "}
                          </td>
                        </RenderSecure>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No members assigned to this team yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {loading1 && <Loader />}
            </div>
          </div>
        </div>

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Team Tasks"
              viewButton={
                <RenderSecure code="ASSIGN-TASK">
                  <a
                    href="#"
                    onClick={handleModal2}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Assign Task
                  </a>
                  <a
                    href="#"
                    onClick={exportAllTasksPDF}
                    className="btn btn-secondary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to PDF
                  </a>
                </RenderSecure>
              }
            />

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row mb-1">
                    <Nav.Item>
                      <Nav.Link size="sm" eventKey="first">
                        Today's Tasks{" "}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link size="sm" eventKey="second">
                        All Tasks{" "}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="border-top mt-1"></div>
                      <div className="table-responsive">
                        <table
                          className="table table-hover text-nowrap mg-b-0"
                          id="second">
                          <thead>
                            <tr>
                              <th scope="col">No.</th>

                              <th scope="col"> Task Name</th>
                              <th scope="col"> Project</th>
                              <th scope="col"> Site</th>
                              <th scope="col"> Start Date</th>
                              <th scope="col"> Achieved Work</th>
                              <th scope="col"> HSE Report</th>
                              <th scope="col"> Team Photo</th>
                              <th scope="col"> Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(tasksList) &&
                            tasksList.length > 0 ? (
                              tasksList.map((item, key) => (
                                <tr key={key}>
                                  <th scope="row">{key + 1}</th>
                                  <td>{item.task_name}</td>
                                  <td>{item.project_name}</td>
                                  <td>{item.site}</td>
                                  <td>{item.task_date?.short_date}</td>
                                  <td>
                                    {item.achieved_work
                                      ? item.achieved_work
                                      : "Not yet recorded"}
                                  </td>
                                  <td>
                                    {item.hse_report ? (
                                      <span>
                                        <Link
                                          to={`hse-report/view/${item.task_allocation_id}`}>
                                          {" "}
                                          View HSE Report
                                        </Link>
                                      </span>
                                    ) : (
                                      "Not yet submitted"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <img
                                      alt="Team Photo"
                                      style={{ width: "100%" }}
                                      class="img-thumbnail"
                                      src={
                                        item.team_photo
                                          ? item.team_photo
                                          : "Not yet submitted"
                                      }
                                    />
                                  </td>
                                  <td style={{ display: "flex" }}>
                                    <Link
                                      className="btn btn-sm btn-info mr-2"
                                      href="#"
                                      onClick={(e) => handleModal5(e, item)}>
                                      Record Output
                                    </Link>
                                    <Link
                                      className="btn btn-sm btn-info mr-2"
                                      href="#"
                                      onClick={(e) => handleModal8(e, item)}>
                                      Add Team Photo
                                    </Link>

                                    {!Array.isArray(item.rollcall) && (
                                      <Link
                                        className="btn btn-success btn-sm mr-5"
                                        to=""
                                        onClick={(e) =>
                                          handleModalRollCall(
                                            e,
                                            item.output_id,
                                            item.task_name
                                          )
                                        }>
                                        <i
                                          className="fe fe-user-check mr-1"
                                          style={{
                                            color: "white",
                                            fontSize: 16,
                                          }}></i>
                                        Rollcall
                                      </Link>
                                    )}

                                    {
                                      Array.isArray(item.rollcall) && (
                                        // !Array.isArray(item.output?.payment) && (
                                        <Link
                                          className="btn btn-secondary btn-sm mr-2"
                                          style={{ width: 120, color: "white" }}
                                          to={`pay/${item.output_id}`}>
                                          <i
                                            className="fe fe-check-circle"
                                            style={{ color: "white" }}></i>{" "}
                                          Make Payment
                                        </Link>
                                      )
                                      // )
                                    }
                                    {!item.hse_report && (
                                      <Link
                                        className="btn btn-sm btn-info mr-2"
                                        to={`hse-report/${item.task_allocation_id}`}>
                                        Add HSE Report
                                      </Link>
                                    )}

                                    {/* {Array.isArray(item.output?.rollcall) && ""}
                                    {Array.isArray(item.output?.payment) && ""} */}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>
                                  No tasks assigned to this team today.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {loading2 && <Loader />}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <div className="border-top mt-1"></div>
                      <div className="table-responsive">
                        <table
                          className="table table-hover text-nowrap mg-b-0"
                          id="seventh">
                          <thead>
                            <tr>
                              <th scope="col">No.</th>

                              <th scope="col"> Task Name</th>
                              <th scope="col"> Project</th>
                              <th scope="col"> Site</th>
                              <th scope="col"> Start Date</th>
                              <th scope="col"> Achieved Work</th>
                              <th scope="col"> HSE Report</th>
                              <th scope="col"> Team Photo</th>
                              <th scope="col"> Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(allTasksList) &&
                            allTasksList.length > 0 ? (
                              allTasksList.map((item, key) => (
                                <tr key={key}>
                                  <th scope="row">{key + 1}</th>
                                  <td>{item.task_name}</td>
                                  <td>{item.project_name}</td>
                                  <td>{item.site}</td>
                                  <td>{item.task_date?.short_date}</td>
                                  <td>
                                    {item.achieved_work
                                      ? item.achieved_work
                                      : "Not yet recorded"}
                                  </td>
                                  <td>
                                    {item.hse_report ? (
                                      <span>
                                        <Link
                                          to={`hse-report/view/${item.task_allocation_id}`}>
                                          {" "}
                                          View HSE Report
                                        </Link>
                                      </span>
                                    ) : (
                                      "Not yet submitted"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <img
                                      alt="Team Photo"
                                      style={{ width: "100%" }}
                                      class="img-thumbnail"
                                      src={
                                        item.team_photo
                                          ? item.team_photo
                                          : "Not yet submitted"
                                      }
                                    />
                                  </td>
                                  <td style={{ display: "flex" }}>
                                    <Link
                                      className="btn btn-sm btn-info mr-2"
                                      href="#"
                                      onClick={(e) => handleModal5(e, item)}>
                                      Record Output
                                    </Link>
                                    <Link
                                      className="btn btn-sm btn-info mr-2"
                                      href="#"
                                      onClick={(e) => handleModal8(e, item)}>
                                      Add Team Photo
                                    </Link>

                                    {!Array.isArray(item.rollcall) && (
                                      <Link
                                        className="btn btn-success btn-sm mr-5"
                                        to=""
                                        onClick={(e) =>
                                          handleModalRollCall(
                                            e,
                                            item.output_id,
                                            item.task_name
                                          )
                                        }>
                                        <i
                                          className="fe fe-user-check mr-1"
                                          style={{
                                            color: "white",
                                            fontSize: 16,
                                          }}></i>
                                        Rollcall
                                      </Link>
                                    )}

                                    {Array.isArray(item.rollcall) &&
                                      !Array.isArray(item.output) && (
                                        <Link
                                          className="btn btn-secondary btn-sm mr-2"
                                          style={{ width: 120, color: "white" }}
                                          to={`pay/${item.output_id}`}>
                                          <i
                                            className="fe fe-check-circle"
                                            style={{ color: "white" }}></i>{" "}
                                          Make Payment
                                        </Link>
                                      )}
                                    {!item.hse_report && (
                                      <Link
                                        className="btn btn-sm btn-info mr-2"
                                        to={`hse-report/${item.task_allocation_id}`}>
                                        Add HSE Report
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>
                                  No tasks assigned to this team yet.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {loading4 && <Loader />}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Team Tools"
              subtitle="List of all the tools allocated"
              viewButton={
                <RenderSecure code="ASSIGN-TOOL">
                  <a
                    href="#"
                    onClick={handleModal4}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Allocate Tool
                  </a>
                  <a
                    href="#"
                    onClick={exportToolsPDF}
                    className="btn btn-secondary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to PDF
                  </a>
                </RenderSecure>
              }
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table
                className="table table-hover text-nowrap mg-b-0"
                id="third">
                <thead>
                  <tr>
                    <th scope="col">No.</th>

                    <th scope="col"> Tool Name</th>
                    <th scope="col"> Task</th>
                    <th scope="col">Quantity Allocated</th>
                    <th scope="col">Date Allocated</th>
                    <th scope="col">Quantity Returned</th>
                    <th scope="col">Date Returned</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamProducts) && teamProducts.length > 0 ? (
                    teamProducts.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{item.product}</td>
                        <td>{item.task}</td>
                        <td>{item.quantity}</td>
                        <td>{item.date_allocated}</td>
                        <td>
                          {item.quantity_returned
                            ? item.quantity_returned
                            : "Still in use"}
                        </td>
                        <td>
                          {item.date_returned
                            ? item.date_returned
                            : "Still in use"}
                        </td>
                        <td>
                          <Link
                            className="btn btn-sm btn-info mr-2"
                            href="#"
                            onClick={(e) => handleModal6(e, item)}>
                            Return Tool
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No tools allocated to this team yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {loading3 && <Loader />}
            </div>
          </div>
        </div>

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Team Payments"
              viewButton={
                <>
                  <a
                    href="#"
                    onClick={exportPaymentsExcel}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Export to Excel
                  </a>
                  <a
                    href="#"
                    onClick={exportPaymentsPDF}
                    className="btn btn-secondary btn-sm mr-2"
                    style={{ float: "right" }}>
                    Export to PDF
                  </a>
                </>
              }
            />
            <div className="border-top mt-4"></div>
            <div className="table-responsive">
              <table
                className="table table-hover text-nowrap mg-b-0"
                id="fourth">
                <thead>
                  <tr>
                    <th scope="col">No.</th>

                    <th scope="col">Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Debit Sum</th>
                    <th scope="col">Credit Sum</th>

                    <th scope="col">Balance</th>
                    {/* <th scope="col">Clients Registered</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamMembers) && teamMembers.length > 0 ? (
                    teamMembers.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          {" "}
                          <span>
                            <Link to={`../users/user-profile/${item.user_id}`}>
                              {" "}
                              {item.User_name}
                            </Link>
                          </span>
                        </td>
                        <td>{item.contact}</td>
                        <td> {item.debit_sum.debit_sum_c}</td>
                        <td> {item.credit_sum.credit_sum_c}</td>

                        <td> {item.balance.balance_c}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No members assigned to this team yet.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="3">
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{totalDebit}</strong>
                    </td>
                    <td>
                      <strong>{totalCredit}</strong>
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              </table>
              {loading1 && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default TeamProfile;

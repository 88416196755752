import React from 'react'
import AppContainer from '../Components/Structure/AppContainer';
import { Link } from 'react-router-dom';
import { Toaster} from 'react-hot-toast';
import TeamPayments from '../Components/Team/TeamPayments';

export default function TeamPaymentsPage(props) {

    return (
        <AppContainer title="Team Payments">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
           
            <div className="container-fluid p-0 sm_padding_15px">


                <div className="row">
                    <TeamPayments/>
              
                </div>
                     
            </div>
    
        </AppContainer>
    )
}


import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Toaster } from "react-hot-toast";
import useStateCallback from "../util/customHooks/useStateCallback";
import CreateStores from "./CreateStores";
import StoresList from "./StoresList";

const Stores = (props) => {
  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () => setModal(<CreateStores isOpen={true} />));
  };

  return (
    <AppContainer title={"Stores  View"}>
      <Toaster position="top-center" reverseOrder={false} />
      {modal}

      {/* First row displaing the cards start */}
      <div className="container-fluid p-0 sm_padding_15px">
        <div
          className="row"
          style={{ marginright: "-5px", marginLeft: "-5px" }}>
          <div
            className="col-12 col-xl-12 col-md-12 col-sm-12"
            style={{ marginBottom: "40px" }}>
            <h6 className="card-title mb-4 d-flex justify-content-end">
              <button
                onClick={handleModal}
                style={{
                  borderRadius: "10px",
                }}
                className="btn ripple btn-primary btn-with-icon btn-sm">
                <i className="fe fe-plus"></i> Add store
              </button>
            </h6>
          </div>
        </div>
      </div>
      {/* First row displaing the cards ends */}

      {/* Second row displaing the list of projects start*/}
      <div className="container-fluid p-3 sm_padding_15px">
        <StoresList />
      </div>
      {/* Second row displaing the list of projects ends */}
    </AppContainer>
  );
};

export default Stores;

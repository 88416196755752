import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ajaxStores from "../util/remote/ajaxStores";
import Loader from "../Components/Common/Loader";
export default function StoresList() {
  const [store, setStores] = useState("");
  useEffect(() => {
    fetch_store();
  }, []);

  const fetch_store = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    } else {
      setStores("404");
    }
  };
  return (
    <div className="row" style={{ marginright: "-5px", marginLeft: "-5px" }}>
      {Array.isArray(store) &&
        store.map((item, key) => (
          <div className="col-lg-4 col-md-4" key={key}>
            <div
              className="card"
              style={{
                transition: "all 0.5s ease-in-out",
                borderRadius: ".55rem",
                borderColor: "#E6E9ED",
                position: "relative",
                width: "100%",
                marginBottom: "10px",
              }}>
              <div
                className="card-header"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  alignItems: "center",
                  borderColor: "#E6E9ED",
                }}>
                <h3
                  className="card-title col-12"
                  style={{
                    fontSize: "20px",
                    marginBottom: "0",
                    fontWeight: "500",
                    lineHeight: "1.2",
                  }}>
                  {item.storeName}
                </h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-5 py-1">
                    <strong>Store Location:</strong>
                  </div>
                  <div className="col-7 py-1">{item.storeLocation}</div>
                  <div className="col-5 py-1">
                    <strong>Manager:</strong>
                  </div>
                  <div className="col-7 py-1">{item.manager}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {!store && <Loader />}
    </div>
  );
}

import { useState } from "react";
import SystemModal from "../../Components/Common/SystemModal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../../Components/Common/Loader";
import ajaxProject from "../../util/remote/ajaxProject";

const ProjectChangeStatusModel = (props) => {
  const [projectID, setProjectID] = useState(props.project_id);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handle_submission = async (e) => {
    e.preventDefault();
    // Form validation
    if (status.trim() === "") {
      toast.error("Please the status is required.");
    } else {
      setLoading(true);
      var data = {
        projectID: projectID,
        status: status,
      };
      try {
        const server_response = await ajaxProject.UpadetProjectStatus(data);

        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          setStatus("");
        } else {
          toast.error(server_response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className="btn ripple btn-success"
            onClick={(e) => handle_submission(e)}>
            Change status
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Select Product To view (Complete Installations)"
      id="model-update-task"
      size="md"
      footer={RenderFooter}
      style={{
        background: "linear-gradient(135deg, #d3d3d3, #e0c3fc, #fcb69f)",
        borderRadius: "20px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}>
      <div className="mb-4">
        <label>Select Project</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          class="form-control">
          <option selected disabled value="">
            select status
          </option>
          <option value="1">complete</option>
          <option value="0">on going</option>
        </select>
      </div>
    </SystemModal>
  );
};

export default ProjectChangeStatusModel;

import React, { Component } from 'react';
import Chart from "react-apexcharts";
import Loader from "../Common/Loader";
import ajaxRegion from '../../util/remote/ajaxRegion';

class TeamsPerRegionChart extends Component {

  state={

    testing:false,
    statData:false,
    series: false,
    options: false
  }

  componentDidMount() {
    this.getRegions()
  }

  setSeries = (teams) =>{
    this.setState({
      series:
        teams 
    })
  }


  setOptions = (regions)=>{
    this.setState({
      options:{
        chart: {
            width: 380,
            type: 'pie',
        },
        
        labels: regions,

        responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
        }]
        },

    })
  }
  
  getRegions=async() =>{
    const server_response = await ajaxRegion.getRegionList()
    if(server_response.status === 'OK'){
      this.setState({
        statData:server_response.details
      },()=>{
        const data = this.state.statData
        const regions= []
        const teams = []
        Object.keys(data).map((index, key)=>{
          regions.push(data[index].region_name)
        })
        Object.keys(data).map((index, key)=>{
          teams.push(data[index].teams_assigned.total*1)
        })
        this.setOptions(regions)
        this.setSeries(teams)
        }
      )

    }
  }

  render() {
    return (
      <div className="col-lg-6">
        <div className="card custom-card overflow-hidden" style={{borderRadius: "10px"}}>
          <div className="card-body">
            <div className="card-option d-flex">
              <div>
                <h6 className="card-title mb-1">Teams Per Region</h6>
                <p className="text-muted card-sub-title">Percentage of teams working within each region</p>
              </div>
              <div className="card-option-title ml-auto">
                <div className="btn-group p-0">
                  <button className="btn btn-light btn-sm" type="button">This Year</button>
                </div>
              </div>
            </div>
            <div>

              {this.state.statData && this.state.options && this.state.series && <Chart
                                                                                    options={this.state.options}
                                                                                    series={this.state.series}
                                                                                    type="pie"
                                                                                    height={700}
                                                                                  /> }
              {!this.state.statData && <Loader/>}

            </div>
          </div>
        </div>
      </div>
        
    );
  }
}

export default TeamsPerRegionChart;
import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxTask from "../../util/remote/ajaxTask";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

function AddTask() {
  const { id } = useParams();
  const [taskName, setTaskName] = useState("");
  const [taskList, setTaskList] = useState(false);
  const [dailyExpectation, setDailyExpectation] = useState("");
  const [duration, setDuration] = useState("");
  const [unit_m, setUnit_measure] = useState("");
  const [total, setTotal] = useState("");
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);

  const AddTaskOption = {
    task_id: "add_new",
    task_name: "- - - Register New Task - - -",
  };

  const handleAddTask = async (e) => {
    e.preventDefault();
    if (
      id > 0 &&
      taskName.length > 0 &&
      dailyExpectation.length > 0 &&
      duration.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxTask.createTask(
        id,
        taskName,
        duration,
        dailyExpectation,
        total,
        unit_m
      );

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        setTimeout(() => {
          setLoading(false);
          navigation(-1);
        }, 1000);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const getMainTasks = async () => {
    const server_response = await ajaxTask.getMainTasksList();

    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  useEffect(() => {
    getMainTasks();
  }, []);

  return (
    <AppContainer title="Add new task">
      <Toaster />
      <Link
        onClick={() => navigation(-1)}
        className="btn btn-primary btn-sm mb-2">
        Back To Previous Page
      </Link>
      <div className="card custom-card">
        <div className="card-body">
          <div>
            <h6 className="card-title mb-4">Add New Task</h6>
          </div>
          <form onSubmit={(e) => handleAddTask(e)} method="post">
            <div className="row">
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Task Name:<span className="tx-danger">*</span>
                  <i
                    class="fe fe-refresh-cw ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={getMainTasks}></i>
                </label>
                <Select
                  onChange={(selectedOption) => {
                    if (selectedOption.task_id === "add_new") {
                      window.open("/task/register", "_blank");
                    } else {
                      setTaskName(selectedOption.task_name);
                    }
                  }}
                  getOptionLabel={(option) => option.task_name}
                  getOptionValue={(option) => option.task_name}
                  isSearchable
                  options={
                    Array.isArray(taskList) ? [...taskList, AddTaskOption] : []
                  }
                  value={
                    Array.isArray(taskList) &&
                    taskList.find((value) => value.task_name === taskName)
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Number of days/Duration:<span className="tx-danger">*</span>
                </label>
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Daily Expectation:<span className="tx-danger">*</span>
                </label>
                <input
                  type="number"
                  value={dailyExpectation}
                  onChange={(e) => setDailyExpectation(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Total:<span className="tx-danger">*</span>
                </label>
                <input
                  type="number"
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Unit of Measure:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={unit_m}
                  onChange={(e) => setUnit_measure(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                {loading && (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-success">
                    Submitting
                  </button>
                )}
                {!loading && (
                  <button style={{ width: "100%" }} className="btn btn-success">
                    Add Task
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppContainer>
  );
}

export default AddTask;

import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProduct from "../../util/remote/ajaxProduct";
import ajaxStores from "../../util/remote/ajaxStores";
import Select from "react-select";
import AuthContext from "../../Context/AuthContext";

const ReturnUserItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [quantity_returned, setQuantityReturned] = useState("");
  const [date_returned, setDateReturned] = useState("");

  const { userId } = useContext(AuthContext);
  const [storeID, setStoreiD] = useState("");
  const [stores, setStores] = useState("");
  useEffect(() => {
    fetch_store();
  }, []);

  const fetch_store = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    } else {
      setStores("404");
    }
  };

  const handleReturn = async (e) => {
    e.preventDefault();

    if (props.id > 0 && quantity_returned > 0) {
      setLoading(true);
      let data = {
        id: props.id,
        quantity_returned: quantity_returned,
        date_returned: date_returned,
        store_id: storeID,
        returned_by: userId,
      };
      const server_response = await ajaxProduct.returnUserTool(data);
      setLoading(false);

      if (server_response.status === "OK") {
        setQuantityReturned("");
        setDateReturned("");
        toast.success(server_response.message);
        props.f(props.user_id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleReturn}>
            Return Tool
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Return Tool"
      id="model-return-user-tool"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Quantity Returned</label>
        <input
          onChange={(e) => setQuantityReturned(e.target.value)}
          value={quantity_returned}
          type="number"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Date Returned</label>
        <input
          onChange={(e) => setDateReturned(e.target.value)}
          value={date_returned}
          type="date"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">
          Store:<span className="tx-danger">*</span>
        </label>
        <Select
          onChange={(e) => setStoreiD(e.id)}
          getOptionLabel={(option) => option.storeName}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(stores) ? stores : []}
          value={
            Array.isArray(stores) &&
            stores.find((value) => value.id === storeID)
          }
        />
      </div>
    </SystemModal>
  );
};

export default ReturnUserItem;

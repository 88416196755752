import React, { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast';
import ajaxTask from '../../util/remote/ajaxTask';
import AppContainer from '../../Components/Structure/AppContainer';

function RegisterMainTask() {

    const [taskName,setTaskName] = useState("")
    const [loading,setLoading] = useState(false)

    const handleAdd = async (e) => {
        e.preventDefault();
      
        if (taskName.length > 0) {
          setLoading(true);
          const server_response = await ajaxTask.createMainTask(taskName);
          setLoading(false);
      
          if (server_response.status === 'OK') {
            setTaskName('');
            toast.success(server_response.message);
      
            // Introduce a delay of 2 seconds before closing the tab
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            toast.error(server_response.message);
          }
        } else {
          toast.error('Complete all fields and try again');
        }
      };

  return (
    <AppContainer title="Register New Task">

      <Toaster
        position="top-center"
        reverseOrder={false}
      />
            
      <div className="row">
			  <div className="col-lg-12 col-md-12">
          <div className="card custom-card" style={{borderRadius: "10px"}}>
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4">Register New Task</h6>
              </div>

              <form onSubmit={(e)=>handleAdd(e)} method="post">
                <div className="row">
                  <div className="mb-4 col-md-12">
                    <label htmlFor="">Task Name:<span className="tx-danger">*</span></label>
                    <input type="text" value={taskName} onChange={(e)=>setTaskName(e.target.value)} className="form-control"/>
                  </div>
                                    
                </div>
                <div className="mb-4">
                  {loading && (<input type="submit" style={{float:"right"}} className="btn btn-success" disabled value="Registering..."/>)}
                  {!loading && (<input type="submit" style={{float:"right"}} className="btn btn-success" value="Save"/>)}
                </div>
            

              </form>
                            
            </div>
          </div>
        </div>
      </div>	
    
    </AppContainer>
  )
}

export default RegisterMainTask
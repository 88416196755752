import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import Select from "react-select";
import ajaxProduct from "../../util/remote/ajaxProduct";
import ajaxTask from "../../util/remote/ajaxTask";
import AuthContext from "../../Context/AuthContext";
import ajaxStores from "../../util/remote/ajaxStores";

const CreateTeamProductAllocation = (props) => {
  const { userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [tool, setTool] = useState("");
  const [date_allocated, setDateAllocated] = useState("");
  const [task, setTask] = useState("");
  const [toolsList, setToolsList] = useState("");
  const [tasksList, setTasksList] = useState("");
  useEffect(() => {
    getTools();
    getTasks();
  }, []);

  const getTools = async () => {
    const server_response = await ajaxProduct.fetchAllTools();

    if (server_response.status === "OK") {
      setToolsList(server_response.details);
    }
  };

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks("");

    if (server_response.status === "OK") {
      setTasksList(server_response.details);
    }
  };
  const [storeID, setStoreiD] = useState("");
  const [stores, setStores] = useState("");
  useEffect(() => {
    fetch_store();
  }, []);

  const fetch_store = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    } else {
      setStores("404");
    }
  };
  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      props.team_id > 0 &&
      tool > 0 &&
      storeID > 0 &&
      quantity > 0 &&
      date_allocated != ""
    ) {
      setLoading(true);
      let data = {
        product_id: tool,
        team_id: props.team_id,
        quantity: quantity,
        date_allocated: date_allocated,
        task_id: task,
        added_by: userId,
        store_id: storeID,
      };
      const server_response = await ajaxProduct.createTeamInventoryAllocation(
        data
      );
      setLoading(false);
      if (server_response.status === "OK") {
        setTool("");
        setQuantity("");
        setDateAllocated("");
        toast.success(server_response.message);
        props.f(props.team_id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Allocation
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Product Allocation"
      id="model-new-teamProductallocation"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select Tool</label>
        <Select
          onChange={(e) => setTool(e.id)}
          getOptionLabel={(option) => option.product_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(toolsList) ? toolsList : []}
          value={
            Array.isArray(toolsList) &&
            toolsList.find((value) => value.id === tool)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Select Task</label>
        <Select
          onChange={(e) => setTask(e.id)}
          getOptionLabel={(option) =>
            `${option.task_name} (${option.project.project_name})`
          }
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(tasksList) ? tasksList : []}
          value={
            Array.isArray(tasksList) &&
            tasksList.find((value) => value.id === task)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Quantity</label>
        <input
          onChange={(e) => setQuantity(e.target.value)}
          value={quantity}
          type="number"
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Date Allocated</label>
        <input
          onChange={(e) => setDateAllocated(e.target.value)}
          value={date_allocated}
          type="date"
          className="form-control"
        />
      </div>{" "}
      <div className="mb-4">
        <label htmlFor="">
          Store:<span className="tx-danger">*</span>
        </label>
        <Select
          onChange={(e) => setStoreiD(e.id)}
          getOptionLabel={(option) => option.storeName}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(stores) ? stores : []}
          value={
            Array.isArray(stores) &&
            stores.find((value) => value.id === storeID)
          }
        />
      </div>
    </SystemModal>
  );
};

export default CreateTeamProductAllocation;

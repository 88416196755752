import { useContext, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ProjectContext from "../../Context/ProjectContext";
import ClientContext from "../../Context/ClientContext";
import TextInput from "../Common/TextInput";
import ajaxProject from "../../util/remote/ajaxProject";
import Select from "react-select";

const CreateProject = () => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [overview, setOverview] = useState("");
  const [priority, setPriority] = useState("");
  const [client, setClient] = useState("");
  const [startDate, setStartDate] = useState("");

  const { clientList } = useContext(ClientContext);
  const { getProjectList } = useContext(ProjectContext);

  const handleAdd = async (e) => {
    e.preventDefault();
    // Form validation
    if (
      project.trim() === "" ||
      description.trim() === "" ||
      overview.trim() === "" ||
      priority.trim() === "" ||
      client.trim() === ""
    ) {
      toast.error("Please complete all fields.");
    } else if (!["Low", "Medium", "High"].includes(priority)) {
      toast.error("Invalid project priority.");
    } else {
      setLoading(true);
      try {
        const server_response = await ajaxProject.createProject(
          project,
          description,
          overview,
          priority,
          client,
          startDate
        );

        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          resetForm();
          getProjectList();
        } else {
          toast.error(server_response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const resetForm = () => {
    setProject("");
    setDescription("");
    setOverview("");
    setPriority("");
    setClient("");
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Project
          </button>
        </>
      );
    }
  };

  const RenderPriority = () => {
    const options = [
      { label: "Low", value: "Low" },
      { label: "Medium", value: "Medium" },
      { label: "High", value: "High" },
    ];

    return (
      <TextInput
        label="Project Priority"
        options={options}
        type="select"
        placeholder="Select the priority of the project..."
        onChange={(item) => setPriority(item.value)}
        value={
          Array.isArray(options) &&
          options.find((item) => item.value === priority)
        }
      />
    );
  };

  return (
    <SystemModal
      title="Add New Project"
      id="model-new-project"
      size="md"
      footer={RenderFooter}>
      <TextInput
        label="Project Name"
        value={project}
        type="text"
        placeholder="Enter project name"
        onChange={(e) => setProject(e.target.value)}
      />

      <TextInput
        label="Project Description"
        value={description}
        type="text"
        placeholder="Enter brief description about project"
        onChange={(e) => setDescription(e.target.value)}
      />

      <TextInput
        label="Project Overview"
        value={overview}
        type="textarea"
        placeholder="Enter the overview of the project"
        onChange={(e) => setOverview(e.target.value)}
      />

      <RenderPriority />
      <div className="mb-4">
        <label htmlFor="">Select Client</label>
        <Select
          onChange={(e) => setClient(e.client_id)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.client_id}
          isSearchable
          options={Array.isArray(clientList) ? clientList : []}
          value={
            Array.isArray(clientList) &&
            clientList.find((value) => value.client_id === client)
          }
        />
      </div>

      <div className="mb-4">
        {/* <label htmlFor="">Project <br/> Start Date</label>                    */}
        <TextInput
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          type="date"
          label="Project Start Date"
        />
      </div>
    </SystemModal>
  );
};

export default CreateProject;

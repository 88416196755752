import React, { useEffect, useState } from "react";
import ajaxIssueLog from "../../util/remote/ajaxIssueLog";
import CountUp from "react-countup";

export default function TotalIssueLogCard() {
  const [issuesNumber, setIssuesNumber] = useState(false);

  const getIssuesNumber = async () => {
    const server_response = await ajaxIssueLog.fetchLogsNumber();
    if (server_response.status === "OK") {
      setIssuesNumber(server_response.details);
    } else {
      setIssuesNumber("404");
    }
  };

  useEffect(() => {
    getIssuesNumber();
  }, []);

  return (
    <div
      className="icon-box bg-color-6 d-block mt-3"
      style={{
        background: "#EAEFFF",
        width: "33%",
        padding: "20px",
        margin: "15px 0 !important",
      }}>
      <div className="content text-center color-6">
        <h5 className="title-box fs-17 font-w500">Total Issue Logs</h5>
        <div
          className="excellencetheme-counter fs-18 font-wb"
          style={{ fontWeight: "bold", color: "#496EE8" }}>
          <span
            className="number"
            data-from="0"
            data-to="309"
            data-speed="2500"
            data-inviewport="yes">
            {" "}
            <CountUp
              start={0}
              end={issuesNumber ? issuesNumber.total_p : "..."}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

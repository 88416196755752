import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";
import { Toaster, toast } from "react-hot-toast";
import ajaxTask from "../../util/remote/ajaxTask";
import { useParams } from "react-router-dom";
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";
import ajaxPayRate from "../../util/remote/ajaxPayRate";

function ViewPayRate(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const [rateId, setRateId] = useState(props.rateId);
  const [teamName, setTeamName] = useState(props.teamName);
  const [roleName, setRoleName] = useState(props.roleName);
  const [rate, setRate] = useState(props.rate);

  const handleUpdatePayRate = async (e) => {
    e.preventDefault();
    if (rate > 0) {
      setLoading(true);
      const server_response = await ajaxPayRate.updatePayRate(rateId, rate);
      setLoading(false);
      // setTimeout(() => setLoading(false), 1000);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (edit) {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn ripple btn-success"
            onClick={handleUpdatePayRate}
          >
            Update Pay Rate
          </button>
        </>
      );
    } else {
      return (
        <button
          type="button"
          className="btn ripple btn-secondary"
          onClick={() => setEdit(true)} // Pass a function to setEdit
        >
          Edit <i className="fa fa-pencil"></i>
        </button>
      );
    }
  };
  return (
    <>
      <Toaster />
      <SystemModal
        title="Pay Rate Details"
        id="model-pay-details"
        size="md"
        footer={RenderFooter}
      >
        <div className="row">
          {edit ? (
            <form method="post">
              <div className="row m-1">
                <div className="mb-4 col-md-12">
                  <label htmlFor="">
                    Pay Rate: <span className="tx-danger">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={rate}
                    onChange={(e) => setRate(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </form>
          ) : (
            <div className="row m-3">
              <div className="mb-4 col-md-12">
                <label htmlFor="" className="mr-1">
                  Team Name:
                </label>
                <b>
                  <label>{teamName}</label>
                </b>
              </div>

              <div className="mb-4 col-md-12">
                <label htmlFor="" className="mr-1">
                  Role:
                </label>
                <b>
                  <label>{roleName}</label>
                </b>
              </div>

              <div className="mb-4 col-md-12">
                <label htmlFor="" className="mr-1">
                  Rate:
                </label>
                <b>
                  <label>{rate}</label>
                </b>
              </div>
            </div>
          )}
        </div>
      </SystemModal>
    </>
  );
}

export default ViewPayRate;

import { useState } from "react"
import SystemModal from "../Common/SystemModal"
import Loader from "../Common/Loader"
import { toast } from 'react-hot-toast';
import ajaxUser from "../../util/remote/ajaxUser";

const ResetUserPassword=(props)=>{

    const [loading, setLoading] = useState(false)

    const handleUpdatePassword = async(e) =>{
        e.preventDefault()
        if(props.userID>0){
            setLoading(true)
            const server_response = await ajaxUser.updatePasswordByAdmin(props.userID);
            setLoading(false);
            if(server_response.status==="OK"){
               
                toast.success(server_response.message);
            }
            else{
                toast.error(server_response.message); 
            }
        }
        else{
            toast.error("Please enter all fields!")
        }  
    }
    

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleUpdatePassword}>Confirm Password Reset</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Reset User Password"
            id="model-new-passw"
            size="md"
            footer={RenderFooter}
        >

            <div className="bg-white">
			    <div className="alert text-center">
				    <i className="fe fe-alert-circle fs-50 text-warning"></i>
			        <h3 className="mt-2 mb-1">Are you sure you want to reset the user's password?</h3>
				    <p className="mb-3 mb-3 tx-inverse">Password will be set to <b style={{color:"green"}}>1234</b></p>
				</div>
			</div>
       
        </SystemModal>
    )
}

export default ResetUserPassword

import React, { useContext} from "react";
import RegionContext from "../../Context/RegionContext";
import Loader from "../Common/Loader";

export default function RegionsList() {   
    const {regionList} = useContext(RegionContext);
	
	return (
		
        <div className="col-lg-6">
		
			<div className="card custom-card" style={{borderRadius: "10px"}}>
				<div className="card-body">
					<div>
						<h6 className="card-title mb-1">Regions List</h6>
						<p className="text-muted mb-0 card-sub-title">List of all the regions</p>
					</div>
				</div>
			    <div className="user-manager scroll-widget border-top">
					<div className="table-responsive">
						<table className="table mg-b-0">
							<tbody>
								{Array.isArray(regionList) && regionList.length > 0 ? (
                                	regionList.map((item, key) => (
									<tr key={key}>
										<td>
                                        	<p>{key+1}</p>
										</td>
										<td>
											<h6 className="mg-b-0">{item.region_name}</h6>
										</td>
										
									</tr>
									))
								): (
									<tr>
										<td colSpan="1" style={{textAlign:"center"}}>No regions added yet.</td>
									</tr>
								)}       
							</tbody>
						</table>
						{!regionList && <Loader/>}
					</div>
				</div>
			</div>
		</div> 
	
    );
}

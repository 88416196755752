import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import PageHeader from './PageHeader'
import Footer from './Footer'


export default function AppContainer(props) {
  return (
		<div className="page" style={{overflowY:"auto"}}>
            <Sidebar />

    <div className="main-content side-content pt-0">
    
        <Header />
        <div className="container-fluid">
       
            <PageHeader title={props.title}/>
        

            {props.children}



        </div>
        </div>
        {/* <Footer /> */}
    </div>
  )
}

import { useState, useContext } from "react";
import Select from "react-select";
import SystemModal from "../../Components/Common/SystemModal";
import toast from "react-hot-toast";
import ProjectContext from "../../Context/ProjectContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../../Components/Common/Loader";

const InstallationProjectSelectPending = (props) => {
  const [projectID, setProjectID] = useState("");
  const [loading, setLoading] = useState(false);
  const { projectList } = useContext(ProjectContext);
  const navigate = useNavigate(); // Initialize navigate

  const handle_navigate = () => {
    if (!projectID) {
      toast.error("Please select a project.");
      return;
    }

    navigate(`/installations/pending/${projectID}`);
    props.function(false);
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className="btn ripple btn-success"
            onClick={handle_navigate}>
            View Installations
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Select Product To view (Pending Installations)"
      id="model-update-task"
      size="md"
      footer={RenderFooter}
      style={{
        background: "linear-gradient(135deg, #d3d3d3, #e0c3fc, #fcb69f)",
        borderRadius: "20px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}>
      <div className="mb-4">
        <label>Select Project</label>
        <Select
          onChange={(e) => setProjectID(e.project_id)}
          getOptionLabel={(option) => option.project_name}
          getOptionValue={(option) => option.project_id}
          isSearchable
          options={Array.isArray(projectList) ? projectList : []}
          value={projectList.find((value) => value.project_id === projectID)}
        />
      </div>
    </SystemModal>
  );
};

export default InstallationProjectSelectPending;

import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Link, useParams } from "react-router-dom";
import { RenderSecure } from "../util/script/RenderSecure";
import { Toaster, toast } from "react-hot-toast";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ajaxProject from "../util/remote/ajaxProject";
import Poles from "../Components/ProjectProfile/Poles.js";
import ProjectInformation from "../Components/ProjectProfile/ProjectInformation";
import Loader from "../Components/Common/Loader";
import ajaxTask from "../util/remote/ajaxTask";
import EndTask from "./Tasks/EndTask";
import useStateCallback from "../util/customHooks/useStateCallback";
import UpdateTask from "../Components/Team/UpdateTask.js";

const ProjectProfile = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  useEffect(() => {
    getProjectProgress(id);
    getProjectProfile(id);
    getCompletedTasks(id);
    getTasks(id);
  }, [id]);

  const [projectProfile, setProjectProfile] = useState(false);
  const [projectProgress, setProjectProgress] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(false);
  const [taskList, setTaskList] = useState(false);

  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [overview, setOverview] = useState("");
  const [priority, setPriority] = useState("");
  const [projectID, setProjectID] = useState("");
  const [startDate, setStartDate] = useState("");

  const getProjectProfile = async (id) => {
    const server_response = await ajaxProject.fetchProjectProfile(id);
    if (server_response.status === "OK") {
      setProjectProfile(server_response.details);
    } else {
      setProjectProfile("404");
    }
  };

  const getProjectProgress = async (id) => {
    const server_response = await ajaxProject.fetchProjectProgress(id);
    if (server_response.status === "OK") {
      setProjectProgress(server_response.details);
    } else {
      setProjectProgress("404");
    }
  };

  const getCompletedTasks = async () => {
    setLoading(true);
    const server_response = await ajaxTask.fetchCompletedTasks(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setCompletedTasks(server_response.details);
    } else {
      setCompletedTasks("404");
    }
  };

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks(id);
    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  const setProjectUpdate = () => {
    handleActive();
    setProjectID(projectProfile.project_id);
    setProjectName(projectProfile.project_name);
    setDescription(projectProfile.description);
    setOverview(projectProfile.overview);
    setPriority(projectProfile.priority);
    setStartDate(projectProfile.start_date);
  };

  const handleProjectUpdate = async (e) => {
    e.preventDefault();

    if (
      projectName.length > 0 &&
      description.length > 0 &&
      overview.length > 0 &&
      priority.length > 0
    ) {
      const server_response = await ajaxProject.updateProject(
        projectID,
        projectName,
        description,
        overview,
        priority,
        startDate
      );

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getProjectProfile(id);
        getProjectProgress(id);
      }
    } else {
      toast.error("Complete all fields and try again!!");
    }
  };
  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateTask
          taskID={item.id}
          f={getTasks}
          projectID={id}
          isOpen={true}
          taskName={item.task_name}
          dailyExpectation={item.daily_expectation}
          duration={item.duration}
        />
      )
    );
  };

  const handleModal2 = (e, item) => {
    setModal(false, () =>
      setModal(
        <EndTask
          task_id={item.id}
          project_id={id}
          f={getTasks}
          g={getCompletedTasks}
          isOpen={true}
        />
      )
    );
  };

  return (
    <AppContainer title={"Project Profile"}>
      <Toaster position="top-center" reverseOrder={false} />

      {modal}
      {loading && <Loader />}

      {!loading && (
        <div className="col-lg-12 mb-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-row mb-4">
                  <Nav.Item>
                    <Nav.Link size="sm" eventKey="first">
                      Project Details{" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link size="sm" eventKey="second">
                      Project Tasks{" "}
                    </Nav.Link>
                  </Nav.Item>

                  <RenderSecure code="ADD-TASK">
                    <Nav.Item>
                      <Link to="task/create">
                        <button
                          type="submit"
                          style={{ float: "right", color: "white" }}
                          className="btn btn-success ml-4 mb-4">
                          Add Task
                        </button>
                      </Link>
                    </Nav.Item>
                  </RenderSecure>
                </Nav>
              </Col>

              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="col-12 col-xl-12">
                      <div
                        className="box user-pro-list overflow-hidden mb-30"
                        style={{
                          marginBottom: "30px",
                          backgroundColor: "white",
                          padding: "25px",
                          boxShadow: "10px",
                          borderRadius: "10px",
                        }}>
                        {projectProfile && (
                          <div
                            className="box-body"
                            style={{ position: "relative" }}>
                            <div className="user-pic text-center">
                              <div
                                className="pro-user mt-3"
                                style={{ marginTop: "1rem !important" }}>
                                <h5
                                  className="pro-user-username text-dark mb-2 fs-30 mt-42 color-span"
                                  style={{ lineHeight: "1.5" }}>
                                  {projectProfile.project_name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          className="box-footer pt-41"
                          style={{ paddingTop: "41px !important" }}>
                          <div className="btn-list text-center">
                            <RenderSecure code="EDIT-PROJECT">
                              {active ? (
                                <Link
                                  to="#"
                                  onClick={handleInActive}
                                  className="btn ripple btn-danger btn-sm mr-2">
                                  <i className="fe fe-x"></i>Back
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  onClick={setProjectUpdate}
                                  className="btn ripple btn-warning btn-sm mr-2">
                                  <i className="fa fa-edit mr-1"></i>Update
                                  Details
                                </Link>
                              )}
                            </RenderSecure>

                            <RenderSecure code="UPLOAD-EXCEL">
                              <Link
                                to={`/project/poles/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-flag mr-1"></i>
                                Project Poles
                              </Link>

                              <Link
                                to={`/project/crosses/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-cross mr-1"></i>Project
                                Crosses
                              </Link>

                              <Link
                                to={`/project/cables/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-cable-car mr-1"></i>Project
                                Cables
                              </Link>

                              <Link
                                to={`/project/otbs/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-football mr-1"></i>Project
                                OTBs
                              </Link>

                              <Link
                                to={`/project/joints/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-joint mr-1"></i>Project
                                Joints
                              </Link>

                              <Link
                                to={`/project/hardware/${id}`}
                                className="btn ripple btn-primary btn-sm mr-2">
                                <i className="fa fa-server mr-1"></i>Project
                                Hardware
                              </Link>
                              <Link
                                to={`/project/acceptance-form/${id}`}
                                className="btn ripple btn-secondary btn-sm mr-2">
                                Acceptance Form
                              </Link>
                              <a
                                href={`${process.env.PUBLIC_URL}/import_template/Bericot_importing template.xlsx`}
                                className="btn ripple btn-success btn-sm mr-2"
                                download>
                                Importing Template
                              </a>
                            </RenderSecure>
                          </div>
                        </div>
                      </div>

                      {active ? (
                        <div
                          className="box left-dot mb-30"
                          style={{
                            marginBottom: "30px",
                            backgroundColor: "white",
                            padding: "25px",
                            boxShadow: "10px",
                            borderRadius: "10px",
                          }}>
                          <div className="box-header  border-0 pd-0">
                            <div className="box-title fs-20 font-w600">
                              {" "}
                              Update Project Details
                            </div>
                          </div>

                          <br />

                          <div className="box-body pt-20 user-profile">
                            <form onSubmit={(e) => handleProjectUpdate(e)}>
                              <div className="form-group">
                                <div className="row row-sm">
                                  <div className="col-sm-6">
                                    <label htmlFor="">Project Name:</label>
                                    <input
                                      type="text"
                                      defaultValue={projectName}
                                      onChange={(e) =>
                                        setProjectName(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label>Project Description</label>
                                    <input
                                      type="text"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row row-sm">
                                  <div className="col-sm-6">
                                    <label>Overview</label>
                                    <textarea
                                      type="text"
                                      value={overview}
                                      onChange={(e) =>
                                        setOverview(e.target.value)
                                      }
                                      rows={3}
                                      className="form-control"></textarea>
                                  </div>
                                  <div className="col-sm-6">
                                    <label>Priority</label>
                                    <select
                                      value={priority}
                                      onChange={(e) =>
                                        setPriority(e.target.value)
                                      }
                                      className="form-control">
                                      <option value="Low">Low</option>
                                      <option value="Medium">Medium</option>
                                      <option value="High">High</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-4">
                                <label htmlFor="">Project Start Date</label>
                                <input
                                  type="date"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  className="form-control"
                                />
                              </div>

                              <button className="btn ripple btn-main-primary btn-block">
                                Update Details
                              </button>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <ProjectInformation />
                      )}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <div className="col-lg-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="mb-4">
                            <h6 className="card-title mb-4">Active Tasks</h6>
                          </div>

                          <div className="table-responsive">
                            <div className="border-top mt-3"></div>

                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Task</th>
                                  <th>Duration</th>
                                  <th>Daily Expectation</th>
                                  <th>Actions</th>
                                  <th>Edit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(taskList) &&
                                  taskList.map((item, key) => (
                                    <tr key={key}>
                                      <td>{item.task_name}</td>
                                      <td>{item.duration}</td>
                                      <td>{item.daily_expectation}</td>
                                      <td>
                                        <Link
                                          className="btn btn-sm btn-info mr-2"
                                          href="#"
                                          onClick={(e) =>
                                            handleModal2(e, item)
                                          }>
                                          End Task
                                        </Link>
                                      </td>
                                      <td>
                                        <i
                                          style={{ color: "gray" }}
                                          onClick={(e) => handleModal(e, item)}
                                          className="fe fe-edit-2"></i>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="mb-4">
                            <h6 className="card-title mb-4">Completed Tasks</h6>
                          </div>

                          <div className="table-responsive">
                            <div className="border-top mt-3"></div>

                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Task</th>
                                  <th>Duration</th>
                                  <th>Daily Expectation</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(completedTasks) &&
                                  completedTasks.map((item, key) => (
                                    <tr key={key}>
                                      <td>{item.task_name}</td>
                                      <td>{item.duration}</td>
                                      <td>{item.daily_expectation}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </AppContainer>
  );
};

export default ProjectProfile;

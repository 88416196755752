import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import TeamContext from "../../Context/TeamContext";

export default function TeamPayments() {
  const { teamList } = useContext(TeamContext);

  const totalPayments = teamList.reduce((total, item) => {
    return total + (item.credit_amount?.team_credit_sum || 0);
  }, 0);

  return (
    <div className="col-lg-12">
      <div className="card custom-card" style={{ borderRadius: "10px" }}>
        <div className="card-body map-card">
          <TableHeader
            title="Team Payments"
            subtitle="List of teams with the total credit payments "
          />
          <div className="border-top mt-3"></div>
          <div className="user-manager scroll-widget border-top">
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Team Name</th>
                    {/* <th scope="col">Members</th> */}
                    <th scope="col">Credit Payments</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamList) && teamList.length > 0 ? (
                    teamList.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          <Link to={`../teams/team-profile/${item.team_id}`}>
                            {item.team_name}
                          </Link>
                        </td>
                        <td>{item.credit_amount?.team_credit_sum_c}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No teams registered yet.
                      </td>
                    </tr>
                  )}
                  {/* Total row */}
                  <tr>
                    <td colSpan="2">
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{totalPayments}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              {!teamList && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

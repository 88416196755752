import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

    async getTeamList(data){
        let response = await apiCall("team/list", data)
        return response;
    },

    async getTeamLeadersList(data){
        let response = await apiCall("lead/list", data)
        return response;
    },

    async fetchTeamMembers(team_id){
        let data={
            "team_id":team_id
        }
        let response = await apiCall("team/members", data)
  
        return response;
  
    },

    async fetchTeamTargets(team_id){
        let data={
              "team_id":team_id
        }
        let response = await apiCall("target/list", data)
  
        return response;
  
    },

    async fetchTeamNumber(data) {
       
        let response = await apiCall("team/count", data)
        return response;
       
    },

    async createTeam(team_name){
        let data={
            "team_name":team_name
        }
        let response = await apiCall("team/create", data)

        return response;

    },

    async createTeamLead(team_id, user){
        let data={
            "team_id":team_id,
            "user":user
        }
        let response = await apiCall("lead/create", data)

        return response;

    },

    async updateTeam(team_id,team_name){
        let data={
            "team_id":team_id,
            "team_name":team_name
        }
        let response = await apiCall("team/update", data)

        return response;

    },

    async updateTeamLead(lead_id, team_id, user){
        let data={
            "lead_id":lead_id,
            "team_id":team_id,
            "user":user
        }
        let response = await apiCall("lead/update", data)

        return response;

    },

    async fetchTeamProfile(team_id){
        let data={
            "team_id":team_id
        }
        let response = await apiCall("team/profile", data)
  
        return response;
  
    },

    async createTarget(team_id, target_details,effective_date,end_date)
  {
      let data={
          "team_id":team_id,
          "target_details":target_details,
          "effective_date":effective_date,
          "end_date":end_date
      }

      let response = await apiCall("target/create", data)

      return response;

  },

    async listTargets(team_id)
  {
      let data={
          "team_id":team_id
      }
      
      let response = await apiCall("target/list", data)

      return response;

  }
}
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ProjectContext from "../../Context/ProjectContext";
import Loader from "../Common/Loader";
import useStateCallback from "../../util/customHooks/useStateCallback";
import ProjectChangeStatusModel from "./ProjectChangeStatusModel";

export default function ProjectsList() {
  const { projectList } = useContext(ProjectContext);

  const [update_status, setUpdate_status] = useStateCallback(false);

  const handlecomplete_installations = (e, project_id) => {
    e.preventDefault();
    setUpdate_status(false, () =>
      setUpdate_status(<ProjectChangeStatusModel project_id={project_id} />)
    );
  };

  return (
    <div className="row" style={{ marginright: "-5px", marginLeft: "-5px" }}>
      {update_status}
      {Array.isArray(projectList) &&
        projectList.map((item, key) => (
          <div className="col-lg-6 col-md-12">
            <div
              className="card"
              style={{
                transition: "all 0.5s ease-in-out",
                borderRadius: ".55rem",
                borderColor: "#E6E9ED",
                position: "relative",
                width: "100%",
                marginBottom: "10px",
              }}>
              <div
                className="card-header"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  alignItems: "center",
                  borderColor: "#E6E9ED",
                }}>
                <h3
                  className="card-title col-12"
                  style={{
                    fontSize: "20px",
                    marginBottom: "0",
                    fontWeight: "500",
                    lineHeight: "1.2",
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Link to={`project-profile/${item.project_id}`}>
                    {item.project_name}
                  </Link>
                  {item.status == "1" ? (
                    <>
                      {" "}
                      <button
                        style={{
                          borderRadius: "10px",
                        }}
                        disabled
                        className="btn btn-success  btn-with-icon btn-sm">
                        <i class="fa-solid fa-flag-checkered"></i> complete
                      </button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        style={{
                          borderRadius: "10px",
                        }}
                        onClick={(e) =>
                          handlecomplete_installations(e, item.project_id)
                        }
                        className="btn btn-primary  btn-with-icon btn-sm">
                        <i className="fe fe-plus"></i> Update_status
                      </button>
                    </>
                  )}
                </h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-5 py-1">
                    <strong>Description:</strong>
                  </div>
                  <div className="col-7 py-1">{item.description}</div>
                  <div className="col-5 py-1">
                    <strong>Created:</strong>
                  </div>
                  <div className="col-7 py-1">{item.created_at.short_date}</div>
                  <div className="col-5 py-1">
                    <strong>Tasks:</strong>
                  </div>
                  <div className="col-7 py-1">
                    {item.total_tasks ? item.total_tasks : "0"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {!projectList && <Loader />}
    </div>
  );
}

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import TeamContext from "../../Context/TeamContext";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function TeamsList() {
  const { teamList } = useContext(TeamContext);

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Teams  Report"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(18); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(14); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(12); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(16); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    const columns = ["Team Name", "Registration Date"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 1800,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save("teams_data.pdf");
  };

  return (
    <div className="col-lg-12">
      <div className="card custom-card" style={{ borderRadius: "10px" }}>
        <div className="card-body map-card">
          <TableHeader
            title="Teams List"
            subtitle="List of all the teams"
            viewButton={
              <a
                href="#"
                onClick={exportToPDF}
                className="btn btn-secondary btn-sm mr-2"
                style={{ float: "right" }}>
                Export to PDF
              </a>
            }
          />
          <div className="border-top mt-3"></div>
          <div className="user-manager scroll-widget border-top">
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Team Name</th>
                    {/* <th scope="col">Members</th> */}
                    <th scope="col">Registration Date</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamList) && teamList.length > 0 ? (
                    teamList.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          <Link to={`team-profile/${item.team_id}`}>
                            {" "}
                            {item.team_name}
                          </Link>
                        </td>

                        <td>{item.created_at.date}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No teams registered yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {!teamList && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

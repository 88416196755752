import React, { Component } from 'react';
import Chart from "react-apexcharts";
import Loader from "../Common/Loader";
import ajaxClient from '../../util/remote/ajaxClient';

class ClientsGraph extends Component {

   state={
    testing:false,
    statData:false,
    series: false,
    options: false
  }

  componentDidMount() {
    this.getMonthlyClients()
  }

  setSeries = (clients) =>{
    this.setState({
      series:[
        {
          name: 'Total Clients',
          type: 'line',
          data: clients
        }]
      
    })
  }

  setOptions = (months)=>{
    this.setState({
      options:{
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        colors: ['#5D3FD3', '#5D3FD3'],
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: months,
        },
      }         
    })
  }
  

  getMonthlyClients=async() =>{
    const server_response = await ajaxClient.fetchClientRegisteredMonthly()
    if(server_response.status === 'OK'){
      this.setState({
        statData:server_response.details
      },()=>{
        const data = this.state.statData
        const months = []
        const clients = []
        Object.keys(data.months).map((index, key)=>{
          months.push(data.months[index])
        })
        Object.keys(data.totals).map((index, key)=>{
          clients.push(data.totals[index].total*1)
        })
        this.setOptions(months)
        this.setSeries(clients)
      }
      )

    }
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card custom-card overflow-hidden" style={{borderRadius: "10px"}}>
          <div className="card-body">
            <div className="card-option d-flex">
              <div>
                <h6 className="card-title mb-1">Clients Registered</h6>
                <p className="text-muted card-sub-title">Number of clients registered this year</p>
              </div>
              <div className="card-option-title ml-auto">
                <div className="btn-group p-0">
                  
                  <button className="btn btn-light btn-sm" type="button">This Year</button>
                                
                </div>
              </div>
            </div>
            <div>

              {this.state.statData && this.state.options && this.state.series && <Chart
                                                                                    options={this.state.options}
                                                                                    series={this.state.series}
                                                                                    height={250}
                                                                                  /> }
              {!this.state.statData && <Loader/>}

            </div>
          </div>
        </div>
      </div>
        
    );
  }
}

export default ClientsGraph;
import React, { useContext} from 'react';
import Loader from '../../Components/Common/Loader';
import TableHeader from '../../Components/Common/TableHeader';
import ProjectContext from '../../Context/ProjectContext';
import { Link } from 'react-router-dom';

function ListAllTasks() {
  const {projectList} = useContext(ProjectContext);


  return (
    // <AppContainer title="Task Board">
    <>
       

            <div className="col-lg-12">
          
          <div className="card custom-card" style={{borderRadius: "10px"}}>
              <div className="card-body map-card">
                 <TableHeader
                      title="Projects with Ongoing Tasks"
                      subtitle="List of all the projects with ongoing tasks"  
                        
                  />
                  <div className="border-top mt-3"></div>
                  <div className="user-manager scroll-widget border-top">                      
                      <div className="table-responsive">
                          <table className="table table-hover text-nowrap mg-b-0">
                              <thead>
                                  <tr>
                                      <th scope="col">No.</th>
                                      <th scope="col">Project</th>
                                      <th scope="col" >Active Tasks Count</th>
                                      <th scope="col" ></th>
                                  </tr>
                              </thead>
                              <tbody>
                              {Array.isArray(projectList) && projectList.length > 0 ? (
                                  projectList.map((item, key) => (
                                      <tr key={key}>
                                      <th scope="row">{key+1}</th>
                                      <td>{item.project_name}</td>
                                      <td>{item.total_tasks}</td>
                                      <td><Link to={`project-tasks/${item.project_id}`} className="btn btn-primary btn-sm mb-2">
                                            View Tasks
                                      </Link></td>
                                      </tr>
                                  ))
                              ): (
                                  <tr>
                                      <td colSpan="4" style={{textAlign:"center"}}>No projects registered yet.</td>
                                  </tr>
                              )}
                              </tbody>
                          </table>
                          {!projectList && <Loader/>}
                      </div>
                  </div>
      </div>
    </div>
    </div>

      {/* <div className="col-lg-12">
          
            <div className="card custom-card" style={{borderRadius: "10px"}}>
                <div className="card-body map-card">
                   <TableHeader
                        title="Tasks List"
                        subtitle="List of all the tasks"  
                        viewButton={
          <RenderSecure code="ADD-TASK">
                        <a href="#" onClick={handleModal} className="btn btn-primary btn-sm" style={{float:"right"}}>Add Task</a>
                      </RenderSecure>
                      }   
                    />
                    <div className="border-top mt-3"></div>
                    <div className="user-manager scroll-widget border-top">                      
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Task Name</th>
                                        <th scope="col">Project</th>
                                        <th scope="col" >Duration </th>
                                        <th scope="col" >Daily Expectation </th>
                                        <th scope="col" >Credit Payments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(taskList) && taskList.length > 0 ? (
                                    taskList.map((item, key) => (
                                        <tr key={key}>
                                        <th scope="row">{key+1}</th>
                                        <td>{item.task_name}</td>
                                        <td>{item.project.project_name}</td>
                                        <td>{item.duration} days</td>
                                        <td>{item.daily_expectation}</td>
                                        <td>{item.credit_payments?.task_credit_sum_c}</td>
                                        </tr>
                                    ))
                                ): (
                                    <tr>
                                        <td colSpan="5" style={{textAlign:"center"}}>No tasks registered yet.</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            {!taskList && <Loader/>}
                        </div>
                    </div>
				</div>
			</div>
	    </div> */}
      </>
    // </AppContainer>
  );
}

export default ListAllTasks;

import React,{useState} from 'react'
import Table1 from './Table1'
import { Users } from './Users';
import { useContext } from 'react';
import ClientContext from '../../Context/ClientContext';
export default function SearchBar() {
    const [query, setQuery] = useState("");
    const [client,setClient] = useState(false)
    const {clientList} = useContext(ClientContext)
      const keys = ["name","email"];
      const search = (data) => {
        return Array.isArray(data) && data.filter((item) =>
          keys.some((key) => item[key].toLowerCase().includes(query))
        );
      };
    return (
      <div className="app">
          <input
            className="search"
            placeholder="Search..."
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        {<Table1 data={search(clientList)} />}
      </div>
    );
}

import React, { useContext} from 'react'
import AppContainer from '../Components/Structure/AppContainer';
import RoleContext from '../Context/RoleContext';
import Loader from '../Components/Common/Loader';
import TableHeader from '../Components/Common/TableHeader'


export default function Roles() {
  const {roleList} = useContext(RoleContext);

  return (
    <AppContainer title="Roles">

          <div className="col-lg-12">
            <div className="card custom-card" style={{borderRadius: "10px"}}>
              <div className="card-body map-card">
                <TableHeader
                  title="Roles List"
                  subtitle="List of all the roles assigned to different users"    
                />
                <div className="border-top mt-3"></div>
                <div className="table-responsive">
                  <table className="table table-hover text-nowrap mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Role Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(roleList) && roleList.length > 0 ? (
                        roleList.map((item, key) => (
                        <tr key={key}>
                          <th>{key+1}</th>
                          <td>{item.role_name}</td>
                        </tr>
                        ))
                      ): (
                        <tr>
                          <td colSpan="2" style={{textAlign:"center"}}>No user roles created yet.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!roleList && <Loader/>}
                </div>
              </div>
			      </div>
					</div>
     
    </AppContainer>
  )
}

import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ajaxProject from "../../util/remote/ajaxProject";
import TableHeader from "../Common/TableHeader";
import AppContainer from "../Structure/AppContainer";

function AcceptanceForm() {
  const [loading, setLoading] = useState(false);
  const cardRef = useRef(null);
  const [projectProfile, setProjectProfile] = useState(false);
  const { id } = useParams();

  const exportAsPDF = () => {
    const unit = "pt";
    const size = "A4";

    const marginLeft = 40;
    const doc = new jsPDF("p", unit, size);

    const source = cardRef.current;

    const excludeImages = source.querySelectorAll("img"); // Get all img elements

    // Hide all images before generating PDF
    excludeImages.forEach((img) => {
      img.style.display = "none";
    });

    doc.html(source, {
      callback: function (pdf) {
        pdf.save("acceptance_form.pdf");

        // After generating PDF, show the images again
        excludeImages.forEach((img) => {
          img.style.display = "block"; // Or set it to the initial display style
        });
      },
      x: marginLeft,
      y: 40,
      html2canvas: { scale: 0.5 },
    });
  };

  const getProjectProfile = async (id) => {
    const server_response = await ajaxProject.fetchProjectProfile(id);
    if (server_response.status === "OK") {
      setProjectProfile(server_response.details);
    } else {
      setProjectProfile("404");
    }
  };

  useEffect(() => {
    getProjectProfile(id);
  }, [id]);

  return (
    <AppContainer title="Acceptance Form">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <TableHeader
            viewButton={
              <a
                href="#"
                onClick={exportAsPDF}
                className="btn btn-secondary btn-sm mb-3"
                style={{ float: "right" }}>
                Export as PDF
              </a>
            }
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card custom-card" id="report-section" ref={cardRef}>
            <div class="card-body">
              <div
                className="table-heade align-items-center"
                style={{ textAlign: "center" }}>
                <div>
                  <h5 className="card-title mb-3">ACCEPTANCE FORM </h5>
                </div>
              </div>

              <div class="row">
                {projectProfile && (
                  <div class="col-lg-6">
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Project Name:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={projectProfile.project_name}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Location:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                )}
                <div class="col-lg-6">
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">Site Name:</label>
                    </div>
                    <div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">Date:</label>
                    </div>
                    <div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mg-b-20" />

              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">S.NO</th>
                    <th class="wd-45p" scope="col">
                      DESCRIPTION
                    </th>
                    <th class="wd-10p" scope="col">
                      Quantity
                    </th>
                    <th class="wd-30p" scope="col">
                      REMARKS
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div
                className="table-heade align-items-center"
                style={{ textAlign: "center" }}>
                <div>
                  <h5 className="card-title mb-3">SIGNED ON BEHALF OF </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">1. NAME:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DESIGNATION:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">2. NAME:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DESIGNATION:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">3. NAME:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DESIGNATION:</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">SIGNATURE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DATE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">SIGNATURE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DATE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">SIGNATURE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mg-b-0">DATE: </label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default AcceptanceForm;

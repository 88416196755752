import React, { useContext, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import TeamContext from "../../Context/TeamContext";
import Select from "react-select";
import RoleContext from "../../Context/RoleContext";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
function CreatePayRate() {
  const { teamList } = useContext(TeamContext);
  const { roleList } = useContext(RoleContext);

  const { id } = useParams();
  const [teamId, setTeamID] = useState("");
  const [roleId, setRoleID] = useState("");
  const [rate, setRate] = useState("");
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const handleAddRate = async (e) => {
    e.preventDefault();
    if (teamId > 0 && teamId.length > 0 && roleId.length > 0) {
      setLoading(true);
      const server_response = await ajaxPayRate.createPayRate(
        id,
        teamId,
        roleId,
        rate
      );
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        setTimeout(() => {
          navigation(-1);
        }, 1000);
      } else {
        toast.error(server_response.message);
        setTimeout(() => {
          navigation(-1);
        }, 1000);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  return (
    <AppContainer title="Register Pay rate">
      <Toaster />
      <Link
        onClick={() => navigation(-1)}
        className="btn btn-primary btn-sm mb-2"
      >
        Back To Previous Page
      </Link>
      <div className="card custom-card">
        <div className="card-body">
          <div>
            <h6 className="card-title mb-4">Register Pay Rate</h6>
          </div>
          <form onSubmit={(e) => handleAddRate(e)} method="post">
            <div className="row">
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Team Name:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setTeamID(e.team_id)}
                  getOptionLabel={(option) => option.team_name}
                  getOptionValue={(option) => option.team_id}
                  isSearchable
                  options={teamList}
                  value={
                    Array.isArray(teamList) &&
                    teamList.find((value) => value.team_id === teamId)
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Role:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setRoleID(e.role_id)}
                  getOptionLabel={(option) => option.role_name}
                  getOptionValue={(option) => option.role_id}
                  isSearchable
                  options={roleList}
                  value={
                    Array.isArray(roleList) &&
                    roleList.find((value) => value.role_id === roleId)
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Rate:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-12">
                {loading && (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-success"
                  >
                    Saving...
                  </button>
                )}

                {!loading && (
                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    className="btn btn-success"
                  >
                    Save pay rate
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppContainer>
  );
}

export default CreatePayRate;

import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Link, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import { TempleBuddhist } from "@mui/icons-material";

function ListPoles() {
  // const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    SearchPoles();
  }, [searchQuery]);

  const SearchPoles = async () => {
    if (searchQuery.length > 0) {
      const server_response = await ajaxProject.searchPolesLimit(searchQuery);

      if (server_response.status === "OK") {
        setSearchResults(server_response.details);
      } else {
        setSearchResults(false);
      }
    }
  };

  const redirect = async (id) => {
    const server_response = await ajaxProject.startInstallation(id);

    if (server_response.status === "OK") {
      toast.success(server_response.message);
      setTimeout(() => {
        navigation(-1);
      }, 1000);
    } else {
      toast.error(server_response.message);
    }
  };

  return (
    <AppContainer title="New Installations">
      <Toaster />
      <div className="mt-4 mb-4">
        <div class="input-group">
          <input
            class="form-control"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for pole..."
            type="text"
            style={{ color: "black" }}
          />
          <span class="input-group-btn">
            <button
              class="btn ripple btn-primary"
              style={{
                height: 26,
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
              }}
              type="button">
              <span class="input-group-btn">
                <i class="fa fa-search"></i>
              </span>
            </button>
          </span>
        </div>
      </div>

      {searchResults && Array.isArray(searchResults) && (
        <div className="card custom-card">
          <div className="card-body">
            <div className="table-responsive mt-2">
              <table className="table  table-hover">
                <thead>
                  <th>#NO</th>
                  <th>Pole ID</th>
                  <th>id_town</th>
                  <th>neighborhood</th>
                  <th>Trunk</th>
                  <th>Contractor</th>
                  <th>Status</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {searchResults &&
                    Array.isArray(searchResults) &&
                    searchResults.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{item.pole_id}</td>
                        <td>{item.id_town}</td>
                        <td>{item.id_neighborhood}</td>
                        <td>{item.trunk}</td>
                        <td>{item.contractor}</td>
                        <td>{item.status}</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-warning btn-sm"
                            onClick={() => redirect(item.id)}>
                            Start Installation
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {!searchResults && searchQuery && (
        <h5 className="ml-5">No poles found with that id</h5>
      )}
    </AppContainer>
  );
}

export default ListPoles;

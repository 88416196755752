import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";
import { Link, useNavigate, useParams } from "react-router-dom";
import useStateCallback from "../../util/customHooks/useStateCallback";
import ViewTask from "../Tasks/ViewTask";
import Rollcall from "../Tasks/Rollcall";
import ViewOutput from "./ViewOutput";
import AlertDialogModal from "../../Components/Common/AlertDialogModal";

function ListDailyOutput() {
  const { id } = useParams();
  const [dailyOuputList, setDailyOuputList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedItemIndex, setExpandedItemIndex] = useState(null);
  const navigation = useNavigate();

  const getDailyOutput = async () => {
    const server_response = await ajaxDailyOutput.fetchOutput(id);

    if (server_response.status === "OK") {
      setDailyOuputList(server_response.details);
    }
  };

  useEffect(() => {
    getDailyOutput();
  }, [id]);

  const toggleDots = (index) => {
    if (expandedItemIndex === index) {
      setExpandedItemIndex(null); // Collapse the currently expanded item
    } else {
      setExpandedItemIndex(index); // Expand the clicked item
    }
  };

  const [modal, setModal] = useStateCallback(false);

  const handleModalViewOutput = (e, item) => {
    setExpandedItemIndex(null);
    setModal(false, () =>
      setModal(
        <ViewOutput
          teamId={item.team.team_id}
          teamName={item.team.team_name}
          achievedWork={item.achieved_work}
          outputId={item.output_id}
          site={item.site}
          getDailyOutput={getDailyOutput}
          isOpen={true}
        />
      )
    );
  };

  const handleModalRollCall = (e, item, output) => {
    e.preventDefault();

    setModal(false, () =>
      setModal(
        <Rollcall
          team={item}
          output={output}
          navigation={navigation}
          isOpen={true}
          getDailyOutput={getDailyOutput}
        />
      )
    );
  };

  return (
    <AppContainer title="Daily Output">
      {modal}
      <Link
        onClick={() => navigation(-1)}
        className="btn btn-primary btn-sm mb-2">
        Back To Previous Page
      </Link>
      <div className="card custom-card">
        <div className="card-body">
          <div className="mb-4">
            <h6 className="card-title mb-4">
              Task Board
              <Link to="daily/create">
                <button
                  type="submit"
                  style={{ float: "right", color: "white" }}
                  className="btn btn-sm btn-success mb-4">
                  Add Output
                </button>
              </Link>
            </h6>
          </div>

          <div className="table-responsive">
            <div className="border-top mt-3"></div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Achieved Work</th>
                  <th>Site</th>
                  <th>Date</th>
                  <th style={{ width: 250 }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(dailyOuputList) &&
                  dailyOuputList.map((item, key) => (
                    <tr key={key}>
                      <td>{item.team.team_name}</td>
                      <td>{item.achieved_work}</td>
                      <td>{item.site}</td>
                      <td>{item.created_at.short_date}</td>

                      <td style={{ display: "flex" }}>
                        <Link
                          className="btn btn-primary btn-sm mr-3"
                          href="#"
                          onClick={(e) => handleModalViewOutput(e, item)}>
                          <i
                            className="fe fe-eye"
                            style={{ color: "white" }}></i>{" "}
                          View
                        </Link>
                        {Array.isArray(item.rollcall) && ""}
                        {!Array.isArray(item.rollcall) && (
                          <Link
                            className="btn btn-success btn-sm mr-5"
                            onClick={(e) =>
                              handleModalRollCall(e, item, item.output_id)
                            }>
                            <i
                              className="fe fe-user-check mr-1"
                              style={{ color: "white", fontSize: 16 }}></i>
                            Rollcall
                          </Link>
                        )}
                        {Array.isArray(item.payment) && ""}
                        {Array.isArray(item.rollcall) &&
                          !Array.isArray(item.payment) && (
                            <Link
                              className="btn btn-secondary btn-sm mr-2"
                              style={{ width: 120, color: "white" }}
                              to={`pay/${item.output_id}`}>
                              <i
                                className="fe fe-check-circle"
                                style={{ color: "white" }}></i>{" "}
                              Make Payment
                            </Link>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ListDailyOutput;

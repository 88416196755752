import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const UpdateJoint = (props) => {
  const [wkt_geom, setWkt_geom] = useState(props.wkt_geom);
  const [fid, setFid] = useState(props.fid);
  const [city, setCity] = useState(props.city);
  const [name, setName] = useState(props.name);
  const [template, setTemplate] = useState(props.template);
  const [pole_location, setPoleLocation] = useState(props.pole_location);
  const [manhole_location, setManholeLocation] = useState(
    props.manhole_location
  );
  const [trunk, setTrunk] = useState(props.trunk);
  const [joint_droit, setJointDroit] = useState(props.joint_droit);
  const [cores, setCores] = useState(props.cores);
  const [nb_splice_joint, setNBSpliceJoint] = useState(props.nb_splice_joint);
  const [cable_entree, setCable_entree] = useState(props.cable_entree);
  const [nb_splice_est, setNb_splice_est] = useState(props.nb_splice_est);
  const [nb_splice_est_12, setNb_splice_est_12] = useState(
    props.nb_splice_est_12
  );
  const [nb_splice_est_48, setNb_splice_est_48] = useState(
    props.nb_splice_est_48
  );

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(props);
    if (
      props.batch.length > 0 &&
      props.id * 1 > 0 &&
      wkt_geom.length > 0 &&
      fid.length > 0 &&
      city.length > 0 &&
      name.length > 0 &&
      template.length > 0 &&
      pole_location.length > 0 &&
      trunk.length > 0 &&
      cores.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProject.updateJoint(
        props.joint_id,
        props.id,
        props.batch,
        wkt_geom,
        fid,
        city,
        name,
        pole_location,
        manhole_location,
        template,
        trunk,
        cores,
        nb_splice_est,
        nb_splice_est_12,
        nb_splice_est_48,
        joint_droit,
        nb_splice_joint,
        cable_entree
      );

      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update Joint Details
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Joint Details"
      id="model-update-joint"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Wkt Geom</label>
        <input
          type="text"
          value={wkt_geom}
          placeholder={"wkt_geom"}
          onChange={(e) => setWkt_geom(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Fid</label>
        <input
          type="text"
          value={fid}
          placeholder={"fid"}
          onChange={(e) => setFid(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">City</label>
        <input
          type="text"
          value={city}
          placeholder={"city"}
          onChange={(e) => setCity(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Name</label>
        <input
          type="text"
          value={name}
          placeholder={"name"}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Pole Location</label>
        <input
          type="text"
          value={pole_location}
          placeholder={"pole_location"}
          onChange={(e) => setPoleLocation(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Manhole Location</label>
        <input
          type="text"
          value={manhole_location}
          placeholder={"manhole_location"}
          onChange={(e) => setManholeLocation(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Template</label>
        <input
          type="text"
          value={template}
          placeholder={"template"}
          onChange={(e) => setTemplate(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Cores</label>
        <input
          type="text"
          value={cores}
          placeholder={"cores"}
          onChange={(e) => setCores(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb splice est</label>
        <input
          type="text"
          value={nb_splice_est}
          placeholder={"nb_splice_est"}
          onChange={(e) => setNb_splice_est(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb splice est 12</label>
        <input
          type="text"
          value={nb_splice_est_12}
          placeholder={"nb_splice_est_12"}
          onChange={(e) => setNb_splice_est_12(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Nb splice est 48</label>
        <input
          type="text"
          value={nb_splice_est_48}
          placeholder={"nb_splice_est_48"}
          onChange={(e) => setNb_splice_est_48(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Joint Droit</label>
        <input
          type="text"
          value={joint_droit}
          placeholder={"joint_droit"}
          onChange={(e) => setJointDroit(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">NB splice_droit</label>
        <input
          type="text"
          value={nb_splice_joint}
          placeholder={"nb_splice_joint"}
          onChange={(e) => setNBSpliceJoint(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Cable entree</label>
        <input
          type="text"
          value={cable_entree}
          placeholder={"cable_entree"}
          onChange={(e) => setCable_entree(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateJoint;

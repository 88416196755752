import React, { useContext, useEffect, useState} from "react";
import AuthContext from "../../Context/AuthContext";
import { Link } from 'react-router-dom'

export default function WelcomeCard() {
    const {user} = useContext(AuthContext);
    const [showWelcome, setShowWelcome] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, []);
    
    return (
        <div class="card" style={{marginBottom: "24px", borderRadius: "10px"}}>
            
            <div class="bg-soft" style={{backgroundColor: "whitesmoke"}}>
                <div class="row">
                    <div class="col-7">
                        <div class="text-primary p-3">
                        {showWelcome && (<h5 class="text-primary">Welcome Back!</h5>)}
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-0 card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div className="main-avatar" style={{marginLeft: "5px", marginTop:"-15px", marginBottom: "1.5rem", fontSize:"40px", height: "85px", width: "85px", backgroundColor: "#8760fb", borderRadius:"50%"}}>
                            {user.short_name}
                        </div>
                        <h5 class="font-size-15 text-truncate">{user.first_name} {user.last_name}</h5>
                        <p class="text-muted mb-0 text-truncate">{user.role?user.role.role_name:""}</p>
                    </div>

                    <div class="col-sm-6">
                        <div class="pt-4">
                            <div class="mt-4">
                                <Link to={`profile-user`} className="btn ripple btn-primary btn-sm">View Profile<i class="fe fe-arrow-right ms-1"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

import React, { useContext, useEffect, useState } from "react";
import ajaxUser from "../../util/remote/ajaxUser";
import Select from "react-select";
import { Toaster, toast } from "react-hot-toast";
import AppContainer from "../Structure/AppContainer";
import ClientContext from "../../Context/ClientContext";
import RegionContext from "../../Context/RegionContext";
import ajaxClient from "../../util/remote/ajaxClient";

export default function CreateClients() {
  const { getClientList } = useContext(ClientContext);
  const { regionList } = useContext(RegionContext);

  // useEffect(()=>{
  //     getSalesReps()
  // },[])

  const [contact, setContact] = useState("");
  const [name, setClient] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [district, setDistrict] = useState("");
  // const [latitude,setLatitude] = useState("")
  // const [longitude,setLongitude] = useState("")
  // const [salesrep,setSalesRep] = useState("")
  // const [salesrepList,setSalesRepList] = useState(false)

  // const getSalesReps =async()=>{
  //     const server_response = await ajaxUser.fetchSalesReps();
  //     if(server_response.status==="OK"){
  //
  //         setSalesRepList(server_response.details);
  //     }else{
  //
  //         setSalesRepList("404");
  //     }
  // }

  // const getLocation = () => {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //     });
  // }

  // useEffect(() => {
  //     if(!latitude || !longitude){
  //         getLocation();
  //     }
  // }, [latitude,longitude]);

  const resetForm = () => {
    setDescription("");
    setClient("");
    setEmail("");
    // setPhysicalAddress("");
    // setVillage("");
    setDistrict("");
    setContact("");
    // setLongitude("");
    // setSalesRep("");
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      district.length > 0 &&
      contact.length > 0 &&
      description.length > 0
    ) {
      const server_response = await ajaxClient.createProjectClient(
        name,
        district,
        email,
        contact,
        description
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getClientList();
        resetForm();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  return (
    <AppContainer title="Register New Client">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card" style={{ borderRadius: "10px" }}>
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4">Register New Client</h6>
              </div>

              <form onSubmit={(e) => handleAdd(e)} method="post">
                <div className="row">
                  <div className="mb-4 col-md-12">
                    <label htmlFor="">
                      Client Name:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setClient(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <label htmlFor="">
                      E-mail:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <label htmlFor="">
                      Description:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <label htmlFor="">
                      Contact:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <label htmlFor="">
                      District:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  {/* <div className="mb-4 col-md-6">
                                        <label htmlFor="">Latitude:<span className="tx-danger">*</span></label>
                                        <input type="text" value={latitude} onChange={(e)=>setLatitude(e.target.value)} className="form-control" readOnly/>
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Longitude:<span className="tx-danger">*</span></label>
                                        <input type="text" value={longitude} onChange={(e)=>setLongitude(e.target.value)} className="form-control" readOnly/>
                                    </div>
            
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Select Region:<span className="tx-danger">*</span></label>
                                        <Select
                                            onChange={(e)=>setRegion(e.region_id)}
                                            getOptionLabel ={(option)=>option.region_name}
                                            getOptionValue ={(option)=>option.region_id}
                                            isSearchable
                                            options={regionList}
                                            value={Array.isArray(regionList) && regionList.find(( value ) => value.region_id===region)}
                                        />
                                    </div>
                                    <div className="mb-4 col-md-6">
                                        <label htmlFor="">Sales Representative:<i><small>(must be assigned to a team)</small></i><span className="tx-danger">*</span></label>
                                        <Select
                                            onChange={(e)=>setSalesRep(e.id)}
                                            getOptionLabel ={(option)=>option.first_name}
                                            getOptionValue ={(option)=>option.id}
                                            isSearchable
                                            options={salesrepList}
                                            value={Array.isArray(salesrepList) && salesrepList.find(( value ) => value.id===salesrep)}
                                        />
                                    </div> */}
                </div>
                <div className="mb-4">
                  <input
                    type="submit"
                    style={{ float: "right" }}
                    className="btn btn-success"
                    value="Register Client"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

import React, { useEffect, useState } from "react";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import AppContainer from "../../Components/Structure/AppContainer";
import TableHeader from "../../Components/Common/TableHeader";

export default function AddProducts() {
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unitCost, setUnitCost] = useState("");

  const [loading, setLoading] = useState(false);
  const [noteInfo, setNoteInfo] = useState(false);
  const { id } = useParams();

  const [rows, setRows] = useState([
    { product: "", description: "", quantity: "", unitCost: "" },
  ]);

  const handleAddRow = () => {
    const newRow = { product: "", description: "", quantity: "", unitCost: "" };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const navigator = useNavigate();

  const handleProductChange = (index, selectedProduct) => {
    const updatedRows = [...rows];
    updatedRows[index].product = selectedProduct.id;
    setRows(updatedRows);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity = quantity;
    setRows(updatedRows);
  };

  const handleDescriptionChange = (index, description) => {
    const updatedRows = [...rows];
    updatedRows[index].description = description;
    setRows(updatedRows);
  };

  const handleUnitCostChange = (index, unitCost) => {
    const updatedRows = [...rows];
    updatedRows[index].unitCost = unitCost;
    setRows(updatedRows);
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Assuming you want to process all rows
    const validRows = rows.every((row) => row.quantity > 0 && row.product > 0);

    if (validRows) {
      for (const row of rows) {
        setLoading(true);
        const server_response = await ajaxProduct.createInventory(
          row.product,
          row.quantity,
          row.description,
          id,
          row.unitCost
        );
        setLoading(false);

        if (server_response.status === "OK") {
          toast.success(server_response.message);
          setTimeout(() => {
            navigator("/delivery-notes");
          }, 1500);
        }
      }
    } else {
      toast.error("Complete all fields in all rows and try again");
    }
  };

  const [productList, setProductList] = useState(false);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const getNoteInfo = async () => {
    const server_response = await ajaxProduct.fetchNoteInfo(id);

    if (server_response.status === "OK") {
      setNoteInfo(server_response.details);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getNoteInfo(id);
  }, [id]);

  return (
    <AppContainer title="Record Inventory">
      <Toaster />
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              {noteInfo && (
                <div class="d-lg-flex">
                  <h2 class="card-title mb-1">
                    Reference No.: {noteInfo.delivery_note}
                  </h2>
                  <div class="ml-auto">
                    <p class="mb-1">
                      <span class="font-weight-bold">Date :</span>{" "}
                      {noteInfo.date_added}
                    </p>
                    <p class="mb-1">
                      <span class="font-weight-bold">Client :</span>{" "}
                      {noteInfo.client?.name}
                    </p>

                    <p class="mb-0">
                      <span class="font-weight-bold">Received By:</span>{" "}
                      {noteInfo.received_by?.first_name}{" "}
                      {noteInfo.received_by?.last_name}
                    </p>
                  </div>
                </div>
              )}
              <hr class="mg-b-40" />

              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th class="wd-35p" scope="col">
                      Product
                    </th>
                    <th class="wd-10p" scope="col">
                      Quantity
                    </th>
                    <th class="wd-10p" scope="col">
                      Unit Cost
                    </th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((row, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <Select
                          onChange={(selectedProduct) =>
                            handleProductChange(index, selectedProduct)
                          }
                          getOptionLabel={(option) => option.product_name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={
                            Array.isArray(productList) ? productList : []
                          }
                          value={
                            Array.isArray(productList) &&
                            productList.find((value) => value.id === product)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          value={row.quantity}
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          value={row.unitCost}
                          onChange={(e) =>
                            handleUnitCostChange(index, e.target.value)
                          }
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={row.description}
                          onChange={(e) =>
                            handleDescriptionChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => handleDeleteRow(index)}
                          className="btn btn-danger btn-sm">
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5">
                      <button
                        onClick={handleAddRow}
                        className="btn btn-primary btn-sm mr-2">
                        Add Row
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer text-right">
              {loading && (
                <button disabled className="btn btn-success btn-sm">
                  Adding...
                </button>
              )}
              {!loading && (
                <button onClick={handleAdd} className="btn btn-success btn-md">
                  Save Products
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

import React, { useContext, useEffect, useState } from 'react'
import AppContainer from '../Components/Structure/AppContainer'
import { Link } from 'react-router-dom'
import { RenderSecure } from '../util/script/RenderSecure'
import UserContext from '../Context/UserContext'
import ajaxUser from '../util/remote/ajaxUser'
import { Toaster, toast } from 'react-hot-toast'
import Loader from '../Components/Common/Loader'

export default function UsersPage() {
    const {userList} = useContext(UserContext);
    const [usersSearch, setUsersSearch] = useState(false);
    const [query, setQuery] = useState("");

    const searchUsers = async (e) => {
        e.preventDefault();
        if (!query) {
            toast.error("Please enter first or last name of user.");
        }
        else{
            const server_response = await ajaxUser.searchUsers(query);
            if (server_response.status === "OK") {
                if (server_response.details.length === 0) {
                    // set the state to an empty array to show an empty table
                    setUsersSearch([]);
                } else {
                    setUsersSearch(server_response.details);
                }
            } else {
                setUsersSearch([]);
            }
          
        }
    };

    const setUsers = (e) =>{
        e.preventDefault()
        setUsersSearch(false)
        setQuery('')
    }

    useEffect(() => {
        if (query) {
            searchUsers();
        }
    }, [query]);

    return (
        <AppContainer title="Users List">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            
            <div className='row'>
                <div className="col-12 col-xl-12 col-md-12 col-sm-12">
                    <h6 className="card-title mb-4 d-flex justify-content-end">
                        <Link to={`/add-user`}>
                            <button className="btn ripple btn-primary btn-with-icon btn-sm"><i className="fe fe-plus"></i> Register New User</button>  
                        </Link>
                    </h6>
                </div> 
            </div>

            <div className="row clearfix" style={{marginRight: "-5px", marginLeft: "-5px"}}>
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                    <div className="card mb-1" style={{ borderRadius: ".55rem" }}>
                        <div className="card-body">
                            <div className="input-group mt-2" style={{ paddingRight: "5px"}}>
                                <input
                                    type="text"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    className="form-control search"
                                    placeholder="Search for..."
                                />
                            <div className="input-group-append">
                                <button
                                    onClick={(e) => searchUsers(e)}
                                    className="btn bg-primary btn-block text-white ml-2"
                                >
                                Search Users
                                </button>
                            </div>
                            <div className="input-group-append">
                                <button
                                    onClick={setUsers}
                                    className="btn bg-light btn-block text-white ml-2"
                                >
                                Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row clearfix" style={{marginRight: "-5px", marginLeft: "-5px"}}>
            {usersSearch && Array.isArray(usersSearch) ? ( usersSearch.length > 0 ? ( usersSearch.map((item, key) => (
                <div key={key} className="col-xl-3 col-lg-3 col-md-6" style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <div className="card" style={{ transition: "all 0.5s ease-in-out", borderRadius: ".55rem",borderColor: "#E6E9ED", position: "relative", width: "100%", marginBottom: "10px"}}>
                        <div className="card-body text-center" style={{margin: "0 !important", padding: "20px 20px", flex: "1 1 auto"}}>
                            <div className="main-avatar" style={{margin:"auto", fontSize:"40px",height: "80px", width: "80px", backgroundColor: "#8760fb", borderRadius:"50%"}}>
                                {item.short_name}
                            </div>
                            <h4 className="mt-3 mb-0">{item.first_name} {item.last_name}</h4>
                            <span>{item.email}</span>
                            <div className="btn-list text-center">
                                <Link to={`user-profile/${item.id}`} className="btn ripple btn-primary btn-md mt-2">View Profile</Link>
                            </div>
                  
                            <div className="row text-center mt-4">
                                <div className="col-12 border-right">
                                    <label className="mb-0">Role</label>
                                    <h6 className="font-10">{item.role.role_name}</h6>
                                </div>
                                {/* <div className="col-6">
                                    <label className="mb-0">Team</label>
                                    <h6 className="font-18">{item.team?item.team.team_name:"Not Assigned"}</h6>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            )))
            : (
                <p style={{margin:"auto"}}>No users found that match the search query.</p>
                )
            ) : (
            
                Array.isArray(userList) && userList.map((item,key)=> 
                    <div key={key} className="col-xl-3 col-lg-3 col-md-6" style={{paddingRight: "5px", paddingLeft: "5px"}}>
                        <div className="card" style={{ transition: "all 0.5s ease-in-out", borderRadius: ".55rem",borderColor: "#E6E9ED", position: "relative", width: "100%", marginBottom: "10px"}}>
                            <div className="card-body text-center" style={{margin: "0 !important", padding: "20px 20px", flex: "1 1 auto"}}>
                                <div className="main-avatar" style={{margin:"auto", fontSize:"40px",height: "80px", width: "80px", backgroundColor: "#8760fb", borderRadius:"50%"}}>
                                    {item.short_name}
                                </div>
                                <h4 className="mt-3 mb-0">{item.first_name} {item.last_name}</h4>
                                <span>{item.email}</span>
                                <div className="btn-list text-center">
                                    <Link to={`user-profile/${item.id}`} className="btn ripple btn-primary btn-md mt-2">View Profile</Link>
                                </div>
                  
                                <div className="row text-center mt-4">
                                    <div className="col-12 border-right">
                                        <label className="mb-0">Role</label>
                                        <h6 className="font-10">{item.role.role_name}</h6>
                                    </div>
                                    {/* <div className="col-5">
                                        <label className="mb-0">Team</label>
                                        <h6 className="font-18">{item.team?item.team.team_name:"Not Assigned"}</h6>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        
            {!userList && <Loader/>}
        </div>

             
        </AppContainer>
    )
}

import React, { useState } from 'react';
import AppContainer from '../Components/Structure/AppContainer'
import TableHeader from '../Components/Common/TableHeader';

const Sample = props => {
  const [rows, setRows] = useState([
    { id: 1, value: 0 },
    { id: 2, value: 0 },
    { id: 3, value: 0 },
  ]);

  const handleInputChange = (id, newValue) => {
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, value: newValue } : row
    );
    setRows(updatedRows);
  };

  const calculateTotal = () => {
    const total = rows.reduce((sum, row) => sum + Number(row.value), 0);
    return total;
  };

  return (
    <AppContainer title={"Sample"}>
      <div className="card custom-card" style={{borderRadius: "10px"}}>
                <div className="card-body map-card">
                   <TableHeader
                        title="calc"
                        subtitle="calc"   
                    />
                    <div className="border-top mt-3"></div>                    
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                            <thead>
          <tr>
            <th>ID</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>
                <input
                  type="number"
                  value={row.value}
                  onChange={e => handleInputChange(row.id, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total:</td>
            <td>{calculateTotal()}</td>
          </tr>
        </tfoot>
                            </table>
                        </div>
                    
				</div>
			</div>
  

            
    	
      
      	
      
      
      
      
        
                        

</AppContainer>
)
}

export default Sample;

import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import ajaxProduct from "../../util/remote/ajaxProduct";
import Select from "react-select";

const UpdateCableStatus = (props) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(props.status);
  const [install_date, setInstallDate] = useState(props.install_date);
  const [start_length, setStartLength] = useState(props.start_length);
  const [end_length, setEndLength] = useState(props.end_length);
  const [comment, setComment] = useState(props.comment);
  const [product, setProduct] = useState("");
  const [productList, setProductList] = useState(false);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (props.cableID > 0 || status.length > 0) {
      if (status === "INSTALLED" && install_date.length < 1) {
        toast.error("Select the installation date to proceed!");
      } else {
        setLoading(true);
        const server_response = await ajaxProject.updateCableStatus(
          props.cableID,
          status,
          install_date,
          start_length,
          end_length,
          comment,
          product
        );
        setLoading(false);

        if (server_response.status === "OK") {
          setStatus("");
          setInstallDate("");
          setStartLength("");
          setEndLength("");
          setProduct("");
          toast.success(server_response.message);
          props.f(props.batch);
        }
      }
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update Cable Status
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Cable Status"
      id="model-update-cable"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label>Status</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          required
          className="form-control">
          <option value="">--- select status ---</option>
          <option value="NOT INSTALLED">NOT INSTALLED</option>
          <option value="INSTALLED">INSTALLED</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="">Product Type</label>
        <Select
          onChange={(e) => setProduct(e.id)}
          getOptionLabel={(option) => option.product_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(productList) ? productList : []}
          value={
            Array.isArray(productList) &&
            productList.find((value) => value.id === product)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Install Date</label>
        <input
          type="date"
          value={install_date}
          onChange={(e) => setInstallDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Start Length</label>
        <input
          type="number"
          value={start_length}
          onChange={(e) => setStartLength(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">End Length</label>
        <input
          type="number"
          value={end_length}
          onChange={(e) => setEndLength(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Comment</label>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateCableStatus;

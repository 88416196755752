import { useContext, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxMeeting from "../../util/remote/ajaxMeeting";

const AddMinutes = (props) => {
  const [loading, setLoading] = useState(false);
  const [meeting_title, setMeetingTitle] = useState("");
  const [additional_file, setAdditionalFile] = useState("");
  const [minutes, setMinutes] = useState("");
  const [date_held, setDateHeld] = useState("");
  const [type, setType] = useState("");

  const handleAdd = async (e) => {
    e.preventDefault();

    if (
      meeting_title.length > 0 ||
      date_held.length > 0 ||
      additional_file.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxMeeting.createMeeting(
        meeting_title,
        minutes,
        type,
        date_held,
        additional_file
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setMeetingTitle("");
        setDateHeld("");
        setType("");
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const changeMinutesDoc = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setMinutes(e.target.result);
      // console.log(image)
    };
  };
  const changeAdditionalFile = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setAdditionalFile(e.target.result);
      // console.log(image)
    };
  };
  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save Minutes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Register Meeting Minutes"
      id="model-new-meeting"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Meeting Title</label>
        <input
          type="text"
          value={meeting_title}
          onChange={(e) => setMeetingTitle(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Meeting Type</label>
        <input
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label>
          <b>Minutes Document </b>
        </label>
        <input
          type="file"
          onChange={(e) => changeMinutesDoc(e)}
          multiple
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label>
          <b>Additional file </b>
        </label>
        <input
          type="file"
          onChange={(e) => changeAdditionalFile(e)}
          multiple
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Meeting Date</label>
        <input
          type="date"
          value={date_held}
          onChange={(e) => setDateHeld(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default AddMinutes;

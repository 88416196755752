import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TableHeader from "../../Components/Common/TableHeader";

function CompleteInstallations() {
  const [loading, setLoading] = useState(false);
  const [installations, setIstallations] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { projectID } = useParams();

  const [projectIDD, setProjectIDD] = useState();

  useEffect(() => {
    setProjectIDD(projectID);
    // listInstallations();
  }, [projectID]);

  useEffect(() => {
    listInstallations();
  }, [projectIDD]);

  const listInstallations = async () => {
    setLoading(true);
    if (searchQuery === "") {
    }
    var data = {
      status: 3,
      projectID: projectIDD * 1,
    };
    setIstallations("");
    const server_response = await ajaxProject.clistInstallations(data);

    setLoading(false);

    if (server_response.status === "OK") {
      setIstallations(server_response.details);
      // 3000 milliseconds = 3 seconds
    } else {
      setTimeout(() => {
        toast.error(server_response.message);
      }, 7000); // 3000 milliseconds = 3 seconds
    }
  };

  useEffect(() => {
    searchActiveInstallations();
  }, [searchQuery]);

  const searchActiveInstallations = async () => {
    setLoading(true);
    if (searchQuery != "") {
      const server_response = await ajaxProject.searchActiveInstalls(
        searchQuery
      );

      setLoading(false);

      if (server_response.status === "OK") {
        setSearchData(server_response.details);
      } else {
        setSearchData(server_response.details);
        toast.error(server_response.message);
      }
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription =
      "Payment complete installation for project_ _ _ _ _ _"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = [
      "No.",
      "Pole ID",
      "Pit Status",
      "Plant Status",
      "Plumb Status",
      "Images Uploaded",
      "Verified",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    // data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(
      `completed_installations_data__${new Date().toLocaleDateString()}.pdf`
    );
  };

  return (
    <AppContainer title="Complete Installations">
      <Toaster />

      <div className="card custom-card">
        <div className="card-body">
          <TableHeader
            title="Complete Installations"
            subtitle="List of all the complete installations"
            viewButton={
              <a
                href="#"
                onClick={exportToPDF}
                className="btn btn-secondary btn-sm mr-2"
                style={{ float: "right" }}>
                Export to PDF
              </a>
            }
          />
          {loading && <Loader />}
          <div className="border-top mt-3"></div>
          <div className="table-responsive">
            <table className="table  table-hover">
              <thead>
                <th>#NO</th>
                <th>#project</th>
                <th>Pole ID</th>
                <th>Pit Status</th>
                <th>Plant Status</th>
                <th>Plumb Status</th>
                <th>Images Uploaded</th>
                <th>Verified</th>
              </thead>
              <tbody>
                {searchQuery === ""
                  ? installations &&
                    Array.isArray(installations) &&
                    installations.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <b>{item.project}</b>{" "}
                        </td>
                        <td>
                          <Link to={`/installations/create/${item.id}`}>
                            <b>{item.pole_id}</b>{" "}
                          </Link>
                        </td>
                        <td>
                          {item.pit_status === null ? (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          ) : item.pit_status === "INSTALLED" ? (
                            <span className="badge badge-pill badge-success">
                              Installed
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning">
                              {item.status}
                            </span>
                          )}
                        </td>
                        <td>
                          {item.status === null ? (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          ) : item.status === "INSTALLED" ? (
                            <span className="badge badge-pill badge-success">
                              Installed
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning">
                              {item.status}
                            </span>
                          )}
                        </td>
                        <td>
                          {item.plumb_status === null ? (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          ) : item.plumb_status === "INSTALLED" ? (
                            <span className="badge badge-pill badge-success">
                              Installed
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning">
                              {item.status}
                            </span>
                          )}
                        </td>
                        <td>{item.img_count}</td>
                        <td>
                          {item.verified === "VERIFIED" && (
                            <span className="badge badge-pill badge-success">
                              Verified
                            </span>
                          )}
                          {item.verified !== "VERIFIED" && (
                            <Link
                              to={`/installations/create/${item.id}/complete`}
                              className="badge badge-pill badge-danger">
                              Not verified
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))
                  : Array.isArray(searchData) &&
                    searchData.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Link to={`/installations/create/${item.id}`}>
                            <b>{item.pole_id}</b>{" "}
                          </Link>
                        </td>
                        <td>
                          {item.pit_status === "INSTALLED" && (
                            <span className="badge badge-pill badge-success">
                              Done
                            </span>
                          )}
                          {item.pit_status !== "INSTALLED" && (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          )}
                        </td>
                        <td>
                          {item.status === "INSTALLED" && (
                            <span className="badge badge-pill badge-success">
                              Done
                            </span>
                          )}
                          {item.status !== "INSTALLED" && (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          )}
                        </td>
                        <td>
                          {item.plumb_status === "INSTALLED" && (
                            <span className="badge badge-pill badge-success">
                              Done
                            </span>
                          )}
                          {item.plumb_status !== "INSTALLED" && (
                            <span className="badge badge-pill badge-danger">
                              Pending
                            </span>
                          )}
                        </td>
                        <td>{item.img_count}</td>
                        <td>
                          {item.verified === "VERIFIED" && (
                            <Link
                              to={`/installations/create/${item.id}/complete`}
                              className="badge badge-pill badge-success">
                              Verified
                            </Link>
                          )}
                          {item.verified !== "VERIFIED" && (
                            <span className="badge badge-pill badge-danger">
                              Not verified
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default CompleteInstallations;

import { useState } from "react"
import { toast } from 'react-hot-toast';
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";

const CreateOutput=(props)=>{

    const [loading, setLoading] = useState(false)
    const [achieved_work,setAchievedWork] =useState("")
    const [comment,setComment] =useState("")
    const handleAddOutput = async(e) =>{
        e.preventDefault()
    
        if(achieved_work.length>0){
        setLoading(true)
    
          const server_response = await ajaxDailyOutput.updateOutput(props.output_id,achieved_work,comment)
          setLoading(false) 
          if(server_response.status==="OK"){
            toast.success(server_response.message)
            props.f(props.team_id)
          }
          else{
            toast.error(server_response.message)
          }
      }
      else{
        toast.error("Complete all fields and try again")
      }
      }
    

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={handleAddOutput}>Save Output</button>
                    </>
        }
    }

    return(
        <SystemModal
            title="record Output"
            id="model-new-pass"
            size="md"
            footer={RenderFooter}
        >

            <div className="mb-4">
                <label htmlFor="">Record Output</label>
                <input onChange={(e)=>setAchievedWork(e.target.value)} value={achieved_work} type="text" className="form-control"/>
            </div>
            <div className="mb-4">
                <label htmlFor="">Comment</label>
                <input onChange={(e)=>setComment(e.target.value)} value={comment} type="text" className="form-control"/>
            </div>
        
       
        </SystemModal>
    )
}

export default CreateOutput

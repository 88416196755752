import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async createTask(
    project_id,
    task_name,
    duration,
    daily_expectation,
    total,
    unit_m
  ) {
    let data = {
      project_id: project_id,
      task_name: task_name,
      duration: duration,
      daily_expectation: daily_expectation,
      total: total,
      unit_m: unit_m,
    };
    let response = await apiCall("task/create", data);
    return response;
  },
  async updateTask(
    task_id,
    task_name,
    daily_expectation,
    duration,
    total,
    unit_m
  ) {
    let data = {
      task_id: task_id,
      task_name: task_name,
      daily_expectation: daily_expectation,
      duration: duration,
      total: total,
      unit_m: unit_m,
    };
    let response = await apiCall("task/update", data);
    return response;
  },
  async createTaskAllocation(task_id, team_id, site, task_date, achieved_work) {
    let data = {
      task_id: task_id,
      team_id: team_id,
      site: site,
      task_date: task_date,
      achieved_work: achieved_work,
    };

    let response = await apiCall("task_allocation/create", data);
    return response;
  },
  async fetchTasks(project_id) {
    let data = {
      project_id: project_id,
    };
    let response = await apiCall("task/list", data);

    return response;
  },
  async fetchTasksPerTeam(team_id) {
    let data = {
      team_id: team_id,
    };
    let response = await apiCall("task_allocation/list/tasks", data);

    return response;
  },

  async fetchAllTasksPerTeam(team_id) {
    let data = {
      team_id: team_id,
    };
    let response = await apiCall("task_allocation/list/tasks/all", data);

    return response;
  },

  async countTasks(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("target/create", data);

    return response;
  },

  async removeTask(task_id, team_id) {
    let data = {
      task_id: task_id,
      team_id: team_id,
    };

    let response = await apiCall("task_allocation/delete", data);
    return response;
  },

  async fetchTask(task_id) {
    let data = {
      task_id: task_id,
    };

    let response = await apiCall("task/list/single", data);

    return response;
  },
  async fetchCompletedTasks(project_id) {
    let data = {
      project_id: project_id,
    };
    let response = await apiCall("task/list/completed", data);

    return response;
  },
  async endTask(task_id) {
    let data = {
      task_id: task_id,
    };
    let response = await apiCall("task/end", data);

    return response;
  },
  async fetchTaskAllocationInfo(task_allocation_id) {
    let data = {
      task_allocation_id: task_allocation_id,
    };

    let response = await apiCall("task_allocation/info", data);

    return response;
  },
  async createMainTask(task_name) {
    let data = {
      task_name: task_name,
    };
    let response = await apiCall("main_task/create", data);
    return response;
  },
  async updateMainTask(taskId, task_name) {
    let data = {
      task_id: taskId,
      task_name: task_name,
    };
    let response = await apiCall("main_task/update", data);
    return response;
  },
  async getMainTasksList(data) {
    let response = await apiCall("main_task/list", data);
    return response;
  },
};

import React from 'react'
import AppContainer from '../../Components/Structure/AppContainer'
import ListAllTasks from './ListAllTasks'

function Tasks() {
  return (
    <AppContainer title="Tasks">
        <ListAllTasks/>
    </AppContainer>
  )
}

export default Tasks
import { useEffect, useState } from "react";
import Select from "react-select";
import ajaxTask from "../../util/remote/ajaxTask";
import Alert from "../Common/Alert";
import Loader from "../Common/Loader";
import SystemModal from "../Common/SystemModal";

const UpdateTask = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [taskName, setTaskName] = useState(props.taskName);
  const [taskList, setTaskList] = useState(false);
  const [dailyExpectation, setDailyExpectation] = useState(
    props.dailyExpectation
  );
  const [unit_m, setUnit_measure] = useState(props.unit_measure);
  const [total, setTotal] = useState(props.total);
  const [duration, setDuration] = useState(props.duration);

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks(props.projectID);
    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (props.taskID > 0 || taskName.length > 0) {
      setLoading(true);
      const server_response = await ajaxTask.updateTask(
        props.taskID,
        taskName,
        dailyExpectation,
        duration,
        total,
        unit_m
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setInfo(<Alert type="success" message={server_response.message} />);
        props.f(props.projectID);
      }
    } else {
      setInfo(<Alert type="danger" message="Complete all fields" />);
    }
  };

  useEffect(() => {
    getTasks(props.projectID);
  }, [props.projectID]);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Task"
      id="model-update-task"
      size="md"
      footer={RenderFooter}>
      {info}
      <div className="mb-4">
        <label htmlFor="">Task Name</label>
        <Select
          onChange={(e) => setTaskName(e.task_name)}
          getOptionLabel={(option) => option.task_name}
          getOptionValue={(option) => option.task_name}
          isSearchable
          options={Array.isArray(taskList) ? taskList : []}
          value={
            Array.isArray(taskList) &&
            taskList.find((value) => value.task_name === taskName)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Daily Expectation</label>
        <input
          type="number"
          value={dailyExpectation}
          onChange={(e) => setDailyExpectation(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Duration</label>
        <input
          type="number"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4 ">
        <label htmlFor="">Total:</label>
        <input
          type="number"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
          className="form-control"
        />
      </div>{" "}
      <div className="mb-4">
        <label htmlFor="">Unit of Measure:</label>
        <input
          type="text"
          value={unit_m}
          onChange={(e) => setUnit_measure(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateTask;

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

export default function PermissionGroup() {

    const [open, setOpen] = useState(false);


    
  return (
    <>
    
    <div className="container">
	<div className="col-md-12">
    	<div className="panel panel-default">
				<div className="panel-heading">
					Employee
				</div>
        <div className="panel-body">
					<table className="table table-condensed table-striped">
    <thead>
        <tr>
					<th></th>
          <th>Fist Name</th>
          <th>Last Name</th>
          <th>City</th>
          <th>State</th>
          <th>Status</th>
        </tr>
    </thead>

    <tbody>
        <tr >
           <td><button  onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open} className="btn btn-default btn-xs"><i className="fa fa-eye"></i></button></td>
            <td>Carlos</td>
            <td>Mathias</td>
            <td>Leme</td>
            <td>SP</td>
          	<td>new</td>
        </tr>
			
        <tr>
            <td colspan="12" className="hiddenRow">
      <Collapse in={open}>

							<div className="accordian-body collapse" id="demo1"> 
              <table className="table table-striped">
                      <thead>
                        <tr className="info">
													<th>Job</th>
													<th>Company</th>
													<th>Salary</th>		
													<th>Date On</th>	
													<th>Date off</th>	
													<th>Action</th>	
												</tr>
											</thead>	
								  		
											<tbody>
												
                        <tr data-toggle="collapse"  className="accordion-toggle" data-target="#demo10">
													<td> <a href="#">Enginner Software</a></td>
													<td>Google</td>
													<td>U$8.00000 </td>
													<td> 2016/09/27</td>
													<td> 2017/09/27</td>
													<td> 
														<a href="#" className="btn btn-default btn-sm">
                 								 <i className="glyphicon glyphicon-cog"></i>
															</a>
													</td>
												</tr>
												
												 <tr>
            <td colspan="12" className="hiddenRow">
							<div className="accordian-body collapse" id="demo10"> 
              <table className="table table-striped">
                      <thead>
                        <tr>
													<td><a href="#"> XPTO 1</a></td>
													<td>XPTO 2</td>
													<td>Obs</td>
												</tr>
                        <tr>
													<th>item 1</th>
													<th>item 2</th>
													<th>item 3 </th>
													<th>item 4</th>
													<th>item 5</th>
													<th>Actions</th>
												</tr>
                      </thead>
                      <tbody>
                        <tr>
													<td>item 1</td>
													<td>item 2</td>
													<td>item 3</td>
													<td>item 4</td>
													<td>item 5</td>
													<td>
															<a href="#" className="btn btn-default btn-sm">
                  							<i className="glyphicon glyphicon-cog"></i>
															</a>
													</td>
												</tr>
                      </tbody>
               	</table>
              
              </div> 
              
          </td>
        </tr>
																										
                        <tr>
													<td>Scrum Master</td>
													<td>Google</td>
													<td>U$8.00000 </td>
													<td> 2016/09/27</td>
													<td> 2017/09/27</td>
													<td> <a href="#" className="btn btn-default btn-sm">
                 								 <i className="glyphicon glyphicon-cog"></i>
															</a>
													</td>
												</tr>
												
														
                        <tr>
													<td>Back-end</td>
													<td>Google</td>
													<td>U$8.00000 </td>
													<td> 2016/09/27</td>
													<td> 2017/09/27</td>
													<td> <a href="#" className="btn btn-default btn-sm">
                 								 <i className="glyphicon glyphicon-cog"></i>
															</a>
													</td>
												</tr>
												
														
                        <tr>
													<td>Front-end</td>
													<td>Google</td>
													<td>U$8.00000 </td>
													<td> 2016/09/27</td>
													<td> 2017/09/27</td>
													<td> <a href="#" className="btn btn-default btn-sm">
                 								 <i className="glyphicon glyphicon-cog"></i>
															</a>
													</td>
												</tr>
								
               
                      </tbody>
               	</table>
              
              </div> 
      </Collapse>

          </td>
        </tr>
      
      
			
        <tr data-toggle="collapse" data-target="#demo2" className="accordion-toggle">
             <td><button className="btn btn-default btn-xs"><span className="glyphicon glyphicon-eye-open"></span></button></td>
             <td>Silvio</td>
            <td>Santos</td>
            <td>São Paulo</td>
            <td>SP</td>
          <td> new</td>
        </tr>
        <tr>
            <td colspan="6" className="hiddenRow"><div id="demo2" className="accordian-body collapse">Demo2</div></td>
        </tr>
        <tr data-toggle="collapse" data-target="#demo3" className="accordion-toggle">
            <td><button className="btn btn-default btn-xs"><span className="glyphicon glyphicon-eye-open"></span></button></td>
            <td>John</td>
            <td>Doe</td>
            <td>Dracena</td>
            <td>SP</td>
          <td> New</td>
        </tr>
        <tr>
            <td colspan="6" className="hiddenRow"><div id="demo3" className="accordian-body collapse">Demo3 sadasdasdasdasdas</div></td>
        </tr>
    </tbody>
</table>
            </div>
        
          </div> 
        
      </div>
	</div>
    </>
       
  )
}

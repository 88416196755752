import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import AuthContext from "../../Context/AuthContext";

export default function RecentPayments() {
  const [payments, setPayments] = useState(false);
  const { user, userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const getUserPayments = async () => {
    setLoading(true);
    const server_response = await ajaxPayRate.fetchPayments(userId);
    setLoading(false);
    if (server_response.status === "OK") {
      setPayments(server_response.details);
    } else {
      setPayments("404");
    }
  };

  useEffect(() => {
    getUserPayments();
  }, [userId]);

  return (
    <div className="col-lg-6">
      <div className="card custom-card" style={{ borderRadius: "10px" }}>
        <div className="card-body map-card">
          <TableHeader
            title="My payments"
            subtitle="Payments made sorted according to most recent"
          />
          <div className="border-top mt-3"></div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap mg-b-0">
              <thead>
                <tr>
                  <th scope="col">Payment Date</th>
                  <th scope="col"> Task Name</th>
                  <th scope="col">Work Achieved</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(payments) && payments.length > 0 ? (
                  payments.map((item, key) => (
                    <tr key={key}>
                      <td>{item.created_at.long_date}</td>
                      <td>{item.output.task_allocation_id.task.task_name}</td>
                      <td>
                        {item.output.achieved_work
                          ? item.output.achieved_work
                          : "Not yet recorded"}
                      </td>
                      <td> {item.amount} </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      No payments made yet
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
}

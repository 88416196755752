import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const UpdateCross = (props) => {
    const [wkt_geom, setWkt_geom] = useState(props.wkt_geom);
    const [fid, setFid] = useState(props.fid);
    const [name, setName] = useState(props.name);
    const [cable_attachment, setCableAttachment] = useState(props.cable_attachment);
    const [initial_length, setInitialLength] = useState(props.initial_length);
    const [trunk, setTrunk] = useState(props.trunk);
    const [location, setLocation] = useState(props.location);
    const [contractor, setContractor] = useState(props.contractor);

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(props);
    if (
        props.batch.length > 0 &&
        props.id * 1 > 0 &&
        wkt_geom.length > 0 &&
        fid.length > 0 &&
        name.length > 0 &&
        cable_attachment.length > 0 &&
        initial_length.length > 0 &&
        trunk.length > 0 &&
        location.length > 0 &&
        contractor.length > 0
    ) {
        setLoading(true);
        const server_response = await ajaxProject.updateCross(props.cross_id,props.id, props.batch, wkt_geom,fid,name,cable_attachment,initial_length,trunk,location,contractor);
        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}
          >
            Update Cross Details
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Cross Details"
      id="model-update-cross"
      size="md"
      footer={RenderFooter}
    >
      <div className="mb-4">
        <label htmlFor="">Wkt Geom</label>
        <input
          type="text"
          value={wkt_geom}
          placeholder={"wkt_geom"}
          onChange={(e) => setWkt_geom(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Fid</label>
        <input
          type="text"
          value={fid}
          placeholder={"fid"}
          onChange={(e) => setFid(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Name</label>
        <input
          type="text"
          value={name}
          placeholder={"name"}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Cable Attachment</label>
        <input
          type="text"
          value={cable_attachment}
          placeholder={"cable_attachment"}
          onChange={(e) => setCableAttachment(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Initital Length</label>
        <input
          type="text"
          value={initial_length}
          placeholder={"initial_length"}
          onChange={(e) => setInitialLength(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Location</label>
        <input
          type="text"
          value={location}
          placeholder={"location"}
          onChange={(e) => setLocation(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Contractor</label>
        <input
          type="text"
          value={contractor}
          placeholder={"contractor"}
          onChange={(e) => setContractor(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateCross;

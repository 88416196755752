import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async getIssueLogList(data) {
       
        let response = await apiCall("issue/list", data)
        return response;
       
      },
      async getIssueLogToday(data) {
       
        let response = await apiCall("issue/today", data)
        return response;
       
      },
      async fetchHandledIssueLogs(data) {
       
        let response = await apiCall("issue/handled", data)
        return response;
       
      },
      async fetchNotHandledIssueLogs(data) {
       
        let response = await apiCall("issue/not_handled", data)
        return response;
       
      },
      async fetchLogsNumber(data) {
       
        let response = await apiCall("issue/count", data)
        return response;
       
      },
      async createIssueLog(project_id,log_details)
      {
          let data={
            "project_id":project_id,
            "log_details":log_details
          }
          let response = await apiCall("issue/create", data)
          return response;
      },

      async assignIssueLog(log_id,user_id)
      {
          let data={
            "log_id":log_id,
            "user_id":user_id
          }
          let response = await apiCall("issue/assign", data)
          return response;
      },

      async fetchLogInfo(log_id)
      {
          let data={
              "log_id":log_id
          }
          let response = await apiCall("issue/info", data)
  
          return response;
  
      },
      async updateStatus(log_id)
      {
          let data={
            "log_id":log_id
          }
          let response = await apiCall("issue/status", data)
          return response;
      },

      async updateIssueLog(log_id,user_id,project_id,log_details,status)
      {
          let data={
            "log_id":log_id,
            "user_id":user_id,
            "log_details":log_details,
            "project_id":project_id,
            "status":status
          }
          let response = await apiCall("issue/update", data)
          return response;
      }



}
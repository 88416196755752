import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";

function ListOTBsBatches() {
  const [loading, setLoading] = useState(false);
  const [batchList, setBatchList] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    listBatches();
  }, [id]);

  const listBatches = async () => {
    setLoading(true);
    const server_response = await ajaxProject.fetchOTBBatches(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setBatchList(server_response.details);
    }
  };

  const deleteBatch = async (batch) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this import batch"
    );
    if (!confirm) {
      return;
    }
    setLoading(true);
    setBatchList(false);
    const server_response = await ajaxProject.deleteOTBBatch(batch);
    setLoading(false);
    if (server_response.status === "OK") {
      listBatches();
      toast.success(server_response.message);
    } else {
      toast.error(server_response.message);
    }
  };

  return (
    // <AppContainer title="Task Board">
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="card custom-card">
        <div className="card-body">
          <div className="mb-4">
            <h6 className="card-title mb-4">Upload Batches</h6>
          </div>

          <div className="table-responsive">
            <div className="border-top mt-3"></div>
            {loading && <Loader />}

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#NO</th>
                  <th>Project</th>
                  <th>View</th>
                  <th>Batch No</th>
                  <th>OTBs Count</th>
                  <th>Date Added</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(batchList) &&
                  batchList.map((item, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{item.project}</td>
                      <td>
                        <Link
                          className="btn btn-success btn-sm"
                          to={`/project/otbs/${id}/list/${item.batch_no}`}
                        >
                          View OTBs
                        </Link>
                      </td>
                      <td>{item.batch_no}</td>
                      <td>{item.count}</td>
                      <td>{item.created_at.long_date}</td>
                      <td>
                        <a
                          className="btn btn-danger btn-sm"
                          onClick={() => deleteBatch(item.batch_no)}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
    // </AppContainer>
  );
}

export default ListOTBsBatches;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import AuthContext from "../../Context/AuthContext";
import ajaxUser from "../../util/remote/ajaxUser";

export default function MyTeams() {
  const [teams, setTeams] = useState(false);
  const { user, userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const getUserTeams = async () => {
    setLoading(true);
    const server_response = await ajaxUser.fetchUserTeams(userId);
    setLoading(false);
    if (server_response.status === "OK") {
      setTeams(server_response.details);
    } else {
      setTeams("404");
    }
  };

  useEffect(() => {
    getUserTeams();
  }, [userId]);

  return (
    <div className="col-lg-6">
      <div className="card custom-card" style={{ borderRadius: "10px" }}>
        <div className="card-body map-card">
          <TableHeader title="My Teams" subtitle="List of teams allocated" />
          <div className="border-top mt-3"></div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap mg-b-0">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col"> Team Name</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(teams) && teams.length > 0 ? (
                  teams.map((item, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        {" "}
                        <span>
                          <Link to={`/teams/team-profile/${item.team.team_id}`}>
                            {" "}
                            {item.team.team_name}
                          </Link>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" style={{ textAlign: "center" }}>
                      No teams allocated
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
}

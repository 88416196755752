import React, { useRef, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ajaxTask from "../../util/remote/ajaxTask";
import TableHeader from "../Common/TableHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ViewHSEReport() {
  const [loading, setLoading] = useState(false);
  const cardRef = useRef(null);
  const [hseList, setHseList] = useState(false);
  const [allocationInfo, setAllocationInfo] = useState(false);
  const {id} = useParams();

  const exportAsPDF = () => {
    const unit = "pt";
    const size = "A4";
  
    const marginLeft = 40;
    const doc = new jsPDF("p", unit, size);
  
    const source = cardRef.current;
  
    const excludeImages = source.querySelectorAll('img'); // Get all img elements
  
    // Hide all images before generating PDF
    excludeImages.forEach(img => {
      img.style.display = 'none';
    });
  
    doc.html(source, {
      callback: function (pdf) {
        pdf.save("hse_report.pdf");
  
        // After generating PDF, show the images again
        excludeImages.forEach(img => {
          img.style.display = 'block'; // Or set it to the initial display style
        });
      },
      x: marginLeft,
      y: 40,
      html2canvas: { scale: 0.5 },
    });
  };
  

  const getAllocationInfo = async () => {
    const server_response = await ajaxTask.fetchTaskAllocationInfo(id);

    if (server_response.status === "OK") {
      setAllocationInfo(server_response.details);
    }
  };

  useEffect(() => {
    getAllocationInfo(id);
  }, [id]);

  return (
    <AppContainer title="View HSE Report">
     
     <div class="row">
		<div class="col-lg-12 col-md-12">
                    <TableHeader
                        viewButton={
                         <a href="#" onClick={exportAsPDF}  className="btn btn-secondary btn-sm mb-3" style={{float:"right"}}>Export as PDF</a>
                        }  
                     />
                
            
		</div>
	</div>
      <div class="row">
				<div class="col-lg-12 col-md-12">
					<div class="card custom-card" id="report-section" ref={cardRef}>
						<div class="card-body">
                       
              <div className="table-heade align-items-center" style={{textAlign:"center"}}>
                <div>
                  <h5 className="card-title mb-3">RISK ASSESSMENT FORM </h5>
                </div>
              </div>

              <div class="row">
									{allocationInfo && <div class="col-lg-6">
										<div class="row row-xs align-items-center mg-b-20" >
											<div class="col-md-3">
												<label class="mg-b-0">Project Name:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input type="text" value={allocationInfo.task?.project?.project_name} readOnly className="form-control"/>
											</div>
										</div>
                    <div class="row row-xs align-items-center mg-b-20">
											<div class="col-md-3">
												<label class="mg-b-0">Task Assessed:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="text" value={allocationInfo.task?.task_name} readOnly className="form-control"/>
											</div>
										</div>
										<div class="row row-xs align-items-center mg-b-20">
											<div class="col-md-3">
												<label class="mg-b-0">Location:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="text" value={allocationInfo.hse_report?.location} readOnly className="form-control"/>
											</div>
										</div>
                    
									</div>}
                  {allocationInfo && <div class="col-lg-6">
										<div class="row row-xs align-items-center mg-b-20" >
											<div class="col-md-3">
												<label class="mg-b-0">Site Name:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input type="text" value={allocationInfo.site} readOnly className="form-control"/>
											</div>
										</div>
                    <div class="row row-xs align-items-center mg-b-20">
											<div class="col-md-3">
												<label class="mg-b-0">Task Date:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="text" value={allocationInfo.task_date} readOnly className="form-control"/>
											</div>
										</div>
										<div class="row row-xs align-items-center mg-b-20">
											<div class="col-md-3">
												<label class="mg-b-0">Assessment Date:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-0">
                      <input type="date" value={allocationInfo.hse_report?.report_date} readOnly className="form-control"/>
											</div>
										</div>
									</div>}</div>
									<hr class="mg-b-20"/>
                  <p class="mb-1"><span class="font-weight-bold">ATTENTION! FOR EACH QUESTION, SELECT THE CIRCLE WITH YOUR APPROPRIATE ANSWER</span></p>
									
                  <p class="mt-1"><span class="font-weight-bold">1. </span> Do the teams clearly understand what is required?</p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox">
                        <input name="teams_understand" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.teams_understand === 'Yes'}/>
                        <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_understand" type="radio" value="No" readOnly  checked={allocationInfo.hse_report?.teams_understand === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_understand" type="radio" value="N/A" readOnly checked={allocationInfo.hse_report?.teams_understand === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>
                  <p class="mt-1"><span class="font-weight-bold">2. </span> Are the teams trained to do the work and familiar with the equipment, tools and tasks assigned?</p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="teams_trained" type="radio" value="Yes" readOnly  checked={allocationInfo.hse_report?.teams_trained === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_trained" type="radio" value="No" readOnly checked={allocationInfo.hse_report?.teams_trained === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_trained" type="radio" value="N/A" readOnly checked={allocationInfo.hse_report?.teams_trained === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>
                  <p class="mt-1"><span class="font-weight-bold">3. </span> Are the tools and equipment in a good and safe condition?</p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="tools_condition" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.tools_condition === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="tools_condition" type="radio" value="No" readOnly checked={allocationInfo.hse_report?.tools_condition === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="tools_condition" type="radio" value="N/A" readOnly checked={allocationInfo.hse_report?.tools_condition === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>
                  <p class="mt-1">Provide a photo of the tools and equipment</p>
                  <img
                      alt="Tools Image"
                      style={{ height: "150px" }}
                      class="img-thumbnail"
                      src={allocationInfo.hse_report?.tools_photo}
                    />
                  <p class="mt-1"><span class="font-weight-bold">4. </span> Do the teams have approved documentation or instructions for the tasks assigned? </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="teams_doc" type="radio" value="Yes" readOnly  checked={allocationInfo.hse_report?.teams_doc === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_doc" type="radio" value="No" readOnly checked={allocationInfo.hse_report?.teams_doc === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="teams_doc" type="radio" value="N/A" readOnly checked={allocationInfo.hse_report?.teams_doc === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>
                  <p class="mt-1"><span class="font-weight-bold">5. </span> Are the other parties likely to be affected by the works informed? </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="other_parties" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.other_parties === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="other_parties" type="radio" value="No" readOnly  checked={allocationInfo.hse_report?.other_parties === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="other_parties" type="radio" value="N/A" readOnly  checked={allocationInfo.hse_report?.other_parties === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>
                  <p class="mt-1"><span class="font-weight-bold">6. </span> Do the teams have the right PPE for the works ongoing?  </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="right_ppe" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.right_ppe === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="right_ppe" type="radio" value="No" readOnly checked={allocationInfo.hse_report?.right_ppe === 'No'}/> <span>No</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="right_ppe" type="radio" value="N/A"readOnly checked={allocationInfo.hse_report?.right_ppe === 'N/A'}/> <span>N/A</span></label>
										</div>
									</div>  
                  <p class="mt-1">Provide a photo for the available PPE</p>
                  <img
                      alt="Tools Image"
                      style={{ height: "150px" }}
                      class="img-thumbnail"
                      src={allocationInfo.hse_report?.ppe_photo}
                    />
                <p class="mt-4"><span class="font-weight-bold">RISKS IDENTIFIED AND HAZARDS ASSESSED</span></p>
                <p class="mt-1"><span class="font-weight-bold">7. </span> Are there any hazards identified within the working area?   </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="hazards" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.hazards === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="hazards" type="radio" value="No" readOnly  checked={allocationInfo.hse_report?.hazards === 'No'}/> <span>No</span></label>
										</div>
									
									</div>   
                  <p class="mt-1"><span class="font-weight-bold">8. </span> Are all hazards identified controlled or removed?  </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="hazards_identified" type="radio" value="Yes" readOnly  checked={allocationInfo.hse_report?.hazards_removed === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="hazards_identified" type="radio" value="No" readOnly checked={allocationInfo.hse_report?.hazards_removed === 'No'}/> <span>No</span></label>
										</div>
									</div>  
                  <p class="mt-1"><span class="font-weight-bold">9. </span> Are all your staff assigned on this project inducted into the HSE guidelines?   </p>
                  <div class="row mg-t-10">
										<div class="col-lg-4">
											<label class="rdiobox"><input name="staff_assigned" type="radio" value="Yes" readOnly checked={allocationInfo.hse_report?.staff_assigned === 'Yes'}/> <span>Yes</span></label>
										</div>
										<div class="col-lg-4 mg-t-20 mg-lg-t-0">
											<label class="rdiobox"><input name="staff_assigned" type="radio" value="No" readOnly  checked={allocationInfo.hse_report?.staff_assigned === 'No'}/> <span>No</span></label>
										</div>
									</div> 
                  <p class="mt-1">Attach the list of names of the staff involved in the implementation of the assigned tasks</p> 
                  <textarea name="staff_list"  rows={4} cols={50} value={allocationInfo.hse_report?.staff_list} readOnly  className="form-control" />

                   <div class="row">
									<div class="col-lg-6">
										<div class="row row-xs align-items-center mg-b-20" >
											<div class="col-md-3">
												<label class="mt-3">Assessed By:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-9">
                                            <input type="text" value={`${allocationInfo.hse_report?.assessed_by?.first_name} ${allocationInfo.hse_report?.assessed_by?.last_name}`} readOnly className="form-control"/>
            
											</div>
										</div>
                   
									</div>
                  <div class="col-lg-6">
										<div class="row row-xs align-items-center mg-b-20" >
											<div class="col-md-2">
												<label class="mt-3">Designation:</label>
											</div>
											<div class="col-md-6 mg-t-5 mg-md-t-9">
                        <input type="text" value={allocationInfo.hse_report?.designation} readOnly className="form-control"/>
											</div>
										</div>
                   
									</div></div>    
								</div>
               
                    
                 
                  
								<div class="card-footer text-right">
                                {/* {loading && (<button disabled className="btn btn-success btn-sm">Saving...</button>)}
                                {!loading && (<button onClick={handleAddReport} className="btn btn-success btn-md">Save HSE Report</button>)} */}
								</div>
							</div>
						</div>
		</div>
    </AppContainer>
  );
}

export default ViewHSEReport;
